import { useRouteMatch } from 'react-router-dom'
import { DefaultContainer } from 'components/StyledComponents'
import React, { useCallback, useEffect, useState } from 'react'
import './ProductDetailsStyles.scss'
import { childListMock, installationOptionsMock } from './mockData'
import ProductInfo from './productInfo/ProductInfo'
import DetailsForm from './detailsForm/DetailsForm'
import { IFormKit, IKitData, IPaymentItem, IInstItem } from './interfaces'
import {
  getInstallationTypeByKit,
  getKitById,
  getListPaymentOptions
} from 'dataSource/DataRepository'
import { INSURANCE_AND_TAX } from 'utils/constants'
import ListItens from 'components/listItem/ListItens'
import Footer from 'components/footer/Footer'

interface IProductDetails {
  handleAddCrumb?: (args1: any) => void
}

const ProductDetails: React.FC<IProductDetails> = ({ handleAddCrumb = () => { } }) => {
  const [kitData, setKitData] = useState({} as IKitData)
  const [pdfList, setPdfList] = useState(childListMock)
  const [formInfo, setFormInfo] = useState({} as IFormKit)
  const [installationOptions, setInstallationOptions] = useState(installationOptionsMock)
  const { kitId } = useRouteMatch().params as { kitId: string }
  const [paymentOptions, setPaymentIptions] = useState<IPaymentItem[]>([])

  const handleChangeInfo = useCallback((field, value) => {
    setFormInfo(oldState => ({ ...oldState, [field]: value }))
  }, [])

  useEffect(() => {
    calcTotal()
  }, [formInfo])

  const calcTotal = () => {
    let laborCost = 0
    let subTotal = kitData.subTotalBkp
    let totalValue = Number(subTotal)
    let shipping = 0

    if (formInfo.installationType?.precoVenda && !kitData.personalizado)
      totalValue += Number(formInfo.installationType?.precoVenda)

    if (formInfo.laborCost) {
      laborCost = formInfo?.laborCost || 0
      totalValue += laborCost
      subTotal = Number(subTotal) + Number(laborCost)
    }

    if (formInfo.shipping?.taxaFrete) {
      shipping = Number(
        (
          Number(totalValue) *
          (formInfo.shipping?.taxaFrete / (1 - formInfo.shipping?.taxaFrete))
        ).toFixed(2)
      )
      if (formInfo.shipping.precoMinimo && shipping < formInfo.shipping.precoMinimo) {
        shipping = formInfo.shipping.precoMinimo
      }
    }

    setKitData(oldState => ({
      ...oldState,
      shipping: shipping || undefined,
      paymentType: formInfo?.paymentType?.payment || 'À vista',
      subTotal: totalValue,
      total: (Number(totalValue) + ((Number(shipping) || 0))),
      shippingPayment: formInfo.shippingPayment
    }))
  }

  const loadData = () => {
    getInstallationTypeByKit(kitId).then(resp => {
      if (!resp.tipoInstalacaoOutPuts) return
      setInstallationOptions(resp.tipoInstalacaoOutPuts)
    }).catch(() => {
      setInstallationOptions([])
    })
  }

  const loadKit = () => {
    getKitById(kitId).then(resp => {
      if (!resp?.kitOutPut) return
      const { dataSheetInversor, dataSheetModulo, nome, precoVenda } = resp.kitOutPut
      const newPdfList = [
        { label: pdfList[0].label, content: dataSheetInversor },
        { label: pdfList[1].label, content: dataSheetModulo },
      ]
      handleAddCrumb({ label: nome, isActive: true })
      setPdfList(newPdfList)
      const kit = {
        ...kitData,
        ...resp.kitOutPut,
        subTotal: precoVenda,
        total: precoVenda,
        subTotalBkp: precoVenda
      }
      setKitData(kit)

      if (!resp.kitOutPut.personalizado) return

      const tipoInstalacao: IInstItem = {
        tipoInstalacaoId: resp.kitOutPut.idTipoInstalacao,
        descTipoId: resp.kitOutPut.idTipoFixacao,
        descricaoTipoFixacao: resp.kitOutPut.descricaoTipoFixacao,
        descricaoTipoInstalacao: resp.kitOutPut.descricaoTipoInstalacao,
        kitId: resp.kitOutPut.id,
        precoVenda: resp.kitOutPut.precoVendaTipoInstalacao,
        quantidadeTipoInstalacao: resp.kitOutPut.quantidadeTipoInstalacao,
        idKitTipoEstrutura: resp.kitOutPut.idKitTipoEstrutura
      }
      handleChangeInfo('installationType', tipoInstalacao)
    })
  }

  const loadPayment = () => {

    const params = {
      kitValue: Number(kitData.subTotalBkp) || 0,
      installationValue: kitData.personalizado
        ? Number(formInfo.laborCostBkp) || 0
        : (Number(formInfo.laborCostBkp) || 0) +
          (Number(formInfo.installationType?.precoVenda) || 0),
      freightValue: kitData.shipping || 0,
      interestTaxes: (formInfo.laborCostBkp || 0) * INSURANCE_AND_TAX
    }
    getListPaymentOptions(params).then(resp => {
      if (resp.constructor === Array) {
        setPaymentIptions(resp as Array<IPaymentItem>)
      }
    })
  }

  useEffect(() => {
    loadPayment()
  }, [
    formInfo.installationType,
    formInfo.shipping,
    kitData.subTotalBkp,
    formInfo.laborCost,
    kitData.shipping,
  ])

  useEffect(() => {
    loadData()
    loadPayment()
  }, [kitId])

  useEffect(() => {
    loadKit()
  }, [kitId])

  return (
    <>
      <DefaultContainer className='product-kit-title'>
        {kitData.nome}
      </DefaultContainer>
      <DefaultContainer className='ProductDetailsContainer'>
        <ProductInfo
          kitData={kitData}
        />
        <DetailsForm
          installationOptions={installationOptions}
          paymentOptions={paymentOptions}
          childList={pdfList}
          handleChangeInfo={handleChangeInfo}
          calculateTotal={calcTotal}
          kitData={kitData}
          formInfo={formInfo}
        >
          <ListItens kitId={kitId} installationType={formInfo.installationType} />
        </DetailsForm>
      </DefaultContainer>
      <Footer />
    </>
  )
}

export default ProductDetails
