import styled from 'styled-components'
import iconArrowRight from 'assets/images/icons/Arrow.svg'

export const StepsContainer = styled.div`
  width: 100%;
  height: 61px;
  background-color: ${({ theme }) => theme.colors.LIGHTER_GRAY};
  border-radius: 10px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  align-items: center;
`

interface IItemStepsProps {
  active?: boolean
}

export const ItemStepsContainer = styled.span<IItemStepsProps>`
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  font-weight: bold;
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${p => p.active
    ? ({ theme }) => theme.colors.LIGHT_GRAY
    : 'transparent'};
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    font-size: 12px;
  }

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :not(:last-child):after {
    position: absolute;
    background: url(${iconArrowRight}) no-repeat;
    background-size: 9px;
    content: '';
    width: 14px;
    height: 14px;
    left: 99%;
    z-index: 1;
  }
`