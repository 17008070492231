import axios, { AxiosRequestConfig } from 'axios'
import { getAccessToken } from 'utils/users'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const storageKey = 'COPBACKOFFICE:userData'

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getAccessToken()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => Promise.reject(error)
)

export default api