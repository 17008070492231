import styled from 'styled-components'
import { Button } from 'react-bootstrap'

type TDarkButtonProps = {
  iconPosition?: 'right' | 'left'
}

export const DarkButton = styled(Button)<TDarkButtonProps>`
  background-color: ${({ theme }) => theme.colors.DARK_GRAY} !important;
  color: ${({ theme }) => theme.colors.WHITE};
  border: none !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  
  :hover,
  :focus,
  :disabled {
    color: ${({ theme }) => theme.colors.WHITE};
    background-color: ${({ theme }) => theme.colors.DARK_GRAY};
  }

  svg {
    margin-left: ${p => p.iconPosition === 'right' ? '10px' : '0'};
    margin-right: ${p => p.iconPosition === 'left' ? '10px' : '0'};
    width: 22px;
    height: auto;
  }
`