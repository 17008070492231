import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './LoginPage.scss'
import { Button } from 'components/StyledComponents'
import { loginRequest } from '../../dataSource/DataRepository'
import { checkUserIsAuth, saveLoginUserData } from '../../utils/users'
import Routes from '../../routes/routes'
import LoginWrapper from './LoginWrapper'

interface ILoginParams {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Insira um e-mail válido')
    .required('Insira seu e-mail'),
  password: yup.string()
    .required('Insira sua senha'),
})

const initialValues: ILoginParams = {
  email: '',
  password: '',
}

const LoginPage: React.FC = () => {
  const history = useHistory()
  const [error, setError] = useState(false)

  useEffect(() => {
    if (checkUserIsAuth()) {
      history.push(Routes.kitList)
    }
  }, [])

  function doLogin() {
    // setLoading(true);
    const params: ILoginParams = {
      email: formik.values.email,
      password: formik.values.password,
    }

    loginRequest(params)
      .then(response => {
        // setLoading(false);
        // console.log('response ', response);

        if (!response.isError) {
          // login ok
          saveLoginUserData(response.token, response.user)
          history.push(Routes.kitList)
        } else {
          setError(true)
        }
      })
      .catch(() => {
        // message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
        // setLoading(false);
      })
  }

  const formik = useFormik<ILoginParams>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: doLogin,
  })

  const hasError = (name: keyof ILoginParams) => {
    return formik.touched[name] && formik.errors[name]
  }

  useEffect(() => {
    setError(false)
  }, [formik.values.email, formik.values.password])

  return(
    <div className='LoginPage'>
      <LoginWrapper title='Acesse a sua conta'>
        <div className='login-form-components'>
          <span className='to-login-text'>Realize o login com a sua conta cadastrada no sistema da Copérnico</span>
          <form className='login-form' onSubmit={formik.handleSubmit}>
            <input
              type='text'
              placeholder='Usuário'
              className={ hasError('email') ? 'is-invalid' : ''}
              {...formik.getFieldProps('email')}
            />
            <span className='error-message'>{formik.errors.email}</span>
            <input
              type='password'
              placeholder='Senha'
              className={ hasError('password') ? 'is-invalid' : ''}
              {...formik.getFieldProps('password')}
            />
            <span className='error-message'>{formik.errors.password}</span>
            {error && <span className='error-message'>E-mail ou senha inválido</span>}
            <Button type='primary' htmlType='submit' className='submit-button'>Entrar</Button>
            <div className='login-information'>
              <a href={Routes.forgotPassword} className='forgot-password'>Esqueceu a senha?</a>
              <a
                href='https://portal.pipefy.com/integrador'
                target='_blank'
                className='cadastro'
                rel='noreferrer'
              >
                Quero me cadastrar
              </a>
            </div>
          </form>
          <span className='policy-text'>Ao realizar o login, você concorda com a <a href='/'> Política de Privacidade</a>  da Copérnico.</span>
        </div>
      </LoginWrapper>
    </div>
  )
}

export default LoginPage
