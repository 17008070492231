import styled from 'styled-components'
import logo from 'assets/images/logoWithSlogan.svg'

export const Container = styled.main`
  /* display: none; */
  width: 590px;
  color: #575657;
  font-size: 12px;

  /* &::before {
    display: block;
    height: 16px;
    content: "";
    background-image: linear-gradient(
      to right,
      #ff3131 -8%,
      #fd5530 21%,
      #ff8500 78%,
      #f8b42d 102%,
      #808080 107%
    );
  } */
`

export const InnerContainer = styled.div`
  padding: 50px;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #ea3728;

  > div:last-child {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }
`

export const Title = styled.h2`
  width: 200px;
  color: #ea3728;
  font-size: 30px;
  font-weight: 800;
`

export const Logo = styled.h1`
  width: 200px;
  height: 52px;
  background: url(${logo}) no-repeat;
  background-size: 200px 52px;
  background-position: top right;
  text-indent: -99999px;
  overflow: hidden;
`

export const CompanyInfo = styled.div`
  width: 400px;
  text-align: right;

  h3 {
    font-size: 12px;
    font-weight: 700;
  }

  span {
    display: block;
  }
`


export const PropostaInfo = styled.div`
  display: flex;
  margin-top: 15px;
`

type TPropostaSectionProps = {
  isCloser?: boolean
}

export const PropostaSection = styled.ul<TPropostaSectionProps>`
  list-style: none;
  padding: 0;
  margin-top: ${p => p.isCloser ? '5px' : 0};
  flex: 1;

  li {
    padding: ${p => p.isCloser ? '3px' : '5px'} 0;

    strong {
      margin-right: 10px;
    }
  }
`

type TTableProps = {
  isMain?: boolean
}

export const Table = styled.table<TTableProps>`
  width: 100%;
  border-bottom: ${p => p.isMain ? '4px solid #ea3728' : 'none'};

  thead {
    background-color: #ea3728;

    tr {

      th {
        color: white;
        font-weight: 700;
        padding: 5px 10px;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #DBDBDB;
    }

    td {
      padding: 5px 10px;
      font-weight: 700;

      &:last-child {
        /* text-align: right; */
        font-weight: 300;
        white-space: nowrap;
      }
    }
  }

  tfoot {
    border-top: solid 3px #575657;

    tr td {
      padding: 10px;
      text-align: center;
    }
  }
`

export const Total = styled.li`
  font-size: 14px;
  border-top: 3px solid #575657;

  span {
    font-weight: 700;
  }
`

export const InfoHeader = styled.header`
  background-color: #ea3728;
  color: white;
  font-weight: 700;
  padding: 5px 10px;
`

export const OrderedList = styled.ol`
  li {
    padding: 7px 0;
    &::marker {
      font-weight: 700;
    }

    span {
      display: block;
      margin-left: 10px;
    }
  }
`

export const FooterLogo = styled.footer`
  display: flex;
  justify-content: center;
`

type TSpacerProps = {
  size: number
}

export const Spacer = styled.hr<TSpacerProps>`
  margin-bottom: ${p => p.size}px;
  opacity: 0;
`
