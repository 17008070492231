import React from 'react'
import { v4 as genId } from 'uuid'
import {
  Container,
  InnerContainer,
  Header,
  Title,
  CompanyInfo,
  PropostaInfo,
  PropostaSection,
  Table,
  Total,
  InfoHeader,
  OrderedList,
  FooterLogo,
  Spacer
} from './styles'
import { formatCurrency, formatDateToView } from 'utils/helpers'
import logo from 'assets/images/logoWithSlogan.png'
import cover from 'assets/images/pdfCover.png'

export type TPropostaInfo = {
  condicaoPagto: string
  dataSheetModulo: string
  dataSheetInversor: string
  detalhesCondicaoPagto: string
  valorFrete: number
  valorTotal: number
  valorServico: number
  createdAt: string
  kitId: number
  idIntegrador: number
  idTipoFixacao: number
  idTipoInstalacao: number
  nomeKit: string
  cep: string
  descricaoItem: Array<any>
  tipoInstalacao: string
  descricaoTipoInstalacao: string
  quantidadeTipoInstalacao: number
  valorTipoInstalacao: number
  descricaoKit: string
  fabricanteModulo: string
  fabricanteInversor: string
  imagem: string
  valorKit: number
  subTotal: number
  tabela_fretes: {
    prazoEntrega: number
    tabelaId?:	number
    fornecedor?:	string
    regiao?: string
    uf?: string
    cidade?: string
    percentualFrete?: number
    precoMinimo?: number
  }
}

type TIntegradorRequest = {
  cnpj: string,
  fantasyName: string,
  companyName: string,
}

const Proposta = ({ id, propostaInfo }: { id: string, propostaInfo: TPropostaInfo }) => {
  const integrador = {} as TIntegradorRequest

  const getPaymentData = () => {
    const banco = 'Safra (422)'
    const agencia = '0002'
    const conta = '000205761-7'
    const razaoSocial = 'COPERNICO ENERGIAS RENOVAVEIS SPE 04 SOCIEDADE UNIPESSOAL LIMITADA'
    const pix = ''
    return (
      <>
        <li>
          <strong>Banco:</strong>
          <span>{banco}</span>
        </li>
        <li>
          <strong>Agência:</strong>
          <span>{agencia}</span>
        </li>
        <li>
          <strong>Conta:</strong>
          <span>{conta}</span>
        </li>
        {pix && <li>
          <strong>Chave Pix (CNPJ):</strong>
          <span>{pix}</span>
        </li>}
        <li>
          <strong>Razão Social:</strong>
          <span>{razaoSocial}</span>
        </li>
      </>
    )
  }

  return (
    <Container id='proposta' style={{ display: 'none' }}>
      <img src={cover} width={596} />
      <InnerContainer>
        <Header>
          <Title>
            Proposta Comercial
          </Title>

          <div>
            <img src={logo} width={225} height={58} />
            <CompanyInfo>
              <h3>COPERNICO ENERGIAS RENOVAVEIS LTDA</h3>
              <address>
                <span>CNPJ: 39.439.388/0001-67</span>
                <span>(11) 2626-3932 - atendimento@copernico.com.br</span>
                <span>https://www.copernico.com.br/</span>
              </address>
            </CompanyInfo>
          </div>
        </Header>

        <PropostaInfo>
          <PropostaSection>
            <li>
              <strong>Descrição:</strong>
              <span />
            </li>
            <li>
              <strong>Cliente:</strong>
              <span>{integrador.companyName || ''}</span>
            </li>
            <li>
              <strong>Razão Social:</strong>
              <span>{integrador.fantasyName || ''}</span>
            </li>
            <li>
              <strong>CNPJ:</strong>
              <span>{integrador.cnpj}</span>
            </li>
          </PropostaSection>

          <PropostaSection>
            <li>
              <strong>Número:</strong>
              <span>{id}</span>
            </li>
            <li>
              <strong>Data:</strong>
              <span>{formatDateToView(propostaInfo?.createdAt)}</span>
            </li>
            <li>
              <strong>Validade:</strong>
              <span>3 dias úteis ou até durarem os estoques </span>
            </li>
            <li>
              <strong>Moeda:</strong>
              <span>Real (R$)</span>
            </li>
          </PropostaSection>
        </PropostaInfo>

        <Table isMain>
          <thead>
            <tr>
              <th>{propostaInfo?.nomeKit}</th>
            </tr>
            <tr>
              <th>Item:</th>
            </tr>
          </thead>
          <tbody>
            {propostaInfo?.descricaoItem?.map(info => (
              <tr key={genId()}>
                <td>{info.nome}</td>
                <td>{info.quantidade}</td>
              </tr>
            ))}
            <tr>
              <td>
                {propostaInfo.tipoInstalacao} - {propostaInfo.descricaoTipoInstalacao}
              </td>
              <td>{propostaInfo.quantidadeTipoInstalacao ? propostaInfo.quantidadeTipoInstalacao : '1'}</td>
            </tr>
          </tbody>
        </Table>

        {propostaInfo?.descricaoItem?.length === 9 && <Spacer size={90} />}

        <PropostaInfo>
          <PropostaSection isCloser>
            <li>
              <strong>Prazo de Entrega:</strong>
              <span>{propostaInfo?.tabela_fretes?.prazoEntrega} &nbsp;dias</span>
            </li>
          </PropostaSection>

          <PropostaSection isCloser>
            <li>
              <strong>Total Produtos:</strong>
              <span>{formatCurrency(propostaInfo?.valorFrete || propostaInfo?.valorTotal ? propostaInfo?.valorTotal - propostaInfo?.valorFrete : 0)}</span>
            </li>
            <li>
              <strong>Frete:</strong>
              <span>{formatCurrency(propostaInfo?.valorFrete)}</span>
            </li>
            <Total>
              <strong>Total Líquido:</strong>
              <span>{formatCurrency(propostaInfo?.valorTotal)}</span>
            </Total>
          </PropostaSection>
        </PropostaInfo>

        {propostaInfo?.descricaoItem?.length === 4 && <Spacer size={100} />}
        {propostaInfo?.descricaoItem?.length === 5 && <Spacer size={115} />}
        {propostaInfo?.descricaoItem?.length === 6 && <Spacer size={90} />}
        {propostaInfo?.descricaoItem?.length === 7 && <Spacer size={65} />}
        {propostaInfo?.descricaoItem?.length === 8 && <Spacer size={40} />}

        <InfoHeader>
          Informações adicionais:
        </InfoHeader>

        <PropostaSection isCloser>
          <li>
            <strong>Condições de pagamento:</strong>
            <span>{`${propostaInfo?.condicaoPagto} ${propostaInfo?.detalhesCondicaoPagto}`}</span>
          </li>
          <li>
            <strong>Tipo de Instalação:</strong>
            <span>{`${propostaInfo?.tipoInstalacao} - ${propostaInfo?.descricaoTipoInstalacao}`}</span>
          </li>
          <li>
            <strong>Observações:</strong>
            <span>Sem observações</span>
          </li>
        </PropostaSection>
        <InfoHeader>
          Informações sobre o pedido:
        </InfoHeader>

        <OrderedList>
          <li>
            <span>
              A Copérnico é somente distribuidora de equipamentos de energia
              fotovoltaica;
            </span>
          </li>
          <li>
            <span>
              A Copérnico não se responsabiliza pelo dimensionamento, projeto e/ou
              instalação das usinas de energia fotovoltaica, sendo tais atividades
              de responsabilidade do integrador;
            </span>
          </li>
          <li>
            <span>
              O prazo de entrega deve ser consultado no ato do fechamento do pedido
              e é contado a partir da confirmação do pagamento e emissão da Nota
              Fiscal;
            </span>
          </li>
          <li>
            <span>
              A responsabilidade pela descarga dos equipamentos, bem como da
              contratação de pessoal e/ou equipamentos é única e exclusiva do
              cliente/integrador;
            </span>
          </li>
          <li>
            <span>
              Ao receber o material, o cliente/integrador deverá realizar a
              conferência imediata de todos os volumes;
            </span>
          </li>
          <li>
            <span>
              Ao verificar a falta de algum equipamento/material, uma ressalva deverá
              ser feita no verso da Nota Fiscal, e a mesma enviada por e-mail ao
              consultor comercial responsável pela conta;
            </span>
          </li>
          <li>
            <span>
              A Copérnico não irá repor os materiais que porventura venham a faltar,
              caso a conferência não seja realizada na descarga dos itens;
            </span>
          </li>
          <li>
            <span>
              Caso seja identificada avarias do equipamento no ato da entrega, uma
              ressalva deverá ser feita imediatamente no verso da Nota Fiscal e o
              material deverá ser retornado junto à transportadora responsável pela
              entrega. A nota fiscal com a ressalva deverá ser enviada por e-mail
              ao consultor comercial.
            </span>
          </li>
        </OrderedList>

        {propostaInfo?.descricaoItem?.length === 4 && <Spacer size={100} />}
        {propostaInfo?.descricaoItem?.length === 5 && <Spacer size={100} />}
        {propostaInfo?.descricaoItem?.length === 6 && <Spacer size={100} />}
        {propostaInfo?.descricaoItem?.length === 7 && <Spacer size={100} />}
        {propostaInfo?.descricaoItem?.length === 8 && <Spacer size={100} />}
        {propostaInfo?.descricaoItem?.length === 9 && <Spacer size={70} />}

        <Table>
          <thead>
            <tr>
              <th>Garantias:</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>INVERSORES SOFAR</td>
              <td>10 anos</td>
            </tr>
            <tr>
              <td>INVERSORES FRONIUS</td>
              <td>7 anos</td>
            </tr>
            <tr>
              <td>INVERSOR WEG/HUAWEI</td>
              <td>5-7 anos</td>
            </tr>
            <tr>
              <td>ESTRUTURA TELHADO</td>
              <td>12 anos</td>
            </tr>
            <tr>
              <td>ESTRUTURA SOLO</td>
              <td>10 anos</td>
            </tr>
            <tr>
              <td>MÓDULOS</td>
              <td>25 anos produção linear</td>
            </tr>
            <tr>
              <td>CABOS E CONECTORES</td>
              <td>1 ano</td>
            </tr>
            <tr>
              <td>TRANSFORMADORES</td>
              <td>1 ano</td>
            </tr>
            <tr>
              <td>STRING BOX</td>
              <td>1 ano</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                Respeitando a forma de instalação e termos de uso de cada produto
                conforme manual do fabricante
              </td>
            </tr>
          </tfoot>
        </Table>

        <InfoHeader>
          Dados bancários para pagamentos:
        </InfoHeader>

        <PropostaSection isCloser>
          {
            getPaymentData()
          }
        </PropostaSection>

        <FooterLogo>
          <img src={logo} width={150} height={38} />
        </FooterLogo>
      </InnerContainer>
    </Container>
  )
}

export default Proposta
