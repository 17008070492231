import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import { Col, Row } from 'react-bootstrap'
import {
  Filter,
  Pagination,
  Header,
  BreadCrumb,
  FlexContainer,
  GoBackButton,
  TableMessageRow,
  Loader,
  ActionButton,
  Footer
} from 'components'
import { DefaultContainer } from 'components/StyledComponents'
import useFilters from 'hooks/useFilters'
import usePagination from 'hooks/usePagination'
import useRequests from 'repositories/useRequests'
import {
  Container,
  PageTitle,
  TextInfo,
  CustomTable
} from './styles'

export interface IPedidoResponse {
  created: string,
  productName: string,
  price: string,
  orderId: number
  proposalId: number
  status: number
}

const crumbList = [
  {
    label: 'Início',
    isLink: false,
    isActive: false,
    url: '/'
  },
  {
    label: 'Consulta de Pedidos',
    isLink: true,
    isActive: true,
    url: '/'
  },
]

const ListarPedidos = () => {
  const [pedidos, setPedidos] = useState<IPedidoResponse[]>([])
  const [statusOptions, setStatusOptions] = useState<TSelectOption[]>([])

  const filters = useFilters()
  const pagination = usePagination()
  const repository = useRequests()
  const history = useHistory()

  useEffect(() => {
    const getStatus = async () => {
      const response = await repository.getStatus()
      if (!response) return

      const formattedStatus = response.map(option => ({
        value: String(option.id),
        label: option.descricao
      }))
      setStatusOptions(formattedStatus)
    }

    getStatus()
  }, [])

  const requestPedidos = async (pageIndex?: number) => {
    const _filters = filters.getObjectifiedFilterItems()
    const params = {
      pageSize: pagination.itemsPerPage,
      page: pageIndex ?? 0,
      ..._filters
    }

    const response = await repository.getPedidos(params)
    pagination.stopLoading()
    filters.stopLoading()

    if (!response) return
    setPedidos(response.orders)
    pagination.setOptions(response.number, response.totalPages)
  }

  useEffect(() => {
    if (filters.items) requestPedidos()
  }, [filters.items])

  const onClickGoToDetalhes = (id: number) => () => (
    history.push(`/pedidos/detalhes/${id}`)
  )

  return (
    <>
      <Header />
      <BreadCrumb crumbList={crumbList} />
      <DefaultContainer>
        <PageTitle>
          Consultar Pedidos
        </PageTitle>
        <Container isBordered>
          <FlexContainer spaced>
            <TextInfo>Consulte todos os pedidos disponíveis em seu cadastro.</TextInfo>
            <GoBackButton
              showConfirmation={false}
              route='/'
              message=''
            />
          </FlexContainer>
          <CustomTable responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <div>
                    <span>Criado em</span>
                    <Filter
                      label={[
                        'Data Inicial',
                        'Data Final'
                      ]}
                      field='createDateStart|createDateEnd'
                      filters={filters}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>Produto</span>
                    <Filter
                      field='productName'
                      filters={filters}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>Preço de Venda</span>
                    <Filter
                      field='price'
                      filters={filters}
                      specialType='currency'
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>N° do Pedido</span>
                    <Filter
                      field='orderNumber'
                      filters={filters}
                      specialType='number'
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>N° da Proposta</span>
                    <Filter
                      field='proposalNumber'
                      filters={filters}
                      specialType='number'
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>Status</span>
                    <Filter
                      field='orderStatus'
                      filters={filters}
                      options={statusOptions}
                    />
                  </div>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {repository.isLoading ? (
                <TableMessageRow colSpan={7}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!pedidos?.length && (
                    <TableMessageRow colSpan={7}>
                      Não foram encontrados pedidos para serem listados
                    </TableMessageRow>
                  )}
                  {pedidos?.map(pedido => {

                    return (
                      <tr key={pedido.orderId}>
                        <td>{pedido.created}</td>
                        <td>{pedido.productName}</td>
                        <td>{pedido.price}</td>
                        <td>{pedido.orderId}</td>
                        <td>{pedido.proposalId}</td>
                        <td>{pedido.status}</td>
                        <td>
                          <ActionButton onClick={onClickGoToDetalhes(pedido.orderId)}>
                            <AiOutlineEye />
                          </ActionButton>
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </CustomTable>

          <Row>
            <Col>
              <Pagination
                currentPageIndex={pagination.currentPageIndex!}
                totalPages={pagination.totalPages!}
                request={requestPedidos}
                goTo={pagination.goTo}
                goToPage={pagination.goToPage}
                loader={pagination.loader}
              />
            </Col>
          </Row>
        </Container>
      </DefaultContainer>
      <Footer />
    </>
  )
}

export default ListarPedidos