import React from 'react'
import DataSheetList from 'components/DataSheetList/DataSheetList'
import { IProposalDetails } from '../interfaces'
import './ProposalDetails.scss'
import { DefaultContainer } from 'components/StyledComponents'
import { formatCurrency, upperCaseFirstLetter } from 'utils/helpers'
import ListItens from 'components/listItem/ListItens'

const ProposalDetails: React.FC<IProposalDetails> = ({ proposalData, childList, children }) => {

  const installationType =
  { kitId: proposalData.kitId,
    tipoInstalacaoId: proposalData.idTipoInstalacao,
    descTipoId: proposalData.idTipoFixacao,
    precoVenda: proposalData.valorTipoInstalacao,
    descricaoTipoInstalacao: proposalData.descricaoTipoInstalacao,
    descricaoTipoFixacao: proposalData.tipoInstalacao,
    quantidadeTipoInstalacao: proposalData.quantidadeTipoInstalacao
  }

  const extractTotal = () => {
    return (Number(proposalData.valorTotal))
  }
  return (
    <DefaultContainer className='proposalDetail'>
      {proposalData && (

        <div className='paymentInfo'>
          <div>
            <span className='span'>Forma de pagamento</span>
            <span>{upperCaseFirstLetter(proposalData.condicaoPagto)}	</span>
          </div>
          <div className='TotalRow'>
            <div className='column'>
              <span className='span'>Total</span>
              <span>
                {formatCurrency(extractTotal())}
              </span>
            </div>
            <div className='column'>

              <span className='span'>Parcelas</span>
              <span>{proposalData.detalhesCondicaoPagto}</span>
            </div>
          </div>
        </div>
      )}
      <hr />
      {children}
      <div className='toggles-container'>
        <ListItens
          kitId={String(proposalData.kitId)}
          installationType={installationType}
        />
        <DataSheetList childList={childList} />
      </div>

    </DefaultContainer>
  )
}

export default ProposalDetails
