import React, { Dispatch, SetStateAction } from 'react'
import Modal from 'react-modal'
import Routes from 'routes/routes'
import { useHistory } from 'react-router-dom'
import { Button } from 'components/StyledComponents'
import { XButton } from '../Form/ConfirmationModal/styles'
import logo from '../../assets/images/logoHorizontalWithoutText.svg'
import './LoginModal.scss'

interface ILoginModal{
  isLoginRedirectModalOpen: boolean;
  setIsLoginRedirectModalOpen: Dispatch<SetStateAction<boolean>>
}

const LoginModal: React.FC<ILoginModal> = ({ isLoginRedirectModalOpen, setIsLoginRedirectModalOpen }) => {
  const history = useHistory()

  function handleCloseLoginRedirectionModal(){
    setIsLoginRedirectModalOpen(false)
  }

  function redirectToLogin(){
    setIsLoginRedirectModalOpen(false)
    history.push(Routes.loginPage)
  }

  return(
    <Modal
      isOpen={isLoginRedirectModalOpen}
      onRequestClose={handleCloseLoginRedirectionModal}
      className='login-modal'
    >
      <div className='modal-content'>
        <XButton onClick={handleCloseLoginRedirectionModal} className='modal-close' />
        <img src={logo} alt='Logo' className='modal-logo' />
        <span className='modal-text'>
          Caro Integrador para finalizar a sua proposta, é necessário estar logado em nosso sistema. Faça o login clicando no botão abaixo:
        </span>
        <span onClick={redirectToLogin}>
          <Button type='primary' className='redirect-button'>Fazer o login</Button>
        </span>
      </div>
    </Modal>
  )
}

export default LoginModal
