import { Link } from 'components'
import React from 'react'

import { BreadCrumbStyled, BreadCrumbItemStyled, DefaultContainerStyled } from './BreadCrumbStyles'

interface ICrumb {
  label: string;
  isActive?: boolean;
  url?: string;
  isLink?: boolean;
}

interface IBreadCrumb {
  crumbList: Array<ICrumb>;
}

const BreadCrumb: React.FC<IBreadCrumb> = ({ crumbList }) => {
  return (
    <BreadCrumbStyled>
      <DefaultContainerStyled>
        {
          crumbList.map((crumb: ICrumb) => (
            <BreadCrumbItemStyled key={crumb.label}>
              {
                crumb.isLink && !crumb.isActive ? (
                  <Link to={crumb?.url || '#'}>
                    <span>
                      {crumb.label}
                    </span>
                  </Link>
                ) : (
                  <span className='active'>
                    {crumb.label}
                  </span>
                )
              }
            </BreadCrumbItemStyled>
          ))
        }
      </DefaultContainerStyled>
    </BreadCrumbStyled>
  )
}

export default BreadCrumb
