import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  div {
    display: flex;
    gap: 10px;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.DARK_YELLOW};
  font-weight: 600;
`