import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles'

type TProps = {
  children: React.ReactNode
}

const Providers = ({ children }: TProps) => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        {children}
      </ToastProvider>
    </ThemeProvider>
  </BrowserRouter>
)

export default Providers
