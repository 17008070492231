import styled from 'styled-components'

export const CustomKitBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  box-shadow: none;
  border: none;
  background-color: #FFF;
  font-size: 32px;
`

export const ItemRow = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 45% 17.5% 17.5% 15% 5%;
  border: 0.5px solid rgb(203, 203, 203);

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    align-items: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &:nth-of-type(even){
    background-color: rgba(112, 112, 112, 0.25);
  }

  &:nth-child(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;
  }
  
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
    grid-template-columns: 80% 20%;
    font-size: 14px;
    font-weight: bold;

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
`
export const Description = styled.span`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 2;
  text-align: left;
  color: rgb(69, 68, 69);

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    padding-left: 15px;
    font-size: 15px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
  } 
`
export const DesktopLabel = styled(Description)`
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    display: none;
  }
`

export const MobileLabel = styled(Description)`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    display: inherit;
    width: 30%;
    font-weight: bold;
    padding: 0 0 0 7.5px;
  }
`

export const MobileDiv = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
  }
`

export const InputGroupContainer = styled.div`
  display: flex;
  gap: 10px;

  button {
    height: 39px;
  }
`

interface IActionButtonProps {
  size?: number
}

export const ActionButton = styled.button<IActionButtonProps>`
	display: flex;
	align-items: center;
	font-size: ${p => p.size ? `${p.size}px` : '13px'};
	font-weight: 600;
	color: ${({ theme }) => theme.colors.DARK_YELLOW};
	text-decoration: none;
	cursor: pointer;
	gap: 8px;
	background-color: transparent;
	border-color: transparent;

	:hover {
	  color: ${({ theme }) => theme.colors.DARK_YELLOW};
	  background-color: transparent;
	  border-color: transparent;
	}
`
