import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './ForgotPassword.scss'
import { Button } from 'components/StyledComponents'
import { forgotPasswordRequest } from '../../dataSource/DataRepository'
import { checkUserIsAuth } from '../../utils/users'
import Routes from '../../routes/routes'
import LoginWrapper from './LoginWrapper'
import Spinner from '../../components/Spinner/Spinner'

interface IForgotPasswordParams {
  email: string;
}

interface IForgotPasswordForm {
  forgotEmail: string;
}

const validationSchema = yup.object().shape({
  forgotEmail: yup.string()
    .email('Insira um e-mail válido')
    .required('Insira seu e-mail')
})

const initialValues: IForgotPasswordForm = {
  forgotEmail: '',
}

const ForgotPassword: React.FC = () => {
  const history = useHistory()
  const [emailSent, setEmailSent] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (checkUserIsAuth()) {
      history.push(Routes.kitList)
    }
  }, [])

  function doLogin() {
    const params: IForgotPasswordParams = {
      email: formik.values.forgotEmail,
    }
    setLoading(true)

    forgotPasswordRequest(params)
      .then(response => {
        setLoading(false)

        if (!response.isError) {
          // login ok
          setEmailSent(params.email)
        } else {
          setError(true)
        }
      }).catch(() => setLoading(false))
  }

  const formik = useFormik<IForgotPasswordForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: doLogin,
  })

  const hasError = (name: keyof IForgotPasswordForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  useEffect(() => {
    setError(false)
  }, [formik.values.forgotEmail])

  return(
    <div className='ForgotPassword'>
      <LoginWrapper title='Recuperação de senha'>
        <div className='login-form-components'>
          {emailSent ? (
            <>
              <span className='to-login-text'>
                Um e-mail foi enviado para <b>{emailSent}</b> com informações para a recuperação da senha
              </span>
              <Button
                type='primary'
                className='submit-button'
                onClick={() => history.push(Routes.loginPage)}
              >
                Login
              </Button>
            </>
          ) : (
            <>
              <span className='to-login-text'>Esqueceu sua senha? É fácil recuperar</span>
              <span className='to-login-text'>Digite seu e-mail que iremos enviar as instruções</span>
              <Spinner loading={loading}>
                <form className='login-form' onSubmit={formik.handleSubmit}>
                  <input
                    type='text'
                    placeholder='E-mail'
                    className={ hasError('forgotEmail') ? 'is-invalid' : ''}
                    {...formik.getFieldProps('forgotEmail')}
                  />
                  <span className='error-message'>{formik.errors.forgotEmail}</span>
                  {error && <span className='error-message'>E-mail inválido</span>}
                  <Button type='primary' htmlType='submit' className='submit-button'>Enviar</Button>
                </form>
              </Spinner>
            </>
          )}
        </div>
      </LoginWrapper>
    </div>
  )
}

export default ForgotPassword
