import styled, { css } from 'styled-components'
import { shade } from 'polished'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`

export const Button = styled.button`
  ${props =>
    props.color
      ? css`
          background: ${props.color};
        `
      : css`
          background: #002244;
        `}

  color: #fff;
  border-radius: 5px;
  border: 0;
  padding: 0 16px;
  width: 100%;
  margin-left: 8px;
  height: 54px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  letter-spacing: normal;
  transition: background-color 0.2s;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    ${props =>
    props.color
      ? css`
            background: ${shade(0.2, props.color)};
          `
      : css`
            background: ${shade(0.2, '#002244')};
          `}
  }
`

export const XButtonContainer = styled.div`
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
`

export const XButton = styled(MdClose)`
  cursor: pointer;
`

Modal.setAppElement(document.getElementById('root'))

export const ModalContainer = styled(Modal)`
  position: absolute;
  margin: 0 16px !important;
  padding: 24px;
  background-color: #fff;
  overflow: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    margin: unset;
    margin-right: -50% !important;
  }
`
