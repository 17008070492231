import React from 'react'

import {
  ButtonsContainer,
  FlexContainer,
  Loader,
  PrimaryButton
} from 'components'
import {
  CardContainer,
  Container,
  ProductImage,
  TitleText,
  ItemContainer,
  DetailsContainer
} from '../../styles'

interface IConfirmationOrderProps {
  orderInfo: IOrderInfo
  onClickNextStep(index?: number | undefined): void
  isLoading: boolean
}

const ConfirmationOrder = (props: IConfirmationOrderProps) => {
  const {
    orderInfo,
    onClickNextStep,
    isLoading
  } = props

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <Container>
          <TitleText>
            Item do Pedido
          </TitleText>
          <CardContainer>
            <ItemContainer>
              <div>
                <ProductImage />
                <ul>
                  <li>
                    <strong>{orderInfo.descricao}</strong>
                  </li>
                  <li>{orderInfo.descricao}</li>
                  <li>Marca: {orderInfo.marca}</li>
                  <li>Inversor: {orderInfo.fabricanteInversor}</li>
                </ul>
              </div>
              <FlexContainer>
                <span>Valor</span>
                <span>
                  <strong>{orderInfo.valorKit}</strong>
                </span>
              </FlexContainer>
            </ItemContainer>
          </CardContainer>
          <TitleText>
            Detalhes do Pedido {orderInfo.orderId}
          </TitleText>
          <CardContainer>
            <DetailsContainer>
              <div>
                <ul>
                  <li>
                    <strong>Local de Entrega</strong>
                  </li>
                  <li>{orderInfo.city} - {orderInfo.state}</li>
                  <li>{orderInfo.street}, {orderInfo.number}, {orderInfo.neighborhood}</li>
                  <li>{orderInfo.cep}</li>
                </ul>
                <hr />
                <FlexContainer spaced>
                  <span>
                    <strong>Valor do Frete</strong>
                  </span>
                  <span>
                    <strong>{orderInfo.valorFrete}</strong>
                  </span>
                </FlexContainer>
              </div>
              <div>
                <ul>
                  <FlexContainer spaced>
                    <span>Valor do Produto</span>
                    <span>
                      <strong>{orderInfo.valorKit}</strong>
                    </span>
                  </FlexContainer>
                  <FlexContainer spaced>
                    <span>Valor dos Serviços</span>
                    <span>
                      <strong>{orderInfo.valorServico}</strong>
                    </span>
                  </FlexContainer>
                  <FlexContainer spaced>
                    <span>Valor de Instalação</span>
                    <span>
                      <strong>{orderInfo.valorTipoInstalacao}</strong>
                    </span>
                  </FlexContainer>
                  <FlexContainer spaced>
                    <span>Impostos</span>
                    <span>
                      <strong>{orderInfo.valorImposto}</strong>
                    </span>
                  </FlexContainer>
                  <FlexContainer spaced>
                    <span>Valor do Frete</span>
                    <span>
                      <strong>{orderInfo.valorFrete}</strong>
                    </span>
                  </FlexContainer>
                </ul>
                <hr />
                <FlexContainer>
                  <span>
                    <strong>Total</strong>
                  </span>
                  <span>
                    <strong>{orderInfo.valorTotal}</strong>
                  </span>
                </FlexContainer>
              </div>
            </DetailsContainer>
          </CardContainer>
          <ButtonsContainer>
            <PrimaryButton
              onClick={onClickNextStep}
              isLoading={false}
            >
              Prosseguir
            </PrimaryButton>
          </ButtonsContainer>
        </Container>
      )}
    </>
  )
}

export default ConfirmationOrder