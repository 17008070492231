import styled from 'styled-components'

type TProps = {
  isPartOfGroup?: boolean
  isCentered?: boolean
}

export const InfoText = styled.p<TProps>`
  margin-bottom: ${p => p.isPartOfGroup && '5px;'};
  text-align: ${p => p.isCentered ? 'center' : 'unset'};

  strong {
    margin: 0 5px;
  }
`