import React from 'react'
import { SalesKitDetailsContainer } from './styles'
import ProductProposal from 'components/ProductProposal/ProductProposal'
import Footer from 'components/footer/Footer'

const SalesKitProposal: React.FC = () => {
  return (
    <SalesKitDetailsContainer>
      <ProductProposal />
      <Footer />
    </SalesKitDetailsContainer>
  )
}

export default SalesKitProposal
