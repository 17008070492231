import { useState } from 'react'
import useRest from 'hooks/useRest'

interface IConfirmOrderRequest {
  orderId: number
  customerName: string
  customerLandline: string
  customerPhone: string
  customerEmail: string
  customerEmailVerification: string
  customerAddressCEP: string
  customerAddressStreet: string
  customerAddressNumber: string
  customerAddressNeighborhood: string
  customerAddressState: string
  customerAddressCity: string
  customerAddressComplement: string
  providerBank: number
  providerBankBranch: string
  providerCheckingAccount: string
}

interface ICEPResponse {
  cep: string
  logradouro: string
  bairro: string
  localidade: string
  uf: string
}

interface IBankResponse{
  id: number
  name: string
  code: string
}

const useConfirmOrder = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getCepPedido = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/pedido/list/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response.pedidoOutput.proposta.cep
  }

  const postConfirmOrder = async (params:IConfirmOrderRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/pedido/confirmOrder'
    const response = await rest.post(url, params)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }

  const postEmail = async (pedidoId:string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/pedido/sendConfirmOrderNotification/${pedidoId}`
    const response = await rest.post(url)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }

  const getAddress = async (cep: String ) => {
    const url = `/frete/findByCep/${cep}`
    const response = await rest.get(url)
    if(!response) return {} as ICEPResponse
    return response.addressByCepDto as ICEPResponse
  }

  const getBanks = async (params: String, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/bank/listAll'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as IBankResponse[]
    return response.banks as IBankResponse[]
  }

  return {
    isLoading,
    getCepPedido,
    postConfirmOrder,
    getAddress,
    getBanks,
    postEmail
  }
}
export default useConfirmOrder