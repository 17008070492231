import { useState } from 'react'

export enum GoToWhere {
  FIRST = 'first',
  PREV = 'prev',
  NEXT = 'next',
  LAST = 'last'
}

export type TLoader = {
  isLoading: boolean
  where?: number | GoToWhere
}

export type TResquestFn = (pageIndex: number) => void

const initialLoaderStatus = {
  isLoading: false
}

const usePagination = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>()
  const [totalPages, setTotalPages] = useState<number>()
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [loader, setLoader] = useState<TLoader>(initialLoaderStatus)

  const setOptions = (currentPageIndex: number, totalPages: number) => {
    setCurrentPageIndex(currentPageIndex)
    setTotalPages(totalPages)
  }

  const setCustomItemsPerPage = (amount: number) => {
    setItemsPerPage(amount)
  }

  const goTo = (request: TResquestFn, where: GoToWhere) => {
    const getPageIndex = () => {
      switch(where) {
        case GoToWhere.FIRST:
          return 0
        case GoToWhere.PREV:
          return currentPageIndex! - 1
        case GoToWhere.NEXT:
          return currentPageIndex! + 1
        case GoToWhere.LAST:
          return totalPages! - 1
      }
    }

    setLoader({ isLoading: true, where })

    const pageIndex = getPageIndex()
    request(pageIndex)
  }

  const goToPage = (request: TResquestFn, page: number) => {
    const pageIndex = page - 1
    setLoader({ isLoading: true, where: page })
    request(pageIndex)
  }

  const stopLoading = () => {
    setLoader({ isLoading: false })
  }

  return {
    currentPageIndex,
    totalPages,
    itemsPerPage,
    setOptions,
    setCustomItemsPerPage,
    goTo,
    goToPage,
    loader,
    stopLoading
  }
}

export default usePagination