import { useState } from 'react'
import useRest from 'hooks/useRest'

interface IPedidoRequestParams {
  page?: number
  pageSize?: number
  createDateStart?: string
  createDateEnd?: string
  productName?: string
  price?: string
  orderId?: string
  orderStatus?: string
  proposalNumber?: string
}

interface IStatusResponse {
  id: number
  descricao: string
}
interface ICreateRequest {
  propostaId: number
  integradorId: number
}

const useRequests = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getPedidos = async (params: IPedidoRequestParams, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/pedido/consultOrders'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return false
    return response
  }

  const getStatus = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/pedido-status'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as IStatusResponse[]
    return response.pedidoStatusList as IStatusResponse[]
  }

  const postCreateRequest = async (body: ICreateRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/pedido/create'
    const response = await rest.post(url, body)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response.pedidoId as number
  }

  const getInfoOrderById = async (id: string, triggerLoading = true) => {
    if(triggerLoading) setIsLoading(true)
    const url = `/pedido/consultOrder/orderId/${id}`
    const response = await rest.get(url)
    if(triggerLoading) setIsLoading(false)
    if(!response) return false
    return response.consultDataOrderOutPut as IOrderInfoResponse
  }

  const getCheckTokenOrderClient = async (id: string, token: string, triggerLoading = true) => {
    if(triggerLoading) setIsLoading(true)
    const url = `/client-order-auth/check-token/${id}/${token}`
    const response = await rest.get(url)
    if(triggerLoading) setIsLoading(false)
    if(!response) return false as boolean
    return response.tokenIsValid as boolean
  }

  return {
    isLoading,
    postCreateRequest,
    getInfoOrderById,
    getPedidos,
    getStatus,
    getCheckTokenOrderClient
  }
}
export default useRequests