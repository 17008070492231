import React from 'react'
import { Container } from './styles'

type TProps = {
  children: React.ReactNode
  colSpan: number
}

const MessageRow = (props: TProps) => {
  const { children, colSpan } = props
  return (
    <Container>
      <td colSpan={colSpan}>
        {children}
      </td>
    </Container>
  )
}

export default MessageRow