import styled from 'styled-components'
import logoImage from 'assets/images/logoHorizontalWithoutText.svg'

export const Container = styled.div`
  width: 100%;
  padding: 0 70px;
  margin: 0 auto;
  max-width: 1366px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    padding: 10px 30px;

    main {
      flex-direction: column;
    }
  }
  
  main {
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: ${({ theme }) => theme.colors.DARK_GRAY};
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.ORANGE};
  color: ${({ theme }) => theme.colors.WHITE};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    font-size: 16px;
  }
`

export const Logo = styled.h1`
  background: url(${logoImage}) no-repeat;
  background-size: 230px;
  width: 235px;
  height: 60px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
`