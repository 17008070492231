import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'

import {
  ButtonsContainer,
  SecondaryButton,
  PrimaryButton
} from 'components'

type TProps = {
  isModalOpen: boolean,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  confirmationText: string,
  confirmationFunction(): void,
  titleModal?: string,
  cancelButtonText?: string,
  confirmButtonText?: string
}

const ModalConfirmation = (props: TProps) => {
  const {
    isModalOpen,
    setIsModalOpen,
    confirmationText,
    confirmationFunction,
    titleModal = 'Confirmação',
    cancelButtonText = 'Cancelar',
    confirmButtonText = 'Confirmar'
  } = props
  const handleClose = () => setIsModalOpen(false)

  const onClickConfirmFn = () => {
    handleClose()
    confirmationFunction()
  }

  return (
    <Modal
      show={isModalOpen}
      centered
      onHide={handleClose}
      backdrop='static'
    >
      <Modal.Header>
        {titleModal}
      </Modal.Header>
      <Modal.Body>
        {confirmationText}
      </Modal.Body>

      <Modal.Footer>
        <ButtonsContainer compact>
          <SecondaryButton onClick={handleClose}>
            {cancelButtonText}
          </SecondaryButton>

          <PrimaryButton
            type='button'
            onClick={onClickConfirmFn}
          >
            {confirmButtonText}
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirmation