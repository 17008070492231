import React from 'react'
import { Route } from 'react-router-dom'
// import { useAuth } from 'hooks/useAuth'
// import AuthLayout from 'components/Layout'

type TProps = {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: TProps) => <>{children}</>

const Wrapper = (props: any) => {
  const { component: Component, ...remainingProps } = props

  const Layout = DefaultLayout

  return (
    <Route
      {...remainingProps}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  )
}

export default Wrapper
