import React from 'react'
import { DefaultContainerWithShadow } from 'components/StyledComponents'
import { formatCurrency } from 'utils/helpers'
import { IKitData } from '../interfaces'
import imgProductDefault from '../../../assets/images/img-product-default.png'

import './ProductInfo.scss'

interface IProdutInfo {
  kitData: IKitData
}
const ProductInfo: React.FC<IProdutInfo> = ({ kitData }) => {
  const FormatTotal = () => {
    const mountStringTotal = () => {
      return formatCurrency(kitData.total)
    }

    return <div className='totalResume'>
      <span className='total-value bold'>{`${mountStringTotal()}`}</span>
      {kitData.shippingPayment === 'avista' &&
        <span className='breakLine'>
          + <span className='bold'> {kitData.shipping} </span>  à vista do valor do frete
        </span>}
    </div>
  }

  return (
    <>
      <DefaultContainerWithShadow className='ProductDetails'>
        <div className='ValuesDetails'>
          <div className='kitInfo'>
            <span>
              <strong>{kitData.descricao}</strong>
            </span>
            <span>
              Módulo: {kitData.fabricanteModulo}
            </span>
            <span>
              Inversor: {kitData.fabricanteInversor}
            </span>
          </div>
          <div className='detailsTable'>
            <div className='tableRow'>
              <span>Resumo do pedido</span>
            </div>
            <div className='tableRow'>
              <span>Subtotal:</span>
              <span>{formatCurrency(kitData.subTotal)}{/* á vista*/}</span>
            </div>
            <div className='tableRow'>
              <span>Frete:</span>
              <span>
                {kitData.shipping && formatCurrency(kitData.shipping) || 'Não calculado'}
              </span>
            </div>
            <div className='tableRow'>
              <span>Total</span>
              <FormatTotal />
            </div>
          </div>
        </div>
        <img src={kitData.imagem || imgProductDefault} className='productImageAside' />
      </DefaultContainerWithShadow>
    </>
  )
}

export default ProductInfo
