import request from 'superagent'
import { API_URL } from '../utils/constants'
import { getAccessToken, removeUserFromStorage } from '../utils/users'

const UNAUTHORIZED_CODE = 401

export const sampleGET = () =>
  new Promise((resolve, reject) =>
    request.get('https://get.geojs.io/v1/ip/country.json?ip=8.8.8.8').end((error, response) => {
      if (response) {
        setTimeout(() => {
          resolve(response.body)
        }, 1000)
      } else {
        reject(error)
      }
    }),
  )

export const fetchCEP = cep =>
  new Promise((resolve, reject) =>
    request.get(`https://viacep.com.br/ws/${cep}/json/`).end((error, response) => {
      if (response) {
        resolve(response.body)
      } else {
        reject(error)
      }
    }),
  )

export const get = ({ url, query, params }) => {
  const accessToken = getAccessToken()
  return new Promise(resolve =>
    request
      .get(API_URL + url)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .query(query || null)
      .send(params || null)
      .end((err, response) => {
        if (response) {
          if (response.body.status === UNAUTHORIZED_CODE) {
            removeUserFromStorage()
            window.location.reload()
          }
          resolve(response.body)
        } else {
          resolve(err)
        }
      }),
  )
}

export const post = ({ url, query, params }) => {
  const accessToken = getAccessToken()

  return new Promise(resolve =>
    request
      .post(API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((err, response) => {
        if (response) {
          if (response.body.status === UNAUTHORIZED_CODE) {
            removeUserFromStorage()
            window.location.reload()
          }
          resolve(response.body)
        } else {
          resolve(err)
        }
      }),
  )
}

export const put = ({ url, params, query }) => {
  const accessToken = getAccessToken()

  return new Promise(resolve =>
    request
      .put(API_URL + url)
      .query(query || null)
      .send(params || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((err, response) => {
        if (response) {
          if (response.body.status === UNAUTHORIZED_CODE) {
            removeUserFromStorage()
            window.location.reload()
          }
          resolve(response.body)
        } else {
          resolve(err)
        }
      }),
  )
}

export const patch = ({ url, params, id, query }) => {
  const accessToken = getAccessToken()

  return new Promise(resolve =>
    request
      .patch(API_URL + url + (id || ''))
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((err, response) => {
        if (response) {
          if (response.body.status === UNAUTHORIZED_CODE) {
            removeUserFromStorage()
            window.location.reload()
          }
          resolve(response.body)
        } else {
          resolve(err)
        }
      }),
  )
}

export const del = ({ url, params, query }) => {
  const accessToken = getAccessToken()

  return new Promise(resolve =>
    request
      .delete(API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((err, response) => {
        if (response) {
          if (response.body.status === UNAUTHORIZED_CODE) {
            removeUserFromStorage()
            window.location.reload()
          }
          resolve(response.body)
        } else {
          resolve(err)
        }
      }),
  )
}

export const download = ({ url }) => {
  const accessToken = getAccessToken()

  return new Promise(resolve =>
    request
      .get(API_URL + url)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .responseType('blob')
      .end((err, response) => {
        if (response) {
          if (response.body.status === UNAUTHORIZED_CODE) {
            removeUserFromStorage()
            window.location.reload()
          }
          resolve(response.body)
        } else {
          resolve(err)
        }
      }),
  )
}

const GET_CEP_URL = '/frete/listByCep/'
export const getCep = (cep, fornecedor) => get({ url: GET_CEP_URL + cep, query: fornecedor })

const GET_INSTALLATION_TYPE_BY_KIT_URL = '/tipoInstalacao/listInstalacoesByKitId/'
export const getInstallationTypeByKit = kitId => get(
  { url: GET_INSTALLATION_TYPE_BY_KIT_URL + kitId })

const GET_CONDICAO_PAGAMENTO = '/condicao-pagamento'
export const getListPaymentOptions = params => post({ url: GET_CONDICAO_PAGAMENTO, params })

const POST_PROPOSTA = '/proposta/create'
export const postProposta = params => post({ url: POST_PROPOSTA, params })

const GET_TABLES_KIT = '/TabelaKits/listByAllParams'
export const getTabelasKitById = query => get({ url: GET_TABLES_KIT, query })

const GET_KIT_BY_ID = '/kits/list/'
export const getKitById = kitId => get({ url: GET_KIT_BY_ID + kitId })

const GET_PRODUCTS_URL = '/kits/listWithFilters'
export const getProducts = (
  query = { pageNumber: 0 }) => get({ url: GET_PRODUCTS_URL, query })

const GET_INSTALLATION_INVERTER_URL = '/kits/AllFilltersInversor'
export const getInstallationInverter = () => get({ url: GET_INSTALLATION_INVERTER_URL })

const GET_POTENCY_RANGE_LIST_URL = '/potency/listAll'
export const getPotencyRangeList = () => get({ url: GET_POTENCY_RANGE_LIST_URL })

const GET_FILE = '/file/download/'
export const getDataSheet = filename => get({ url: GET_FILE + filename })

const GET_PROPOSTA = '/proposta/listAllInfoById/'
export const getProposta = id => get({ url: GET_PROPOSTA + id })

const GET_INTEGRADOR = '/integrador/'
export const getIntegrador = id => get({ url: `${GET_INTEGRADOR + id}/details` })

const GET_PROPOSTAS = '/proposta/consultProposals'
export const getPropostas = params => get({ url: GET_PROPOSTAS, params })

const LOGIN_URL = '/auth/signin'
export const loginRequest = params =>
  new Promise(resolve =>
    request
      .post(API_URL + LOGIN_URL)
      .send(params)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        if (response) {
          resolve(response.body)
        } else {
          resolve({ message: error, error: true })
        }
      }),
  )

const GET_ITEMS = '/Item/listAllByKitId/'
export const getItems = id => get({ url: GET_ITEMS + id })

const POST_FORGOT_PASSWORD = '/auth/kit/forgot-password'
export const forgotPasswordRequest = params =>
  new Promise(resolve =>
    request
      .post(API_URL + POST_FORGOT_PASSWORD)
      .send(params)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        if (response) {
          resolve(response.body)
        } else {
          resolve({ message: error, error: true })
        }
      }),
  )

const GET_FORGOT_PASSWORD_VALIDATE_TOKEN = '/auth/token-validation'
export const forgotPasswordTokenRequest = token =>
  new Promise(resolve =>
    request
      .get(`${API_URL}${GET_FORGOT_PASSWORD_VALIDATE_TOKEN}/${token}`)
      .send(null)
      .end((error, response) => {
        if (response) {
          resolve(response.body)
        } else {
          resolve({ message: error, error: true })
        }
      }),
  )

const PUT_RESET_PASSWORD = '/auth/password-reset'
export const resetPasswordRequest = params =>
  new Promise(resolve =>
    request
      .put(API_URL + PUT_RESET_PASSWORD)
      .send(params)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        if (response) {
          resolve(response.body)
        } else {
          resolve({ message: error, error: true })
        }
      }),
  )
