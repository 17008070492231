import React, { useState, useEffect } from 'react'
import Footer from 'components/footer/Footer'
import {
  Filter,
  Pagination,
  Header,
  BreadCrumb,
  FlexContainer,
  GoBackButton,
  TableMessageRow,
  Loader,
  ActionButton
} from 'components'
import { useHistory } from 'react-router-dom'
import useFilters from 'hooks/useFilters'
import usePagination from 'hooks/usePagination'
import usePropostas from 'repositories/usePropostas'
import {
  Container,
  PageTitle,
  TextInfo,
  CustomTable
} from './styles'
import { DefaultContainer } from 'components/StyledComponents'
import { Col, Row } from 'react-bootstrap'
import { AiOutlineEye } from 'react-icons/ai'

export interface IPropostasResponse {
  created: string,
  proposalName: string,
  price: string,
  id: number
  pedidoNumber: number
}

const crumbList = [
  {
    label: 'Kits de geradores de energia solar',
    isLink: true,
    isActive: false,
    url: '/'
  },
  {
    label: 'Consulta de Propostas',
    isLink: false,
    isActive: true,
    url: '/'
  },
]

const ListarPropostas = () => {
  const [propostas, setPropostas] = useState<IPropostasResponse[]>([])
  const filters = useFilters()
  const pagination = usePagination()
  const repository = usePropostas()
  const history = useHistory()

  const requestPropostas = async (pageIndex?: number) => {
    const _filters = filters.getObjectifiedFilterItems()
    const params = {
      pageSize: pagination.itemsPerPage,
      page: pageIndex ?? 0,
      ..._filters
    }

    const response = await repository.getPropostas(params)
    pagination.stopLoading()
    filters.stopLoading()

    if (!response) return
    setPropostas(response.proposals)
    pagination.setOptions(response.number, response.totalPages)
  }

  useEffect(() => {
    if (filters.items) requestPropostas()
  }, [filters.items])

  const onClickGoToDetalhes = (id: number) => () => (
    history.push(`/propostas/detalhes/${id}`)
  )

  return (
    <>
      <Header />
      <BreadCrumb crumbList={crumbList} />
      <DefaultContainer>
        <PageTitle>
          Consultar Propostas
        </PageTitle>
        <Container isBordered>
          <FlexContainer spaced>
            <TextInfo>Consulte todas as propostas disponíveis em seu cadastro</TextInfo>
            <GoBackButton
              showConfirmation={false}
              route='/'
              message=''
            />
          </FlexContainer>
          <CustomTable responsive striped bordered>
            <thead>
              <tr>
                <th>
                  <div>
                    <span>Criado em</span>
                    <Filter
                      label={[
                        'Data Inicial',
                        'Data Final'
                      ]}
                      field='createDateStart|createDateEnd'
                      filters={filters}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>Produto</span>
                    <Filter
                      field='proposalName'
                      filters={filters}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>Preço de Venda</span>
                    <Filter
                      field='price'
                      filters={filters}
                      specialType='currency'
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>N° da Proposta</span>
                    <Filter
                      field='proposalNumber'
                      filters={filters}
                      specialType='number'
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <span>N° do Pedido</span>
                    <Filter
                      field='orderNumber'
                      filters={filters}
                      specialType='number'
                    />
                  </div>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {repository.isLoading ? (
                <TableMessageRow colSpan={6}>
                  <Loader />
                </TableMessageRow>
              ) : (
                <>
                  {!propostas?.length && (
                    <TableMessageRow colSpan={6}>
                      Não foram encontrados propostas para serem listadas
                    </TableMessageRow>
                  )}
                  {propostas?.map(proposta => {

                    return (
                      <tr key={proposta.id}>
                        <td>{proposta.created}</td>
                        <td>{proposta.proposalName}</td>
                        <td>{proposta.price}</td>
                        <td>{proposta.id}</td>
                        <td>{proposta.pedidoNumber}</td>
                        <td>
                          <ActionButton onClick={onClickGoToDetalhes(proposta.id)}>
                            <AiOutlineEye />
                          </ActionButton>
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </CustomTable>
          <Row>
            <Col>
              <Pagination
                currentPageIndex={pagination.currentPageIndex!}
                totalPages={pagination.totalPages!}
                request={requestPropostas}
                goTo={pagination.goTo}
                goToPage={pagination.goToPage}
                loader={pagination.loader}
              />
            </Col>
          </Row>
        </Container>
      </DefaultContainer>
      <Footer />
    </>
  )
}

export default ListarPropostas