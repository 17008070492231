import React, { useEffect, useMemo, useState } from 'react'
import { MaskedCurrencyField, MaskedTextField } from 'components'
import DataSheetList from 'components/DataSheetList/DataSheetList'
import { Button } from 'components/StyledComponents'
import { Dropdown, Form } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import { IDetailsForm, IInstItem, IPaymentItem, IShipping } from '../interfaces'
import { encrypty, formatCurrency, removeCurrencyMask } from 'utils/helpers'
import { useRouteMatch } from 'react-router-dom'
import { INSURANCE_AND_TAX } from 'utils/constants'
import { getCep, getTabelasKitById, postProposta } from 'dataSource/DataRepository'
import LoginModal from 'components/loginModal/LoginModal'
import { checkUserIsAuth, getUserData } from 'utils/users'
import './DetailsForm.scss'

interface IFormaPagamento {
  incash: boolean
  card: boolean
  financing: boolean
}

const initialValuesFormaPagamento: IFormaPagamento = {
  incash: false,
  card: false,
  financing: false
}

const DetailsForm: React.FC<IDetailsForm> = ({
  installationOptions,
  paymentOptions,
  childList,
  handleChangeInfo,
  kitData,
  formInfo,
  children
}) => {
  const [installationType, setInstallationType] = useState({} as IInstItem)
  const [shipping, setShipping] = useState('')
  const [paymentType, setPaymentType] = useState({} as IPaymentItem)
  const [laborCost, setLaborCost] = useState('')
  const [showShipping, setShowShipping] = useState(false)
  const [fieldError, setFieldError] = useState({
    shipping: false,
    paymentType: false,
    installationType: false,
    laborCost: false,
    shippingData: false,
  })
  const [shippingData, setShippingData] = useState({} as IShipping)
  const [selectedShippingPayment, setSelectedShippingPayment] = useState('parcelado')
  const [insuranceAndTax, setInsuranceAndTax] = useState('0')
  const [loginModal, setLoginModal] = useState(false)
  const { kitId } = useRouteMatch().params as { kitId: string }
  const [cepShippingHasError, setCepShippingHasError] = useState(false)
  const [formaPagamento, setFormaPagamento] = useState<IFormaPagamento>(initialValuesFormaPagamento)
  const [isDisabledDropdownTipoInstalacao, setIsDisabledDropdownTipoInstalacao] = useState(false)

  useEffect(() => {
    if (!kitData.personalizado) return
    const tipoInstalacao = {
      tipoInstalacaoId: kitData.idTipoInstalacao,
      descTipoId: kitData.idTipoFixacao,
      descricaoTipoFixacao: kitData.descricaoTipoFixacao,
      descricaoTipoInstalacao: kitData.descricaoTipoInstalacao,
      kitId: kitData.id,
      precoVenda: kitData.precoVenda,
      quantidadeTipoInstalacao: kitData.quantidadeTipoInstalacao,
      idKitTipoEstrutura: kitData.idKitTipoEstrutura
    }
    setInstallationType(tipoInstalacao)
    setIsDisabledDropdownTipoInstalacao(true)
  }, [kitData.id])

  useEffect(() => {
    loadState()
  }, [])

  const handleChange = (field: string, value: any) => {
    switch (field) {
      case 'installationType':
        handleChangeInfo(field, value)
        setInstallationType(value)
        break
      case 'shipping':
        setShipping(value)
        break
      case 'laborCost':
        handleChangeInfo(
          field,
          Number(removeCurrencyMask(value)) * (1 + INSURANCE_AND_TAX)
        )
        handleChangeInfo('laborCostBkp', removeCurrencyMask(value))
        setInsuranceAndTax(
          formatCurrency(Number(removeCurrencyMask(value)) * INSURANCE_AND_TAX)
        )
        setLaborCost(value)
        break
      case 'paymentType':
        handleChangeInfo(field, value)
        setPaymentType(value)
        break
      case 'shippingPayment':
        handleChangeInfo(field, value)
        setSelectedShippingPayment(value)
        break
    }
  }

  const validateField = (field: string): boolean => {
    switch (field) {
      case 'shipping':
        if (!shipping.match(/[0-9]{5}-[0-9]{3}/)) {
          setShowShipping(false)
          setFieldError(oldState => ({ ...oldState, [field]: true }))
          atualizaCalculoCep({}, '', 0, false, true)
          return false
        }
        break
      case 'installationType':
        if (!Object.keys(installationType).length) {
          setFieldError(oldState => ({ ...oldState, [field]: true }))
          return false
        }
        break
      case 'laborCost':
        if (Number(removeCurrencyMask(laborCost.toString())) <= 0) {
          setFieldError(oldState => ({ ...oldState, [field]: true }))
          return false
        }
        break
      case 'paymentType':
        if (!Object.keys(paymentType).length) {
          setFieldError(oldState => ({ ...oldState, [field]: true }))
          return false
        }
        break
      case 'shippingData':
        if (!Object.keys(shippingData).length) {
          setFieldError(oldState => ({ ...oldState, [field]: true }))
          return false
        }
    }
    setFieldError(oldState => ({ ...oldState, [field]: false }))
    return true
  }
  const atualizaCalculoCep = (
    response: any,
    tmpShipping: any,
    value: number,
    showShipping: boolean,
    showErrorCep: boolean
    // eslint-disable-next-line max-params
  ) => {
    handleChangeInfo('shipping', { ...tmpShipping, ...response, value })
    setShippingData({ ...tmpShipping, ...response, value })
    setShowShipping(showShipping)
    setCepShippingHasError(showErrorCep)
  }

  const handleCalculate = () => {
    if (!validateField('shipping')) return
    let totalValue = Number(kitData.subTotalBkp)
    if (formInfo.installationType?.precoVenda)
      totalValue += Number(formInfo.installationType?.precoVenda)
    const tmpShipping = {
      cep: shipping,
    }

    const cepTratado = shipping.replace('-', '')
    const fornecedor = { fornecedor: kitData.fornecedorKit.toUpperCase() }
    getCep(cepTratado, fornecedor)
      .then(response => {
        if (response.commonResponse.code === 19) {
          let shippingValue =
            totalValue * (response.taxaFrete / (1 - response.taxaFrete))
          if (response.precoMinimo && shippingValue < response.precoMinimo) {
            shippingValue = response.precoMinimo
          }
          atualizaCalculoCep(response, tmpShipping, shippingValue, true, false)
        } else if (response.commonResponse.error) {
          atualizaCalculoCep(response, tmpShipping, 0, true, true)
          setFieldError({ ...fieldError, shipping: true })
        }
      })
  }

  const validateAllFields = (): boolean => {
    const fieldList = ['shipping', 'installationType', 'paymentType', 'shippingData']
    return fieldList.map(field => validateField(field)).every(state => state)
  }
  const handleSubmit = () => {
    if (validateAllFields()) {
      if (!checkUserIsAuth()) {
        saveStateInStorage()
        setLoginModal(true)
        return
      }
      const { id } = getUserData()
      const extractInstallmentText = () => {
        return paymentType.payment.replace(/[^0-9]/g, '') || 0
      }
      const redirectToProposal = (proposalId: string | number) => {
        const proposal = {
          kitData,
          laborCost: laborCost || 0,
          shippingData,
          paymentType,
          installationType: formInfo.installationType,
          kitId,
          childList,
        }
        localStorage.setItem('proposalJSON', encrypty(JSON.stringify(proposal)))
        setTimeout(() => {
          sessionStorage.removeItem('formState')
          window.open(`/propostas/detalhes/${proposalId}`, '_blank')
        }, 1000)
      }
      const itemKit = {
        kitId: kitData.id,
        descTipoInstalacaoId: formInfo.installationType?.descTipoId,
        tipoInstalacaoId: formInfo.installationType?.tipoInstalacaoId,
      }

      const quantidadeDeParcelas = extractInstallmentText()
      getTabelasKitById(itemKit).then(() => {

        const propostaData = {
          idIntegrador: id,
          valorServico: formInfo.laborCost || 0,
          condicaoPagto: getPaymentDesc(),
          detalhesCondicaoPagto: `${quantidadeDeParcelas || 1}x ${formatCurrency(
            quantidadeDeParcelas > 0
              ? formInfo.paymentType?.installment
              : formInfo.paymentType?.total)}`,
          cep: shippingData.cep.replace('-', ''),
          valorFrete: kitData.shipping,
          valorTotal: formInfo.paymentType?.total,
          idTabelaFretes: shippingData.id,
          idKit: kitData.id,
          idTipoEstrutura: formInfo.installationType?.idTipoFixacao,
          quantidade: 1,
          idKitTipoEstrutura: formInfo.installationType?.idKitTipoEstrutura,
        }
        postProposta(propostaData).then(resp => {
          if (!resp.proposta) return

          localStorage.setItem(
            'proposal',
            encrypty(JSON.stringify(resp.proposta))
          )
          redirectToProposal(resp.proposta.propostaId)
        })
      })
    }
  }

  const saveStateInStorage = () => {
    const temp = {
      shipping,
      shippingData,
      showShipping,
      installationType,
      paymentType,
      laborCost,
      selectedShippingPayment,
      insuranceAndTax,
      formInfo,
      kitData,
    }
    sessionStorage.setItem('formState', JSON.stringify(temp))
  }
  const loadState = () => {
    const temp = sessionStorage.getItem('formState')
    if (temp && !loginModal) {
      const tempState = JSON.parse(temp)
      setShipping(tempState.shipping)
      setShippingData(tempState.shippingData)
      setShowShipping(tempState.showShipping)
      setInstallationType(tempState.installationType)
      setPaymentType(tempState.paymentType)
      setLaborCost(tempState.laborCost)
      setSelectedShippingPayment(tempState.selectedShippingPayment)
      setInsuranceAndTax(tempState.insuranceAndTax)
      setTimeout(() => {
        Object.keys(tempState.formInfo).forEach(item =>
          handleChangeInfo(item, tempState.formInfo[item])
        )
      }, 500)
    }
  }

  const getPaymentDesc = () => {
    if (paymentType.payment.toLocaleLowerCase().includes('financiamento'))
      return 'financiamento'
    else if (
      paymentType.payment.toLocaleLowerCase().includes('cartão') ||
      paymentType.payment.toLocaleLowerCase().includes('cartao')
    )
      return 'parcelado'

    return 'a vista'
  }
  const extractInstallmentNumber = (data: string) => {
    return data.replace(/[^0-9]/g, '') || 1
  }

  const paymentTypeLabel = useMemo(() => {
    if (!paymentType.payment)
      return 'Selecione o tipo de pagamento de sua preferência'

    const [metodoPagamentoFirstWord] = paymentType.payment.split(' ')

    const metodoPagamento =
      metodoPagamentoFirstWord === 'Cartão'
        ? metodoPagamentoFirstWord
        : paymentType.payment

    const formattedTotal = formatCurrency(paymentType.total)
    const parcelas = extractInstallmentNumber(paymentType.payment)
    const valorParcela = parcelas > 1 ? paymentType.installment : paymentType.total
    const formattedValorParcela = formatCurrency(valorParcela)

    return `${metodoPagamento} - ${formattedTotal} em ${parcelas}x de ${formattedValorParcela}`
  }, [paymentType])

  const onChangeFormaPagamento = (e: TInputEvent) => {
    const { id } = e.target
    const getValue = () => {
      switch (id) {
        case 'incash': return formaPagamento.incash
        case 'card': return formaPagamento.card
        case 'financing': return formaPagamento.financing
      }
    }
    const value = getValue()
    const data = {
      ...formaPagamento,
      [id]: !value
    }
    setFormaPagamento(data)
  }

  const filteredFormasPagamento = useMemo(() => {
    const tiposArray = Object
      .entries(formaPagamento)
      .filter(item => item[1])
      .map(item => {
        const [key] = item
        return key.toUpperCase()
      })
    const filteredFormasPagamento = paymentOptions.filter(payment => (
      tiposArray.includes(payment.typePayment)
    ))
    return filteredFormasPagamento
  }, [
    formaPagamento,
    paymentOptions
  ])

  useEffect(() => {
    setPaymentType({} as IPaymentItem)
    const [paymentInCash] = filteredFormasPagamento

    if (!paymentInCash || filteredFormasPagamento.length > 1) return
    setPaymentType(paymentInCash)
    handleChangeInfo('paymentType', paymentInCash)
  }, [
    filteredFormasPagamento,
    formaPagamento,
    installationType,
    laborCost,
    insuranceAndTax,
    shipping
  ])

  return (
    <div className='requestForm'>
      <div className='mt-5'>
        <span className='span'>Tipo de instalação</span>
        <Dropdown className='dropdown'>
          <DropdownToggle
            disabled={isDisabledDropdownTipoInstalacao}
            className={`dropdownToggle ${fieldError.installationType ? 'error' : ''}`}
          >
            <span className='dropdownItemSelected'>
              {installationType.descricaoTipoFixacao && installationType.descricaoTipoInstalacao
                ? `${installationType.descricaoTipoFixacao} - ${installationType.descricaoTipoInstalacao}`
                : 'Selecione o tipo de instalação de sua preferência'}
            </span>
          </DropdownToggle>
          <DropdownMenu className='dropdownMenu'>
            {installationOptions.map((opt: IInstItem) => (
              <DropdownItem
                className='dropdownItem'
                key={`id-installation-${opt.descTipoId}`}
                onClick={() => handleChange('installationType', opt)}
              >
                <span>{`${opt.descricaoTipoFixacao} - ${opt.descricaoTipoInstalacao}`}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className='valuesGroup'>
        <div className='serviceValue'>
          <span className='span'>Valor do integrador (R$)</span>
          <MaskedCurrencyField
            value={laborCost}
            className={`currencyInput ${fieldError.laborCost && 'error'}`}
            name='value'
            onChange={e => handleChange('laborCost', e.target.value)}
            prefix='R$'
            isInvalid={fieldError.laborCost}
            maxValue={999999999.99}
          />
        </div>
        <div className='serviceValue'>
          <span className='span'>Seguro e Impostos (R$)</span>
          <MaskedCurrencyField
            value={insuranceAndTax}
            className={'currencyInput insuranceInput'}
            name='insuranceValue'
            onChange={() => { }}
            prefix='R$'
            isInvalid={fieldError.laborCost}
            disabled
          />
        </div>
        <div className='shipping'>
          <span className='cep-text'>
            Preencha o CEP de destino para calcular o frete.
          </span>
          <div className='shipping-fields'>
            <MaskedTextField
              value={shipping}
              mask='99999-999'
              name='shipping'
              onChange={e => handleChange('shipping', e.target.value)}
              className={`inputMask ${fieldError.shipping && 'error'}`}
              isInvalid={fieldError.shipping}
              onBlur={() => validateField('shipping')}
            />
            <Button
              type='primary'
              className={`product-details-button ${fieldError.shippingData && 'error'}`}
              onClick={handleCalculate}
            >
              Calcular
            </Button>
          </div>
          <div
            className={`shippingInfo ${showShipping ? 'show' : ''} ${fieldError.shipping && 'error'}`}
          >
            {cepShippingHasError ? (
              <span className='cep-invalid-label'>
                CEP inválido para cálculo do frete. Informe outro CEP ou entre em
                contato por telefone.
              </span>
            ) : (
              <>
                <span>{`Em até ${shippingData.prazoEntrega} úteis`}</span>
                <span>{formatCurrency(kitData.shipping || 0)}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='paymentType'>
        <span className='span'>Selecione a forma de pagamento</span>
        <div>
          <span className='span'>Filtros:</span>
          <Form.Check
            type='checkbox'
            label='À Vista'
            id='incash'
            checked={formaPagamento.incash}
            onChange={onChangeFormaPagamento}
          />
          <Form.Check
            type='checkbox'
            label='Cartão'
            id='card'
            checked={formaPagamento.card}
            onChange={onChangeFormaPagamento}
          />
          <Form.Check
            type='checkbox'
            label='Financiamento'
            id='financing'
            checked={formaPagamento.financing}
            onChange={onChangeFormaPagamento}
          />
        </div>
        <div className='paymentGroup'>
          <Dropdown className='dropdown'>
            <DropdownToggle
              className={`dropdownToggle ${fieldError.paymentType ? 'error' : ''}`}
            >
              {paymentTypeLabel}
            </DropdownToggle>
            <DropdownMenu className='dropdownMenu'>
              {!filteredFormasPagamento.length && (
                <DropdownItem
                  className='dropdownItem'
                >
                  <div className='emptyPaymentItem'>
                    <span>Nenhuma forma de pagamento filtrada</span>
                  </div>
                </DropdownItem>
              )}
              {filteredFormasPagamento.map((opt: IPaymentItem) => (
                <DropdownItem
                  className='dropdownItem'
                  key={opt.payment}
                  onClick={() => handleChange('paymentType', opt)}
                >
                  <div className='paymentItem'>
                    <span>{opt.payment}</span>
                    <span>
                      {`${formatCurrency(opt.total)} em ${extractInstallmentNumber(
                        opt.payment
                      )}x de ${formatCurrency(
                        extractInstallmentNumber(opt.payment) > 1
                          ? opt.installment
                          : opt.total
                      )}`}
                    </span>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <div>
            <Button
              type='primary'
              className='product-details-button'
              onClick={handleSubmit}
            >
              Gerar proposta do kit
            </Button>
          </div>
        </div>
      </div>
      <div className='accordionContainer'>
        {children}
        <DataSheetList childList={childList} />
      </div>
      <LoginModal
        isLoginRedirectModalOpen={loginModal}
        setIsLoginRedirectModalOpen={setLoginModal}
      />
    </div>
  )
}

export default DetailsForm
