import styled from 'styled-components'

export const PageTitle = styled.h1`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  padding: 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 20px;
    margin: 20px;
  }
`