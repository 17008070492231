import styled from 'styled-components'

export const ActionButton = styled.button`
  display: inline-flex;
  background: none;
  border: none;
  cursor: ${p => p.disabled ? 'unset' : 'pointer'};
  color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
  margin: 0 5px;
  
  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    color: ${p => p.disabled
    ? p.theme.colors.MEDIUM_GRAY
    : p.theme.colors.ORANGE
};
  }
`