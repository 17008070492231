import React, { createContext, useContext, useState, useEffect } from 'react'
import restApi, { storageKey } from 'services/axios'

type TUserData = {
  name: string
  token: string
  role: string
}

type TUseAuth = {
  isSignedIn: boolean
  userData?: TUserData
  hasToken(): boolean
  saveUserData(user: TUserData): void
  removeUserData(): void
}

const AuthContext = createContext<TUseAuth>({} as TUseAuth)

type TProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: TProps) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [userData, setUserData] = useState<TUserData>()

  useEffect(() => {
    const storageUserData = localStorage.getItem(storageKey)
    if (!storageUserData) return

    const parsedUserData = JSON.parse(storageUserData)
    setUserData(parsedUserData)
    setIsSignedIn(true)
    // setToken(parsedUserData.token)
  }, [])

  const saveUserData = (user: TUserData) => {
    localStorage.setItem(storageKey, JSON.stringify(user))
    setUserData(user)
    setIsSignedIn(true)
    setToken(user.token)
  }

  const removeUserData = () => {
    localStorage.removeItem(storageKey)
    setUserData(undefined)
    setIsSignedIn(false)
  }

  const setToken = (token: string) => {
    restApi.defaults.headers.Authorization = `Bearer ${token}`
  }

  const hasToken = () => Boolean(localStorage.getItem(storageKey))

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        userData,
        hasToken,
        saveUserData,
        removeUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): TUseAuth => {
  const context = useContext(AuthContext)

  return context
}

export { useAuth, AuthProvider }
