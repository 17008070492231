import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './ListFilters.scss'
import Select from '../Select/Select'
import { DefaultContainer } from '../StyledComponents'
import PrimaryButton from '../PrimaryButton'
import { getInstallationInverter, getPotencyRangeList } from '../../dataSource/DataRepository'

interface powerPotencyItem {
  key: number;
  potencyRange: string;
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

const ListFilters: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const [inverterList, setInverterList] = useState<string[]>([])
  const [powerFilterKey, setPowerFilterKey] = useState<string>('')
  const [powerFilterString, setPowerFilterString] = useState<string>('')
  const [inverterFilterKey, setInverterFilterKey] = useState<string>('')
  const [powerList, setPowerList] = useState<powerPotencyItem[]>([])

  useEffect(() => {
    getInstallationInverter().then(response => {
      if (!response.error) {
        const responseInverterList = response.filtersOutputList.map(
          (item: { inversor: string }) => (item.inversor))
        setInverterList(responseInverterList)
      }
    })
  }, [])

  const onSubmitFilter = (params: { power?: string; inverter?: string; }) => {
    let power
    let inverter

    if (params.power !== undefined) {
      ({ power } = params)
    } else {
      power = powerFilterKey
    }

    if (params.inverter !== undefined) {
      ({ inverter } = params)
    } else {
      inverter = inverterFilterKey
    }

    let powerString = ''
    if (power) {
      powerString = `?power=${power}`
    }
    let inverterString = ''
    if (inverter) {
      inverterString = `${powerString ? '&' : '?'}inverter=${inverter}`
    }

    history.push(`/${powerString}${inverterString}`)

  }

  useEffect(() => {
    getPotencyRangeList().then(response => {
      if (!response.error)
        setPowerList(response.map((item: {
          potency_range_id: number,
          initialValue: number,
          finalValue: number
        }) => {
          return {
            key: item.potency_range_id,
            potencyRange: `${item.initialValue} - ${item.finalValue} kWp`
          }
        }))
    })
    let power = ''
    let inverter = ''
    let powerString = ''
    try {
      const powerQuery = query.get('power')
      const inverterQuery = query.get('inverter')
      power = powerQuery !== null ? JSON.parse(powerQuery) : ''
      inverter = inverterQuery !== null ? inverterQuery : ''
      powerString = powerList.find(item => item.key.toString() === power.toString())?.potencyRange || ''
    } catch (err) {
      power = ''
      inverter = ''
      powerString = ''
    } finally {
      setPowerFilterKey(power)
      setInverterFilterKey(inverter)
      setPowerFilterString(powerString)
    }

  }, [location])

  const onClickGoToPersonalizarKit = () => history.push('/personalizar-kits')

  return (
    <div className='ListFilters'>
      <DefaultContainer>
        <div className='filters-container'>
          <div className='filters-form'>
            <div>
              <div className='filter-text' style={{ marginTop: 15 }}>Kits de Geradores por</div>
              <Select
                divClassName='filter-select'
                value={powerFilterKey}
                onChange={e => { onSubmitFilter({ power: e.target.value }) }}
              >
                <option value=''>Potência</option>
                {powerList?.map(item => (
                  <option key={item.key} value={item.key}>{item.potencyRange}</option>
                ))}
              </Select>

              <Select
                divClassName='filter-select'
                value={inverterFilterKey}
                onChange={e => { onSubmitFilter({ inverter: e.target.value }) }}
              >
                <option value=''>Inversor</option>
                {inverterList?.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Select>
            </div>
            <PrimaryButton onClick={onClickGoToPersonalizarKit}>
              Personalizar o Kit
            </PrimaryButton>
          </div>
          <div className='active-filters'>
            Filtros selecionados
            {powerFilterString && <span>&nbsp;&nbsp;/&nbsp;&nbsp;{powerFilterString}</span>}
            {inverterFilterKey && <span>&nbsp;&nbsp;/&nbsp;&nbsp;{inverterFilterKey}</span>}
            {!powerFilterString && !inverterFilterKey && <span>&nbsp;&nbsp;/&nbsp;&nbsp;Nenhum filtro selecionado.</span>}
          </div>
        </div>
      </DefaultContainer>
    </div>
  )
}

export default ListFilters
