import React, { useState } from 'react'
import Routes from 'routes/routes'
import Header from '../../../components/header/Header'
import { SalesKitDetailsContainer } from './SalesKitStyles'
import BreadCrumb from 'components/Form/BreadCrumb/BreadCrumb'
import ProductDetails from 'components/ProductDetails/ProductDetails'

const SalesKitDetails: React.FC = () => {
  const [crumbList, setCrumbList] = useState([
    {
      label: 'Kits de geradores de energia solar',
      isLink: true,
      isActive: false,
      url: Routes.kitList
    },
  ])

  const handleAddCrumb = (item: any) => {
    setCrumbList([...crumbList, item])
  }

  return (
    <SalesKitDetailsContainer>
      <Header />

      <BreadCrumb crumbList={crumbList} />

      <ProductDetails handleAddCrumb={handleAddCrumb} />

    </SalesKitDetailsContainer>
  )
}

export default SalesKitDetails
