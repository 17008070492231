import styled from 'styled-components'
import background from 'assets/background.jpg'


export const UnsignedContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${background}) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`