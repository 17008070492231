import React from 'react'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'
import './LoginWrapper.scss'
import { DefaultContainer } from 'components/StyledComponents'
import Routes from '../../routes/routes'

interface IProps {
  title: string;
  children: React.ReactNode;
}

const LoginWrapper: React.FC<IProps> = ({ title, children }: IProps) => {

  return(
    <>
      <Header />
      <div className='container LoginWrapper'>
        <div className='title'>
          <div className='login-breadcrumb'>
            <DefaultContainer>
              <div className='breadcrumb-content'>
                <a href='/'>Início</a>
                <span>&nbsp;/&nbsp;</span>
                <a href={Routes.loginPage}>Login</a>
              </div>
            </DefaultContainer>
          </div>
          <DefaultContainer>
            <div className='account-access-message'>
              {title}
            </div>
          </DefaultContainer>
        </div>
        <DefaultContainer>
          <div className='login-content'>
            {children}
          </div>
        </DefaultContainer>
      </div>
      <Footer />
    </>
  )
}

export default LoginWrapper
