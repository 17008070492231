
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { BsDownload } from 'react-icons/bs'
import { useToasts } from 'react-toast-notifications'

import { DefaultContainer } from 'components/StyledComponents'
import './ProductProposalStyles.scss'
import ProposalDetails from './proposalDetails/ProposalDetails'
import ProductProposalInfo from './productProposaltInfo/ProductProposaltInfo'
import { formatDateTimeToView } from 'utils/helpers'
import { getProposta } from 'dataSource/DataRepository'
import Proposta, { TPropostaInfo } from 'pages/Proposta'
import {
  BoxContainer,
  BreadCrumb,
  Header,
  Loader,
  PageTitle,
  FlexContainer,
  GoBackButton,
  DarkButton,
  ConfirmButton
} from 'components'
import { TextInfo } from './styles'
import { Variant } from 'utils/constants'
import useRequests from 'repositories/useRequests'
import jsPDF from 'jspdf'

const confirmationMessageGenerateRequest = 'Deseja mesmo gerar um pedido a partir desta proposta?'
const toastSuccessMessage = 'Pedido criado com sucesso!'

interface IDataSheet {
  label: string
  content: string
}

const ProductProposal = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [datasheets, setDatasheets] = useState<IDataSheet[]>([])
  const [propostaInfo, setPropostaInfo] = useState({} as TPropostaInfo)

  const { proposalId } = useParams<{ proposalId: string }>()
  const repository = useRequests()
  const { addToast } = useToasts()
  const history = useHistory()

  const itemsBreadcrumb = [
    {
      label: 'Kits de geradores de energia solar',
      isLink: true,
      isActive: false,
      url: '/'
    },
    {
      label: 'Consulta de Propostas',
      isLink: true,
      isActive: false,
      url: '/propostas'
    },
    {
      label: `Proposta ${proposalId}`,
      isLink: false,
      isActive: true,
      url: '/'
    },
  ]

  useEffect(() => {
    const requestProposta = () => {
      getProposta(proposalId)
        .then(response => {
          if (!response) return
          const { propostaAllInfosOutput } = response
          setPropostaInfo(propostaAllInfosOutput)
          const datasheets = [
            {
              label: 'Datasheet Inversor',
              content: propostaAllInfosOutput.dataSheetInversor
            },
            {
              label: 'Datasheet Módulo',
              content: propostaAllInfosOutput.dataSheetModulo
            },
          ]
          setDatasheets(datasheets)
          setIsLoading(false)
        })
    }
    if (proposalId) requestProposta()
  }, [proposalId])


  const onClickGenerateRequest = async () => {
    const data = {
      propostaId: Number(proposalId),
      integradorId: propostaInfo.idIntegrador
    }

    const requestId = await repository.postCreateRequest(data)
    if (!requestId) return

    addToast(toastSuccessMessage, {
      appearance: 'success',
      autoDismiss: true
    })
    history.push(`/pedidos/detalhes/${requestId}`)
  }

  const generateProposalPdf = async () => {
    const doc = new jsPDF('p', 'pt', 'a4')
    const content = document.querySelector('#proposta') as HTMLElement
    content.style.display = 'block'
    if (!content) return
    await doc.html(content)
    doc.save('proposta.pdf')
    content.style.display = 'none'
  }

  return (
    <>
      <Header />
      <BreadCrumb crumbList={itemsBreadcrumb} />
      <DefaultContainer className='ProductDetailsContainerProposta'>
        <PageTitle>
          Proposta {proposalId}
        </PageTitle>
        <BoxContainer isBordered>
          <FlexContainer spaced>
            <TextInfo>Consulte as informações da Proposta selecionada.</TextInfo>
            <GoBackButton
              showConfirmation={false}
              route='/propostas'
            />
          </FlexContainer>
          <FlexContainer spaced>
            <TextInfo>
              Proposta gerada dia {formatDateTimeToView(propostaInfo.createdAt)} com
              o número de Integrador {propostaInfo.idIntegrador}
            </TextInfo>
            <div>
              <ConfirmButton
                isLoading={false}
                variant={Variant.PRIMARY}
                message={confirmationMessageGenerateRequest}
                actionFn={onClickGenerateRequest}
              >
                Gerar Pedido
              </ConfirmButton>
              <DarkButton
                onClick={generateProposalPdf}
                iconPosition='right'
              >
                <span>Download PDF</span>
                <BsDownload />
              </DarkButton>
            </div>
          </FlexContainer>

          <Loader isLoading={isLoading} />
          {!isLoading && (
            <>
              <ProductProposalInfo
                proposalData={propostaInfo}
              />
              <ProposalDetails
                childList={datasheets}
                proposalData={propostaInfo}
              />
            </>
          )}
        </BoxContainer>
      </DefaultContainer>
      <div className='pdf'>
        {propostaInfo && <Proposta propostaInfo={propostaInfo} id={proposalId} />}
      </div>
    </>
  )
}

export default ProductProposal
