enum Colors {
  LIGHTER_GRAY = '#F3F3F3',
  LIGHT_GRAY = '#D3D3D3',
  MEDIUM_GRAY = '#888888',
  DARK_GRAY = '#565556',
  DARKER_GRAY = '#404041',
  ORANGE = '#F28F28',
  DARK_ORANGE = '#FD4A1D',
  DARK_YELLOW = '#FE961D',
  BLACK = '#1e1e1e',
  WHITE = '#FFFFFF',
  RED = '#FD4A1D',
}

enum BreakPoints {
  SMALL_SCREEN = '542px',
  MEDIUM_SCREEN = '1187px',
  BS_MD = '767px',
  BS_LG = '992px'
}

type TTheme = {
  colors: typeof Colors
  breakPoints: typeof BreakPoints
}

export const theme: TTheme = {
  colors: Colors,
  breakPoints: BreakPoints
}
