import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  FaPencilAlt,
  FaPlusCircle,
  FaTimes,
  FaTrash
} from 'react-icons/fa'
import {
  useToasts,
  Options
} from 'react-toast-notifications'

import {
  Col, Form
} from 'react-bootstrap'

import {
  FormSubTitle,
  FormLabel,
  SelectField,
  TextField,
  FlexContainer,
  Divider,
  ModalConfirmation,
  BoxContainer
} from 'components'
import useKits from 'repositories/useKits'
import { getFormattedDouble } from 'utils/format'
import { upperCaseFirstLetter } from 'utils/helpers'
import { toastAddMessage, toastDeleteMessage } from 'utils/constants'

import {
  CustomKitBox,
  ItemRow,
  DesktopLabel,
  MobileLabel,
  Description,
  MobileDiv,
  InputGroupContainer,
  ActionButton
} from './styles'

import {
  FormRow,
  AccordionTitle
} from '../../styles'

interface IKitForm {
  tipoItem: string
  item: string
  quantidade: string
}

const initialValues: IKitForm = {
  tipoItem: '',
  item: '',
  quantidade: ''
}

const schema = Yup.object().shape({
  tipoItem: Yup.string().required('Campo "Tipo de Item" é obrigatório'),
  item: Yup.number().required('Campo "Item" é obrigatório'),
  quantidade: Yup
    .number()
    .min(1, 'Campo "Quantidade" não pode ser menor que zero')
    .required('Campo "Quantidade" é obrigatório'),
})

const messageModal = 'Deseja mesmo remover esse item ?'

type TItensSelectOption = {
  custoUnitario: number
  label: string
  value: string
  id: number
  tipo: string
}

const toastSuccessConfig = {
  appearance: 'success',
  autoDismiss: true
} as Options

interface IProps {
  customSetFieldValue(field: keyof IMontagemKitForm, value: any): void
  fornecedorId: number
  itensList: ICustomKitItens[]
  customSetItens(itens: ICustomKitItens[]): void
}

const Itens = (props: IProps) => {
  const {
    customSetFieldValue,
    fornecedorId,
    itensList,
    customSetItens
  } = props

  const [isEditable, setIsEditable] = useState(false)
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false)
  const [id, setId] = useState<number>()
  const [tiposItensOptions, setTiposItensOptions] = useState<TSelectOption[]>([])
  const [itensOptions, setItensOptions] = useState<TItensSelectOption[]>([])

  const { addToast } = useToasts()
  const repository = useKits()

  useEffect(() => {
    const getTiposItens = async () => {
      const tiposItens = await repository.getTiposItens(fornecedorId)
      if (!tiposItens) return

      const formattedTiposItens = tiposItens.map(item => ({
        value: item,
        label: upperCaseFirstLetter(item),
      }))
      setTiposItensOptions(formattedTiposItens)
    }

    if (fornecedorId) getTiposItens()
  }, [fornecedorId])

  const onSubmit = () => {
    const { values } = formik
    const _item = itensOptions.find(item => item.value === values.item)
    if (!_item) return

    const total = _item.custoUnitario * Number(values.quantidade)
    const item: ICustomKitItens = {
      id: _item.id,
      item: _item.label,
      custoUnitario: String(_item.custoUnitario),
      quantidade: Number(values.quantidade),
      total: String(total),
      tipo: _item.tipo
    }
    customSetFieldValue('customizado', 'true')
    const itens = [...itensList, item]
    customSetItens(itens)
    formik.resetForm()
    addToast(toastAddMessage, toastSuccessConfig)
  }

  const formik = useFormik<IKitForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit
  })

  const hasError = (name: keyof IKitForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof IKitForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const onChangeTipoItem = (e: TInputEvent) => {
    const { value } = e.target
    const getItensByFornecedorTipo = async () => {
      const params = {
        fornecedorId,
        tipo: value
      }
      const response = await repository.getItensByTipoAndFornecedor(params)
      if (!response) return

      const formattedItens = response.map(item => ({
        value: String(item.id),
        label: item.nome,
        id: item.id,
        custoUnitario: item.custoUnitario,
        tipo: item.tipo
      }))

      setItensOptions(formattedItens)
    }

    formik.setFieldValue('item', '')
    formik.setFieldValue('tipoItem', value)
    getItensByFornecedorTipo()
  }

  const valorTotal = useMemo(() => (
    itensList.reduce((acc, curr) => (
      acc + Number(curr.total)
    ), 0)
  ), [itensList])

  const onClickShowItemEditing = () => {
    setIsEditable(!isEditable)
    formik.resetForm()
    setItensOptions([])
  }

  const onClickRemoveItem = () => {
    const filteredItens = itensList.filter(item => item.id !== id)
    customSetItens(filteredItens)
    setIsModalConfirmationOpen(false)
    customSetFieldValue('customizado', 'true')
    addToast(toastDeleteMessage, toastSuccessConfig)
  }

  const onClickShowConfirmationModal = (id: number) => () => {
    setId(id)
    setIsModalConfirmationOpen(true)
  }

  return (
    <>
      <Divider />
      {isEditable && (
        <BoxContainer>
          <FormRow>
            <Col>
              <FormSubTitle>Adicionar Itens no Kit</FormSubTitle>
            </Col>
          </FormRow>

          <Form onSubmit={formik.handleSubmit}>
            <FormRow>
              <Col md={3}>
                <SelectField
                  required
                  label='Tipo do Item'
                  options={tiposItensOptions}
                  {...getCommonFieldProps('tipoItem')}
                  onChange={onChangeTipoItem}
                />
              </Col>
              <Col md={5}>
                <SelectField
                  required
                  label='Escolha do Item'
                  options={itensOptions}
                  {...getCommonFieldProps('item')}
                />
              </Col>
              <Col md={4}>
                <FormLabel required>
                  Quantidade
                </FormLabel>
                <InputGroupContainer>
                  <TextField
                    required
                    type='number'
                    label=''
                    {...getCommonFieldProps('quantidade')}
                  />
                  <ActionButton
                    type='submit'
                  >
                    <FaPlusCircle
                      size={26}
                    />
                  </ActionButton>
                  <ActionButton onClick={onClickShowItemEditing}>
                    <FaTimes />
                    <span>Cancelar</span>
                  </ActionButton>
                </InputGroupContainer>
              </Col>
            </FormRow>
          </Form>
        </BoxContainer>
      )}
      <BoxContainer>
        <FormRow>
          <FlexContainer>
            <AccordionTitle>Itens do Kit</AccordionTitle>
            {!isEditable && (
              <ActionButton
                onClick={onClickShowItemEditing}
              >
                <FaPencilAlt />
                <span>Editar Itens</span>
              </ActionButton>
            )}
          </FlexContainer>
        </FormRow>
        <CustomKitBox>
          <ItemRow>
            <Description>Itens</Description>
            <DesktopLabel>Preço Unitário</DesktopLabel>
            <DesktopLabel>Quantidade </DesktopLabel>
            <DesktopLabel>Subtotal</DesktopLabel>
            <DesktopLabel />
          </ItemRow>
          {itensList.map(item => (
            <ItemRow key={item.id}>
              <MobileDiv>
                <MobileLabel>Item: </MobileLabel>
                <Description>{item.item}</Description>
              </MobileDiv>
              <MobileDiv>
                <MobileLabel>Preço: </MobileLabel>
                <Description>R$ {getFormattedDouble(item.custoUnitario)}</Description>
              </MobileDiv>
              <MobileDiv>
                <MobileLabel>Qtd: </MobileLabel>
                <Description>{item.quantidade}</Description>
              </MobileDiv>
              <MobileDiv>
                <MobileLabel>Subtotal: </MobileLabel>
                <Description>R$ {getFormattedDouble(item.total)}</Description>
              </MobileDiv>
              <MobileDiv>
                <MobileLabel />
                <Description>
                  {isEditable && (
                    <ActionButton
                      onClick={onClickShowConfirmationModal(item.id)}
                    >
                      <FaTrash />
                    </ActionButton>
                  )}
                </Description>
              </MobileDiv>
            </ItemRow>
          ))}
          <ItemRow>
            <Description>TOTAL DO KIT</Description>
            <Description>R$ {getFormattedDouble(valorTotal)}</Description>
          </ItemRow>
        </CustomKitBox>
      </BoxContainer>
      <ModalConfirmation
        setIsModalOpen={setIsModalConfirmationOpen}
        isModalOpen={isModalConfirmationOpen}
        confirmationText={messageModal}
        confirmationFunction={onClickRemoveItem}
      />
    </>
  )
}

export default Itens