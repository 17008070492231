import Moment from 'moment'
import AES from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'
import { ENCRYPTION_KEY } from './constants'

export const encrypty = (value: string) => AES.encrypt(value, ENCRYPTION_KEY).toString()

export const decrypty = (value: string) => {
  if (value) {
    return AES.decrypt(value, ENCRYPTION_KEY).toString(enc)
  }

  return null
}

export const formatDateTimeToView = (date: any) => (
  Moment(date).format('DD/MM/YYYY [às] HH:mm')
)

export const formatDateToServer = (date: string) => (
  Moment(date).format('YYYY-MM-DD')
)

export const removeTimeFromDateTime = (date: string) => (
  date.substring(0, 10)
)

export const formatDecimalNumber = (number: any) => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'decimal',
      maximumFractionDigits: 2
    }
  )

  return formatter.format(number)
}

export const formatDecimalNumberToPercentage = (number: any) => (
  `${formatDecimalNumber(number)} %`
)

export const formatCurrency = (number: any) => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL'
    }
  )

  return formatter.format(number)
}

export const formatDateToView = (date: any) => {
  if (!date) return ''
  return Moment(date).format('DD/MM/YYYY')
}

export const getCurrentDate = () => Moment().format('YYYY-MM-DD')

export const getDiffDaysBetweenDates = (start: any, end: any) => {
  const startDate = Moment(start, 'YYYY-MM-DD')
  const endDate = Moment(end, 'YYYY-MM-DD')
  return Moment.duration(endDate.diff(startDate)).asDays()
}

export const fileToBase64 = async (file: Blob) => {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const [, data] = String(base64).split(',')

  return data
}

export const downloadBase64File = async (
  fileName: string,
  base64string: string,
  fileFormat = 'application/pdf'
) => {
  await new Promise<void>((resolve, reject) => {
    try {
      const linkSource = `data:${fileFormat};base64,${base64string}`

      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export const formatCurrencyToServer = (number: string) => {
  if (!number) return '0'

  if (number.indexOf(','))
    return number.replace(/\./g, '').replace(',', '.')

  return number
}

export const getApiErroMessage = (code: number) => {
  switch (code) {
    case -35: return 'Pedido não foi encontrado.'
    case -39: return 'Não foi encontrado o integrador na proposta.'
    case -45: return 'Não foi possível confirmar seu pedido, pois já existe pedido cadastrado para essa proposta.'
    default: return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
  }
}

export const formatDateEnUs = (date: Moment.MomentInput) => (
  Moment(date).format('YYYY-MM-DD hh:mm')
)

export const getYesNoFromBoolean = (value: boolean) => (
  value ? 'Sim' : 'Não'
)

export const removeMaskGuides = (value: string) => (
  value
    .replace(/_/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/ /g, '')
    .replace(/[^\d]+/g, '')
)

export const formatCpfCnpj = (number: string) => {
  const [regext, replacement] = number.length === 11
    ? [/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4']
    : [/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5']

  return number.replace(regext, replacement)
}

export const formatCep = (number: string) => number.replace(/(\d{5})(\d{3})/, '$1-$2')

export const addDaysInDate = (days: string) => (
  Moment(new Date()).add(days, 'days').format('YYYY-MM-DD')
)

export const downloadUrl = (url: string, name: string): void => {
  const link = document.createElement('a')

  link.download = name
  link.href = url
  link.target = '_BLANK'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const removeCurrencyMask = (value: string) => {
  return value.toString().replace(/[^0-9,]*/g, '').replace(',', '.')
}

export const upperCaseFirstLetter = (text: string) => {
  const _text = text.toLowerCase().replace(/(?:^|\s)\S/g, firstCharacter => (
    firstCharacter.toUpperCase()
  ))
  return _text
}