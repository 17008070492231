import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ButtonsContainer, PrimaryButton, SecondaryButton } from 'components'
import { Variant } from 'utils/constants'

type TProps = {
  active?: boolean
  children: React.ReactNode
  variant: Variant
  actionFn(): void
  message?: string
  isLoading?: boolean
}

const ConfirmButton = (props: TProps) => {
  const {
    active = true,
    children,
    variant,
    actionFn,
    message,
    isLoading = false
  } = props

  const [showModal, setShowModal] = useState(false)

  const showModalFn = () => setShowModal(true)
  const hideModalFn = () => setShowModal(false)

  const executeAction = () => {
    actionFn()
    hideModalFn()
  }

  const onClickCheck = () => active ? showModalFn() : executeAction()

  const Button = variant === Variant.PRIMARY
    ? PrimaryButton
    : SecondaryButton

  return (
    <>
      <Button onClick={onClickCheck} isLoading={isLoading}>
        {children}
      </Button>

      <Modal
        size='sm'
        show={showModal}
        onHide={hideModalFn}
      >
        <Modal.Body>
          {message}
        </Modal.Body>

        <Modal.Footer>
          <ButtonsContainer compact>
            <SecondaryButton onClick={hideModalFn}>
              Não
            </SecondaryButton>

            <PrimaryButton onClick={executeAction} isLoading={isLoading}>
              Sim
            </PrimaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmButton