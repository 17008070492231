import React, { SelectHTMLAttributes } from 'react'
import './Select.scss'

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  divClassName?: string,
}

const Select: React.FC<IProps> = ({ divClassName, children, ...rest }: IProps) => {

  return (
    <div className={`CustomSelect ${divClassName}`}>
      <select {...rest}>
        {children}
      </select>
    </div>
  )
}

export default Select
