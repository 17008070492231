import styled from 'styled-components'
import { Row } from 'react-bootstrap'

export const TextInfo = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
`

export const TextSection = styled.span`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.88px;
`

export const FormRow = styled(Row)`
  margin-top: 10px;
`

export const AccordionTitle = styled.span`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.BLACK};
`