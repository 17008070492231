import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './RecoverPassword.scss'
import { Button } from 'components/StyledComponents'
import { forgotPasswordTokenRequest, resetPasswordRequest } from '../../dataSource/DataRepository'
import { checkUserIsAuth } from '../../utils/users'
import Routes from '../../routes/routes'
import LoginWrapper from './LoginWrapper'
import Spinner from '../../components/Spinner/Spinner'

interface IRecoverPasswordParams {
  newPassword: string;
  token: string;
}

interface IRecoverPasswordForm {
  password: string;
  confirmPassword: string;
}

const senhaRegex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>"'`~´]){1})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/

const validationSchema = yup.object().shape({
  password: yup.string().required('Informe uma nova senha')
    .matches(
      senhaRegex,
      'A nova senha precisa ter pelo menos 8 caracteres, sendo um caractere especial, letras e números'
    ),
  confirmPassword: yup.string()
    .required('O campo "Confirmação de Senha" é obrigatório')
    .oneOf(
      [yup.ref('password'), ''],
      'Os campos "Nova Senha" e "Confirmação de Senha" não conferem'
    )
})

const initialValues: IRecoverPasswordForm = {
  password: '',
  confirmPassword: '',
}

const RecoverPassword: React.FC = () => {
  const history = useHistory()
  const [error, setError] = useState(false)
  const [recoveryCompleted, setRecoveryCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { token } = useRouteMatch().params as { token: string }

  useEffect(() => {
    if (checkUserIsAuth()) {
      history.push(Routes.kitList)
    }
    setLoading(true)

    forgotPasswordTokenRequest(token)
      .then(response => {
        setLoading(false)

        if (!response.tokenIsValid) {
          history.push(Routes.kitList)
        }
      })
      .catch(() => {
        // message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
        setLoading(false)
      })

  }, [])

  function doLogin() {
    const params: IRecoverPasswordParams = {
      newPassword: formik.values.password,
      token,
    }
    setLoading(true)

    resetPasswordRequest(params)
      .then(response => {
        setLoading(false)

        if (!response.isError) {
          // login ok
          // history.push(Routes.kitList);
          setRecoveryCompleted(true)
        } else {
          setError(true)
        }
      })
      .catch(() => {
        // message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
        setLoading(false)
      })
  }

  const formik = useFormik<IRecoverPasswordForm>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: doLogin,
  })

  const hasError = (name: keyof IRecoverPasswordForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  useEffect(() => {
    setError(false)
  }, [formik.values.password, formik.values.confirmPassword])

  return (
    <div className='RecoverPassword'>
      <LoginWrapper title='Redefinição de senha'>
        <div className='login-form-components'>
          {recoveryCompleted ? (
            <>
              <span className='to-login-text'>Senha redefinida com sucesso!</span>
              <Button
                type='primary'
                className='submit-button'
                onClick={() => history.push(Routes.loginPage)}
              >
                Login
              </Button>
            </>
          ) : (
            <>
              <span className='to-login-text'>
                Para a redefinir sua senha, entre com uma senha nova e a confirme. Em seguida, clique em
                &ldquo;Redefinir&ldquo; para efetivar a alteração
              </span>
              <Spinner loading={loading}>
                <form className='login-form' onSubmit={formik.handleSubmit}>

                  <input
                    type='password'
                    placeholder='Senha'
                    className={hasError('password') ? 'is-invalid' : ''}
                    {...formik.getFieldProps('password')}
                  />
                  <span className='error-message'>{formik.errors.password}</span>

                  <input
                    type='password'
                    placeholder='Confirmar senha'
                    className={hasError('confirmPassword') ? 'is-invalid' : ''}
                    {...formik.getFieldProps('confirmPassword')}
                  />
                  <span className='error-message'>{formik.errors.confirmPassword}</span>
                  {error && <span className='error-message'>Erro ao recuperar senha</span>}

                  <Button type='primary' htmlType='submit' className='submit-button'>Redefinir</Button>
                </form>
              </Spinner>
            </>
          )}
        </div>
      </LoginWrapper>
    </div>
  )
}

export default RecoverPassword
