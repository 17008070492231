import styled from 'styled-components'

type TBoxProps = {
  width?: number
  height?: number
}

export const UnsignedBox = styled.div<TBoxProps>`
  width: 100%;
  max-width: ${p => `${p.width}px` ?? 'auto'};
  height: ${p => `${p.height}px` ?? 'auto'};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 5px;
  padding: 30px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.25);
`