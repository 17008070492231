import styled from 'styled-components'

export const CustomLink = styled.a`
  color: ${({ theme }) => theme.colors.DARK_YELLOW};
  font-weight: 700;
  display: block;
  cursor: pointer;
  display: flex;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.DARK_YELLOW};
    text-decoration: none;
  }

  svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    font-size: 22px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`