import { DefaultContainer } from 'components/StyledComponents'
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import styled from 'styled-components'

export const DefaultContainerStyled = styled(DefaultContainer)`
  display: flex;
  align-items: center;
  height: 65px;
  width: 100vw;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    height: auto;
  }
`
export const BreadCrumbStyled = styled(Breadcrumb)`
    border-bottom: 1px solid rgba(112, 112, 112, 0.25);

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
      ol div {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 10px 0;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_MD}) {
      ol div {
        font-size: 14px;
      }
    }

    ol {
      margin: 0;
    }
    
    color: #565556;
    .breadcrumb-item.active + .breadcrumb-item.active::before {
      color: #565556 !important;
    }

  `

export const BreadCrumbItemStyled = styled(BreadcrumbItem)`
    a, span, ::before {
      color: #565556 !important;
      text-decoration: none;
      font-weight: 400;
    }

    .active {
      font-weight: bold;
    }
`