import styled from 'styled-components'
import { FormLabel as Label } from 'react-bootstrap'

type TLabelProps = {
  required?: boolean
}

export const FormLabel = styled(Label)<TLabelProps>`
  display: block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.DARK_GRAY};

  &::after {
    content: ${p => p.required ? '"*"' : ''};
    margin-left: 5px;
  }
`