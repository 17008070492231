import React from 'react'
import { Button } from '../StyledComponents'
import { useHistory } from 'react-router-dom'
import './ProductCard.scss'
import imgProductDefault from '../../assets/images/img-product-default.png'
import Routes from '../../routes/routes'
import { IProduct } from '../Interfaces'
import { getFormattedDouble } from '../../utils/format'

interface IProps {
  product: IProduct;
}

const ProductCard: React.FC<IProps> = ({ product }) => {
  const history = useHistory()

  const highPriceBreakPoint = 1000000
  const isPriceHigh = product.precoVenda > highPriceBreakPoint

  return (
    <div className='ProductCard'>
      <img src={product.imagem || imgProductDefault} alt='' />
      <div className='product-description-container'>
        <div className='product-title'>{product.nome}</div>
        <div className='product-description'>
          Marca: {product.fabricanteModulo} <br />
          Inversor: {product.fabricanteInversor}
        </div>
      </div>
      <div className='price-container'>
        <div className='price-text'>a partir de</div>
        <div className={`price-value ${isPriceHigh && 'higher-price'}`}>
          R$ {getFormattedDouble(product.precoVenda)}</div>
      </div>
      <Button
        type='primary'
        className='product-details-button'
        onClick={() => history.push(`${Routes.kitDetail}/${product.id}`)}
      >
        Detalhes do kit
      </Button>
    </div>
  )
}

export default ProductCard
