import styled from 'styled-components'
import { Form } from 'react-bootstrap'

type TButtonsContainerProps = {
  compact?: boolean
}

export const ButtonsContainer = styled(Form.Row)<TButtonsContainerProps>`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: ${p => p.compact ? 0 : '20px'};
`