import React, { useMemo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import {
  Form,
  Col
} from 'react-bootstrap'

import {
  Header,
  BreadCrumb,
  Footer,
  FlexContainer,
  GoBackButton,
  TextField,
  MaskedCurrencyField,
  SelectField,
  FormSubTitle,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  ModalConfirmation,
  PageTitle,
  BoxContainer
} from 'components'

import {
  TextInfo,
  FormRow
} from './styles'

import {
  confirmationMessage,
  itemsTensao,
  estruturaFixacao,
  orientacaoEstrutura
} from 'utils/constants'
import ItensKit from './Components/Itens'
import useKits from 'repositories/useKits'
import { formatCurrencyToServer, upperCaseFirstLetter } from 'utils/helpers'
import { DefaultContainer } from 'components/StyledComponents'

const initialValues: IMontagemKitForm = {
  potencia: '',
  overloadMinimo: '20',
  overloadMaximo: '20',
  tensao: '',
  tipoEstrutura: '',
  estruturaFixacao: '',
  orientacaoEstrutura: '',
  customizado: 'false'
}

const schema = Yup.object().shape({
  potencia: Yup
    .string()
    .test(
      '',
      'Campo "Potência" não pode ser menor que zero',
      value => (parseFloat(value!) > 0)
    ).required('Campo "Potência" é obrigatório'),
  overloadMinimo: Yup
    .number()
    .min(0, 'Não pode ser menor que zero')
    .max(100, 'Não pode ser maior que "100%"')
    .required('Campo "Overload Mínimo" é obrigatório'),
  overloadMaximo: Yup
    .number()
    .min(0, 'Não pode ser menor que zero')
    .max(100, 'Não pode ser maior que "100%"')
    .required('Campo "Overload Máximo" é obrigatório'),
  tensao: Yup
    .string()
    .when('potencia', {
      is: (potencia: string) => (parseFloat(potencia) > 10),
      then: Yup
        .string()
        .test(
          '',
          'Para uma potência acima de 10kW, o sistema deverá ser Trifásico',
          value => {
            if (!value) return false

            const itensTensaoTrifasico = ['2', '3', '4']
            return itensTensaoTrifasico.includes(value!)
          }
        )
    })
    .required('Campo "Tensão" é obrigatório'),
  tipoEstrutura: Yup.string().required('Campo "Tipo de estrutura" é obrigatório'),
  estruturaFixacao: Yup.number().required('Campo "Estrutura de fixação" é obrigatório')
})

const itemsBreadCrumb = [
  {
    label: 'Kit de geradores de energia solar',
    isLink: true,
    isActive: true,
    url: '/'
  },
  {
    label: 'Personalizar Kit',
    isLink: false,
    isActive: false,
    url: '/'
  },
]

const toastEmptyResult = 'Não foi encontrado nenhum kit para fazer um novo kit customizado!'
const toastSuccess = 'Seu Kit customizado foi gerado com sucesso!'
const messageModal = 'Ao clicar em confirmar, sera gerado um kit customizado. Deseja mesmo continuar ?'
const messagePotenciaMaiorQueTrinta = 'Um transformador de mesma potência será adicionado, pois a mesma é maior que 30kWp'

const PersonalizarKits = () => {
  const [itens, setItens] = useState<ICustomKitItens[]>([])
  const [fornecedorId, setFornecedorId] = useState<number>(0)
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false)
  const [tiposEstruturasOptions, setTiposEstruturasOptions] = useState<TSelectOption[]>([])
  const [isSavingKit, setIsSaving] = useState(false)

  const repository = useKits()
  const { addToast } = useToasts()
  const history = useHistory()

  useEffect(() => {
    const getInformacoes = async () => {
      const response = await repository.getTiposEstruturas()
      if(!response) return

      const formattedTiposEstruturas = response.map(tipo => ({
        value: tipo,
        label: upperCaseFirstLetter(tipo)
      }))
      setTiposEstruturasOptions(formattedTiposEstruturas)
    }
    getInformacoes()
  }, [])

  const onSubmit = async () => {
    const { values } = formik

    const formatItensKit = (response: ICustomKitItens[]) => {
      const formattedKits = response.map(item => ({
        id: item.id,
        item: item.item,
        custoUnitario: formatCurrencyToServer(item.custoUnitario),
        quantidade: item.quantidade,
        total: formatCurrencyToServer(item.total),
        tipo: item.tipo
      }))
      return formattedKits
    }

    const data = {
      potenciaKwp: formatCurrencyToServer(values.potencia),
      tensaoId: Number(values.tensao),
      overloadMin: Number(values.overloadMinimo),
      overloadMax: Number(values.overloadMaximo),
      tipoEstruturaId: values.tipoEstrutura,
      tipoFixacaoEstruturaId: Number(values.estruturaFixacao),
      orientacaoEstruturaId: Number(values.orientacaoEstrutura)
    }
    const response = await repository.postCustomKit(data)
    if (!response) return
    const formattedKits = formatItensKit(response.itemKitList)
    setItens(formattedKits)
    setFornecedorId(response.fornecedorId)
    formik.setFieldValue('customizado', 'false')

    if (response.itemKitList.length) return

    clearItens()
    addToast(toastEmptyResult, {
      appearance: 'warning',
      autoDismiss: true
    })
  }

  const formik = useFormik<IMontagemKitForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit
  })

  const clearItens = () => setItens([])

  const onClickClearFields = () => {
    formik.resetForm()
    clearItens()
  }

  const hasError = (name: keyof IMontagemKitForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const getCommonFieldProps = (name: keyof IMontagemKitForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const potenciaMaiorQueTrintaKwp = useMemo(() => {
    const { potencia } = formik.values
    return parseFloat(potencia) >= 30
      ? messagePotenciaMaiorQueTrinta
      : ''
  }, [formik.values.potencia])

  const onClickGerarKitCustomizado = async () => {
    setIsSaving(true)
    const { potencia, customizado } = formik.values
    const params = {
      potencia: Number(formatCurrencyToServer(potencia)),
      customizado: customizado === 'true',
      itens
    }
    const kitId = await repository.postGenerateCustomKit(params, false)
    setIsSaving(false)
    if (!kitId) return

    history.push(`/detalhes/${kitId}`)
    addToast(toastSuccess, {
      appearance: 'success',
      autoDismiss: true
    })
  }

  const customSetItens = (itens: ICustomKitItens[]) => setItens(itens)

  const customSetFieldValue = (
    field: keyof IMontagemKitForm,
    value: any
  ) => {
    formik.setFieldValue(field, value)
  }

  const onClickShowConfirmationModal = () => setIsModalConfirmationOpen(true)

  return (
    <>
      <Header />
      <BreadCrumb crumbList={itemsBreadCrumb} />
      <DefaultContainer>
        <PageTitle>
          Personalize o seu Kit
        </PageTitle>
        <BoxContainer isBordered>
          <FlexContainer spaced>
            <TextInfo>Personalize um kit com as suas preferências</TextInfo>
            <GoBackButton
              showConfirmation={true}
              route='/'
              message={confirmationMessage}
            />
          </FlexContainer>
          <Form onSubmit={formik.handleSubmit}>
            <FormRow>
              <Col>
                <FormSubTitle>Dados Técnicos</FormSubTitle>
              </Col>
            </FormRow>

            <FormRow>
              <Col md={6}>
                <MaskedCurrencyField
                  required
                  label='Potência da Usina (kWp)'
                  {...getCommonFieldProps('potencia')}
                  maxValue={9999.99}
                  description={potenciaMaiorQueTrintaKwp}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  required
                  label='Tensão do Local'
                  options={itemsTensao}
                  {...getCommonFieldProps('tensao')}
                />
              </Col>
            </FormRow>

            <FormRow>
              <Col md={6}>
                <TextField
                  required
                  type='number'
                  label='Overload Mínimo (%)'
                  {...getCommonFieldProps('overloadMinimo')}
                />
              </Col>
              <Col md={6}>
                <TextField
                  required
                  type='number'
                  label='Overload Máximo (%)'
                  {...getCommonFieldProps('overloadMaximo')}
                />
              </Col>
            </FormRow>

            <FormRow>
              <Col md={4}>
                <SelectField
                  required
                  label='Tipo de Fixação'
                  options={estruturaFixacao}
                  {...getCommonFieldProps('estruturaFixacao')}
                />
              </Col>
              <Col md={4}>
                <SelectField
                  label='Orientação da Estrutura'
                  options={orientacaoEstrutura}
                  {...getCommonFieldProps('orientacaoEstrutura')}
                />
              </Col>
              <Col md={4}>
                <SelectField
                  required
                  label='Tipo de Estrutura'
                  options={tiposEstruturasOptions}
                  {...getCommonFieldProps('tipoEstrutura')}
                />
              </Col>
            </FormRow>

            <ButtonsContainer>
              <SecondaryButton
                onClick={onClickClearFields}
                isLoading={false}
              >
                Limpar
              </SecondaryButton>
              <PrimaryButton
                type='submit'
                isLoading={repository.isLoading}
              >
                Calcular
              </PrimaryButton>
            </ButtonsContainer>
          </Form>
        </BoxContainer>
        {(itens.length > 0 && !repository.isLoading) && (
          <>
            <ItensKit
              customSetFieldValue={customSetFieldValue}
              fornecedorId={fornecedorId}
              itensList={itens}
              customSetItens={customSetItens}
            />
            <BoxContainer>
              <ButtonsContainer>
                <PrimaryButton
                  type='button'
                  onClick={onClickShowConfirmationModal}
                  isLoading={isSavingKit}
                >
                  Avançar
                </PrimaryButton>
              </ButtonsContainer>
            </BoxContainer>
          </>
        )}
      </DefaultContainer>
      <ModalConfirmation
        setIsModalOpen={setIsModalConfirmationOpen}
        isModalOpen={isModalConfirmationOpen}
        confirmationText={messageModal}
        confirmationFunction={onClickGerarKitCustomizado}
      />
      <Footer />
    </>
  )
}

export default PersonalizarKits