import { encrypty, decrypty } from './helpers'

export const keyVariables = {
  userToken: 'kits_user_token',
  userData: 'kits_user_data',
  typeList: 'kits_type_list',
}

export const removeUserFromStorage = () => {
  localStorage.removeItem(keyVariables.userToken)
  localStorage.removeItem(keyVariables.userData)
}

export const setStorageValue = (value, key) => {
  try {
    return localStorage.setItem(key, encrypty(value))
  } catch (error) {
    removeUserFromStorage()
    this.props.history.push('/')

    return false
  }
}

export const getStorageValue = key => {
  try {
    return decrypty(localStorage.getItem(key))
  } catch (error) {
    // Logout
    removeUserFromStorage()

    return false
  }
}

export const getAccessToken = () => getStorageValue(keyVariables.userToken)

export const checkUserIsAuth = () => {
  const token = getAccessToken()
  return Boolean(token)
}

export const saveLoginUserData = (token, userData) => {

  // Encrypty and save in local storage
  localStorage.setItem(keyVariables.userToken, encrypty(token))
  localStorage.setItem(keyVariables.userData, encrypty(JSON.stringify(userData)))
}

export const setUserData = userData => {
  localStorage.setItem(keyVariables.userData, encrypty(JSON.stringify(userData)))
}

export const getUserData = () => {
  const userData = JSON.parse(getStorageValue(keyVariables.userData))
  if (userData) {
    return userData
  }
  removeUserFromStorage()
  return {
    firstName: '',
  }
}
