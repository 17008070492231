import React from 'react'
import { ButtonProps } from 'react-bootstrap'
import { CustomButton, CustomSpinner } from './styles'

type TProps = Omit<ButtonProps, 'onClick'> & {
  isLoading?: boolean
  iconPosition?: 'right' | 'left'
  inLine?: boolean
  onClick?(): void
}

const PrimaryButton = (props: TProps) => {
  const { children, isLoading, ...rest } = props

  return (
    <CustomButton {...rest}>
      {
        isLoading
          ? <CustomSpinner animation='border' />
          : children
      }
    </CustomButton>
  )
}

export default PrimaryButton