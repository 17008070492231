import { DefaultContainer } from 'components/StyledComponents'
import React from 'react'
import { formatCurrency } from 'utils/helpers'
import imgProductDefault from'../../../assets/images/img-product-default.png'
import { TPropostaInfo } from 'pages/Proposta'
import './ProductProposalInfo.scss'
import { useRouteMatch } from 'react-router-dom'
import { INSURANCE_AND_TAX } from 'utils/constants'

interface IProdutInfo {
  proposalData: TPropostaInfo
}
const ProductProposalInfo: React.FC<IProdutInfo> = ( { proposalData } ) => {
  const { proposalId } = useRouteMatch().params as { proposalId: string }
  return (
    <DefaultContainer className='ProductProposalInfo'>
      {proposalData &&
      (
        <>
          <div className='Details'>
            <div className='ValuesDetails'>
              <span className='proposalID'>{`Proposta ${proposalId}`}</span>
              <span className='kitTitle'>{proposalData.nomeKit}</span>
              <div className='kitInfo'>
                <span>{proposalData.descricaoKit}</span>
                <span>Módulo: {proposalData.fabricanteModulo}</span>
                <span>Inversor: {proposalData.fabricanteInversor}</span>


              </div>
              <span className='subTotal'>
                {formatCurrency(proposalData.valorKit)}
              </span>
            </div>

            <img src={proposalData.imagem || imgProductDefault} className='productImage' />
          </div>

          <div className='priceDetail'>
            <div className='simpleValues'>
              <span className='headerText'>Valor dos serviços (R$)</span>
              <span>{`${formatCurrency(proposalData.valorServico)}`}</span>
            </div>
            <div className='simpleValues'>
              <span className='headerText'>Valor do tipo de instalação (R$)</span>
              <span>{`${formatCurrency(proposalData.valorTipoInstalacao)}`}</span>
            </div>
            <div className='simpleValues'>
              <span className='headerText'>Seguro e impostos (R$)</span>
              <span>{`${formatCurrency(proposalData.valorServico * INSURANCE_AND_TAX)}`}</span>
            </div>
            <div className='simpleValues'>
              <span className='headerText'>Valor do frete (R$)</span>
              <span>{`${formatCurrency(proposalData.valorFrete)}`}</span>
              <span className='postalCode'>
                  CEP/ {proposalData.cep}
              </span>
            </div>
            <div className='total'>
              <span className='headerText'>Valor do total (R$)</span>
              <span>{`${formatCurrency(proposalData.subTotal)}`}</span>
            </div>
          </div>
        </>
      )}
    </DefaultContainer>
  )
}

export default ProductProposalInfo
