import { DefaultContainer } from 'components/StyledComponents'
import React from 'react'
import { callCenter, socialMedia } from 'utils/constants'
import logo from 'assets/images/logoHorizontalWithoutTextBranco.svg'

import imageFacebook from 'assets/images/facebook.svg'
import imageLinkedin from 'assets/images/linkedIn.svg'
import imageInstagram from 'assets/images/instagram.svg'

import './footerStyles.scss'

const Footer: React.FC = () => {
  const SocialMedia: React.FC = () => (
    <div className='socialMediaList'>
      <a target='_blank' href={socialMedia.linkedin} rel='noreferrer'>
        <img src={imageLinkedin} alt='Linkedin' />
      </a>
      <a target='_blank' href={socialMedia.facebook} rel='noreferrer'>
        <img src={imageFacebook} alt='Facebook' />
      </a>
      <a target='_blank' href={socialMedia.instagram} rel='noreferrer'>
        <img src={imageInstagram} alt='Instagram' />
      </a>
    </div>
  )
  return (
    <div className='Footer'>
      <DefaultContainer className='top'>
        <div className='left'>
          <img src={logo} className='footerLogo' />
          <span>
            A Copérnico Energia Solar Tecnologia e Serviços Financeiros Ltda.
            (&quot;Copérnico&quot;) é pessoa jurídica registrada sob o
            CNPJ nº 39.439.388/0001-67.
          </span>
          <span>
            A Copérnico não é uma instituição financeira e não realiza operações de crédito diretamente,
            mas é uma correspondente bancária do Safra – Crédito, Financiamento e Investimento S.A. (&quot;Safra&quot;),
            registrada sob o CNPJ nº 39.439.388/0001-67 que, nos termos da Resolução nº 3954 da CMN de 24 de fevereiro de 2011,
            firmaram um acordo entre si. Telefone da ouvidoria da Copérnico: (11) 2626-3932.
          </span>
        </div>
        <div className='right'>
          <SocialMedia />
          <span>
            Central de atendimento Copérnico
          </span>
          <span>
            FAQ
          </span>
          <span>
            {callCenter.phoneNumber}
          </span>
          <span>
            {callCenter.email}
          </span>
        </div>
      </DefaultContainer>
      <hr />
      <DefaultContainer className='footer-bottom'>
        <div className='copyright'>
          <span>Copyright &copy; {new Date().getFullYear()}.</span>
        </div>
        <div className='politics'>
          <a href=''>
            Políticas de privacidade
          </a>
          <a href=''>
            Política de cookies
          </a>
          <a href=''>
            Termos de uso
          </a>
        </div>
      </DefaultContainer>
    </div>
  )
}

export default Footer