import { Table } from 'react-bootstrap'
import styled, { css } from 'styled-components'

const borderTable = 'rgb(112, 112, 112, 0.25)'

export const PageTitle = styled.h1`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  padding: 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 20px;
    margin: 20px;
  }
`

export const TextInfo = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
`

interface IProps {
  isBordered?: boolean
}

export const Container = styled.div<IProps>`
  padding: 30px 40px;
    
 ${p => p.isBordered && (
    css`
      box-shadow: 1px -3px 6px 0 rgb(0 0 0 / 8%);
      border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
      border-radius: 25px;
      border-bottom-style: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
  )}
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 20px;
    margin: 20px;
  }

  .table-responsive{
    min-height: 300px;
  }
`

export const CustomTable = styled(Table)`
	font-size: 16px;

  thead th div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

	tr th {
		font-weight: 400;
		border-bottom-color: ${borderTable} !important;
    border-top: solid 1px ${borderTable};
	}

	tr th,
	tr td {
		color: ${({ theme }) => theme.colors.DARK_GRAY};
	}
`