import React, { useState, useRef, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { TypeaheadModel, AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import {
  Form,
  Col
} from 'react-bootstrap'
import {
  Header,
  BreadCrumb,
  Footer,
  FlexContainer,
  GoBackButton,
  TextField,
  SearchField,
  FormSubTitle,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  MaskedTextField
} from 'components'
import {
  PageTitle,
  Container,
  TextInfo,
  FormRow
} from './styles'
import { confirmationMessage, regexCep } from 'utils/constants'
import useConfirmOrder from 'repositories/useConfirmOrder'
import { formatCep, removeMaskGuides } from 'utils/helpers'
import { DefaultContainer } from 'components/StyledComponents'

const initialValues: IRequestForm = {
  customerName: '',
  customerLandline: '',
  customerPhone: '',
  customerEmail: '',
  customerEmailVerification: '',
  customerAddressCEP: '',
  customerAddressStreet: '',
  customerAddressNumber: '',
  customerAddressNeighborhood: '',
  customerAddressState: '',
  customerAddressCity: '',
  customerAddressComplement: '',
  providerBank: '',
  providerBankBranch: '',
  providerCheckingAccount: '',
  providerBankLabel: '',
}

const schema = Yup.object().shape({
  customerName: Yup
    .string()
    .required('Campo Nome para Contato é obrigatório'),
  customerLandline: Yup
    .string()
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 0000-00000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = (cleanPhone.length < 10 && cleanPhone.length > 1)

        return (!isPhoneIncomplete && !isPhoneZeroed)
      }),
  customerPhone: Yup
    .string()
    .test(
      '',
      'Insira um telefone válido',
      value => {
        const zeroedPhone = '(00) 0000-0000'
        const isPhoneZeroed = value === zeroedPhone

        const cleanPhone = removeMaskGuides(String(value))
        const isPhoneIncomplete = cleanPhone.length < 10

        return (!isPhoneIncomplete && !isPhoneZeroed)
      })
    .required('Campo Telefone Celular é obrigatório'),
  customerEmail: Yup
    .string()
    .email('É necessário um E-mail válido')
    .required('Campo E-mail para Contato é obrigatório'),
  customerEmailVerification: Yup
    .string()
    .when('customerEmail', {
      is: (email: string) => (Boolean(email && email.length > 0)),
      then: Yup.string().oneOf(
        [Yup.ref('customerEmail')],
        'Os Campos "E-mail" e "Confirmar E-mail" não conferem'
      )
    })
    .required('Campo Confirmar E-mail é obrigatório'),
  customerAddressCEP: Yup
    .string()
    .matches(regexCep, 'CEP não esta com o formato válido')
    .required('Campo CEP é obrigatório'),
  customerAddressStreet: Yup
    .string()
    .required('Campo Endereço é obrigatório'),
  customerAddressNumber: Yup
    .string()
    .max(5, 'Não pode ser maior que 5 dígitos')
    .required('Campo Número é obrigatório'),
  customerAddressNeighborhood: Yup
    .string()
    .required('Campo Bairro é obrigatório'),
  customerAddressState: Yup
    .string()
    .required('Campo Estado é obrigatório'),
  customerAddressCity: Yup
    .string()
    .required('Campo Cidade é obrigatório'),
  customerAddressComplement: Yup
    .string(),
  providerBank: Yup
    .string()
    .required('Campo Banco é obrigatório'),
  providerBankBranch: Yup
    .string()
    .required('Campo Agência Bancária é obrigatório'),
  providerCheckingAccount: Yup
    .string()
    .required('Campo Conta Corrente é obrigatório'),
})

const toastSuccess = 'Seu pedido foi confirmado com sucesso!'
const toastSuccessEmail = 'Um link de pagamento foi enviado para o cliente.'
const toastError = 'Não foi possível confirmar seu pedido.'
const toastErrorEmail = 'Não foi possível enviar o link de pagamento.'

const Confirmacao = () => {
  const { requestId } = useRouteMatch().params as { requestId: string }
  const repository = useConfirmOrder()
  const history = useHistory()
  const { addToast } = useToasts()
  const [bankOptions, setBankOptions] = useState<TypeaheadModel[]>([])
  const bankRef = useRef<AsyncTypeahead<string>>(null)

  const itemsBreadCrumb = [
    {
      label: 'Início',
      isLink: true,
      isActive: false,
      url: '/'
    },
    {
      label: 'Consulta de Pedidos',
      isLink: true,
      isActive: false,
      url: '/pedidos'
    },
    {
      label: `Pedido ${requestId}`,
      isLink: false,
      isActive: true,
      url: '/'
    }
  ]

  const onSubmit = async () => {
    const { values } = formik
    const data = {
      orderId: parseInt(requestId),
      customerName: values.customerName,
      customerLandline: values.customerLandline,
      customerPhone: values.customerPhone,
      customerEmail: values.customerEmail,
      customerEmailVerification: values.customerEmailVerification,
      customerAddressCEP: values.customerAddressCEP,
      customerAddressStreet: values.customerAddressStreet,
      customerAddressNumber: values.customerAddressNumber,
      customerAddressNeighborhood: values.customerAddressNeighborhood,
      customerAddressState: values.customerAddressState,
      customerAddressCity: values.customerAddressCity,
      customerAddressComplement: values.customerAddressComplement,
      providerBank: parseInt(values.providerBank),
      providerBankBranch: values.providerBankBranch,
      providerCheckingAccount: values.providerCheckingAccount
    }
    const responseConfirmOrder = await repository.postConfirmOrder(data)
    if (!responseConfirmOrder || responseConfirmOrder.commonResponse.error){
      addToast(toastError, {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    addToast(toastSuccess, {
      appearance: 'success',
      autoDismiss: true
    })

    const responseEmail = await repository.postEmail(requestId)
    if (!responseEmail || responseEmail.commonResponse.error){
      addToast(toastErrorEmail, {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    addToast(toastSuccessEmail, {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/pedidos/detalhes/${requestId}`)
  }

  const formik = useFormik<IRequestForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit
  })

  useEffect(() => {
    const getCepInfoOrder = async () => {
      const cep = await repository.getCepPedido(requestId)
      if(!cep) return

      formik.setFieldValue('customerAddressCEP', formatCep(cep))
    }

    getCepInfoOrder()
  }, [requestId])

  useEffect(() => {
    const getConsultCep = async () => {
      const { customerAddressCEP } = formik.values
      const cleanCEP = removeMaskGuides(customerAddressCEP)
      if (cleanCEP.length < 8) return
      const response = await repository.getAddress(cleanCEP)
      if (!response) return
      formik.setFieldValue('customerAddressStreet', response.logradouro, true)
      formik.setFieldValue('customerAddressNeighborhood', response.bairro, true)
      formik.setFieldValue('customerAddressCity', response.localidade, true)
      formik.setFieldValue('customerAddressState', response.uf, true)
    }

    getConsultCep()
  }, [formik.values.customerAddressCEP])

  const hasError = (name: keyof IRequestForm) => {
    return formik.touched[name] && formik.errors[name]
  }

  const onSearchBank = (term: string) => {
    if (term.length < 3) return
    const requestBanks = async () => {
      const banks = await repository.getBanks(formik.values.providerBank)
      if (!banks) return

      const _bankOptions = banks.map(bank => ({
        value: String(bank.id),
        label: `${bank.code} - ${bank.name}`
      }))

      setBankOptions(_bankOptions)
    }
    requestBanks()
  }

  const onBlurBank = () => {
    formik.setFieldTouched('providerBank', true)
    if (!formik.values.providerBank) bankRef.current?.clear()
  }

  const getCommonFieldProps = (name: keyof IRequestForm) => ({
    isInvalid: Boolean(hasError(name)),
    errorMessage: formik.errors[name],
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
  })

  const onClickClearFields = () => {
    formik.resetForm()
  }

  const onChangeBank = ([bank]: TSelectOption[]) => {
    const value = bank?.value ?? ''
    const label = bank?.label ?? ''
    formik.setFieldValue('providerBankLabel', label, true)
    formik.setFieldValue('providerBank', value, true)
  }

  return (
    <>
      <Header />
      <BreadCrumb crumbList={itemsBreadCrumb} />
      <DefaultContainer>
        <PageTitle>
          Confirmar Pedido
        </PageTitle>
        <Container isBordered>
          <FlexContainer spaced>
            <TextInfo>Preencha abaixo os campos com as informações para o Pedido</TextInfo>
            <GoBackButton
              showConfirmation={true}
              route='/'
              message={confirmationMessage}
            />
          </FlexContainer>
          <Form onSubmit={formik.handleSubmit}>
            <FormRow>
              <Col>
                <FormSubTitle>Dados de Contato do Cliente</FormSubTitle>
              </Col>
            </FormRow>
            <FormRow>
              <Col md={6}>
                <TextField
                  required
                  label='Nome para Contato'
                  type='string'
                  {...getCommonFieldProps('customerName')}
                />
              </Col>
              <Col md={3}>
                <MaskedTextField
                  label='Telefone Fixo'
                  mask='(99) 9999-9999'
                  {...getCommonFieldProps('customerLandline')}
                />
              </Col>
              <Col md={3}>
                <MaskedTextField
                  required
                  label='Telefone Celular'
                  mask='(99) 99999-9999'
                  {...getCommonFieldProps('customerPhone')}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col md={6}>
                <TextField
                  required
                  label='E-mail para Contato'
                  type='string'
                  {...getCommonFieldProps('customerEmail')}
                />
              </Col>
              <Col md={6}>
                <TextField
                  required
                  label='Confirmar E-mail'
                  type='string'
                  {...getCommonFieldProps('customerEmailVerification')}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <FormSubTitle>Endereço de Entrega</FormSubTitle>
              </Col>
            </FormRow>
            <FormRow>
              <Col md={2}>
                <MaskedTextField
                  required
                  disabled
                  label='CEP'
                  mask='99999-999'
                  {...getCommonFieldProps('customerAddressCEP')}
                />
              </Col>
              <Col md={3}>
                <TextField
                  required
                  disabled
                  label='Estado'
                  type='string'
                  {...getCommonFieldProps('customerAddressState')}
                />
              </Col>
              <Col md={7}>
                <TextField
                  required
                  disabled
                  label='Cidade'
                  type='string'
                  {...getCommonFieldProps('customerAddressCity')}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col md={4}>
                <TextField
                  required
                  label='Endereço'
                  type='string'
                  {...getCommonFieldProps('customerAddressStreet')}
                />
              </Col>
              <Col md={3}>
                <TextField
                  required
                  label='Bairro'
                  type='string'
                  {...getCommonFieldProps('customerAddressNeighborhood')}
                />
              </Col>
              <Col md={2}>
                <TextField
                  required
                  label='Número'
                  type='string'
                  {...getCommonFieldProps('customerAddressNumber')}
                />
              </Col>
              <Col md={3}>
                <TextField
                  label='Complemento'
                  type='string'
                  placeholder='Ex: Apto 410 ou Casa B'
                  {...getCommonFieldProps('customerAddressComplement')}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <FormSubTitle>Dados Bancários do Fornecedor</FormSubTitle>
              </Col>
            </FormRow>
            <FormRow>
              <Col md={6}>
                <SearchField
                  required
                  label='Banco'
                  elementRef={bankRef}
                  options={bankOptions}
                  {...getCommonFieldProps('providerBank')}
                  onSearch={onSearchBank}
                  onBlur={onBlurBank}
                  value={formik.values.providerBankLabel}
                  onChange={onChangeBank}
                />
              </Col>
              <Col md={3}>
                <TextField
                  required
                  label='Agência Bancária'
                  type='string'
                  {...getCommonFieldProps('providerBankBranch')}
                />
              </Col>
              <Col md={3}>
                <TextField
                  required
                  label='Conta Corrente'
                  type='string'
                  {...getCommonFieldProps('providerCheckingAccount')}
                />
              </Col>
            </FormRow>
            <ButtonsContainer>
              <SecondaryButton
                onClick={onClickClearFields}
                isLoading={false}
              >
                Limpar
              </SecondaryButton>
              <PrimaryButton
                type='submit'
                isLoading={repository.isLoading}
              >
                Confirmar Pedido
              </PrimaryButton>
            </ButtonsContainer>
          </Form>
        </Container>
      </DefaultContainer>
      <Footer />
    </>
  )
}

export default Confirmacao