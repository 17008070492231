import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
  FaBars,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaUserCircle
} from 'react-icons/fa'
import { copernicoSite, socialMedia } from 'utils/constants'
import logo from '../../assets/images/logoHorizontalWithoutTextBranco.svg'
import imageFacebook from 'assets/images/facebook.svg'
import imageLinkedin from 'assets/images/linkedIn.svg'
import imageInstagram from 'assets/images/instagram.svg'
import logoMobile from '../../assets/images/logoTexto.svg'
import miniLogo from '../../assets/images/LogoHorizontal.svg'
import './headerStyles.scss'
import { DefaultContainer } from 'components/StyledComponents'
import Routes from '../../routes/routes'
import { checkUserIsAuth, getUserData, removeUserFromStorage } from '../../utils/users'
import { Dropdown } from 'react-bootstrap'

interface ISocialMediaContent {
  withText?: boolean
}

interface IMenuItems {
  title: string
  path: string[]
  isActive: boolean
}

const menuItems: IMenuItems[] = [
  {
    title: 'Kits de Geradores de Energia Solar',
    path: ['/', '/detalhes'],
    isActive: false
  },
  {
    title: 'Consulta de Propostas',
    path: ['/propostas'],
    isActive: false
  },
  {
    title: 'Consulta de Pedidos',
    path: ['/pedidos'],
    isActive: false
  }
]

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { name } = getUserData()
  const ref = useRef<HTMLHeadingElement>(null)

  const { pathname } = useLocation()

  const mainPath = `/${pathname.split('/')[1]}`

  useEffect(() => {
    if(ref.current)ref.current.focus()
  }, [])

  const updatedMenuItems = useMemo(() => (
    menuItems.map(item => (
      item.path.includes(mainPath)
        ? { ...item, isActive: true }
        : item
    ))
  ), [mainPath])

  const handleToggleMenu = useCallback(() => {
    setMenuOpen(oldState => !oldState)
  }, [])

  const isLogged = checkUserIsAuth()

  const onClickLogout = () => {
    removeUserFromStorage()
    window.location.reload()
  }

  const linkToLoginOrLogout = (
    <>
      {isLogged && (
        <Dropdown>
          <Dropdown.Toggle as='a' className='loginIcon'>
            <FaUserCircle className='icon' />
            <span>
              {name}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={onClickLogout}>Sair</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )

  const SocialMediaContent: React.FC<ISocialMediaContent> = ({ withText }) => (
    <>
      <a target='_blank' href={copernicoSite} rel='noreferrer'>
        Site Institucional
      </a>
      <a target='_blank' href={socialMedia.linkedin} rel='noreferrer'>
        {!withText && (<img src={imageLinkedin} alt='Linkedin' />)}
        {withText && (
          <>
            <FaLinkedin />
            <span>Linkedin</span>
          </>
        )}
      </a>
      <a target='_blank' href={socialMedia.facebook} rel='noreferrer'>
        {!withText && (<img src={imageFacebook} alt='Facebook' />)}
        {withText && (
          <>
            <FaFacebook />
            <span>Facebook</span>
          </>
        )}
      </a>
      <a target='_blank' href={socialMedia.instagram} rel='noreferrer'>
        {!withText && (<img src={imageInstagram} alt='Instagram' />)}
        {withText && (
          <>
            <FaInstagram />
            <span>Instagram</span>
          </>
        )}
      </a>
    </>
  )
  return (
    <div className='HeaderContainer' ref={ref} tabIndex={-1}>
      <div className='Header'>
        <DefaultContainer className='top'>
          <a className='left' href={Routes.kitList}>
            <img
              src={logo}
              className='HeaderLogo'
              alt='Copérnico Energia Renovável'
            />
          </a>
          <div className='right'>{linkToLoginOrLogout}</div>
        </DefaultContainer>
        <hr />
        <DefaultContainer className='bottom'>
          <div className='left'>
            {isLogged && (
              <>
                {updatedMenuItems.map(item => (
                  <Link
                    key={item.title}
                    to={item.path[0]}
                    className={item.isActive ? 'active' : ''}
                  >
                    {item.title}
                  </Link>
                ))}
              </>
            )}
          </div>
          <div className='right'>
            <SocialMediaContent />
          </div>
        </DefaultContainer>
      </div>
      <div className='Header-Mobile'>
        <img
          src={logoMobile}
          className='HeaderLogo'
          alt='Copérnico Energia Renovável'
        />
        <div>
          <FaBars className='menu-icon' onClick={handleToggleMenu} />
        </div>
        <div
          className={`menu-wrapper ${menuOpen ? 'open' : ''}`}
          onClick={handleToggleMenu}
        >
          <div
            className={'mobile-menu'}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              e.stopPropagation()
            }
          >
            <img src={miniLogo} alt='' className='mini-logo' />
            <div className='items-menu'>
              {isLogged && (
                <>
                  {updatedMenuItems.map(item => (
                    <Link
                      key={item.title}
                      to={item.path[0]}
                      className={item.isActive ? 'active' : ''}
                    >
                      {item.title}
                    </Link>
                  ))}
                </>
              )}
            </div>
            <div className='social-media'>
              <SocialMediaContent withText />
            </div>
            {linkToLoginOrLogout}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header