import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import {
  OrderNumber,
  OrderDetails,
  HorizontalLine,
  OrderStatusContainer,
  OrderItemsContainer,
  OrangeTitle,
  OrderMainContainer,
  OrderDetailInfo,
  FlexBox,
  OrderTitleBox,
  StatusBox,
  ProductImg,
  ProductInfoContainer,
  GeneralInfoContainer,
  GeneralInfoContainerData,
  MainContainer,
  BoxContainer,
  PedidoPrimaryButton,
  PedidoCheckbox
} from './styles'
import {
  Header,
  BreadCrumb,
  FlexContainer,
  GoBackButton,
  Loader,
  Footer
} from 'components'
import { Variant, INSURANCE_AND_TAX } from 'utils/constants'
import usePedidos, { IPedidosRequest } from 'repositories/usePedidos'
import useConfirmOrder from 'repositories/useConfirmOrder'
import { formatDateTimeToView, formatCurrency, upperCaseFirstLetter } from 'utils/helpers'
import imgProductDefault from 'assets/images/img-product-default.png'


const toastErrorEmail = 'Não foi possível enviar o link de pagamento.'
const toastSuccessEmail = 'Um link de pagamento foi enviado para o cliente.'

interface IItemsStep {
  index: number
  title: string
}

const itemsStep: IItemsStep[] = [
  {
    index: 1,
    title: 'Pedido Realizado'
  },
  {
    index: 2,
    title: 'Pedido Processado'
  },
  {
    index: 3,
    title: 'Pedido Faturado'
  },
  {
    index: 4,
    title: 'Produto em Deslocamento'
  },
  {
    index: 5,
    title: 'Produto Finalizado'
  }
]

const DetalhePedido = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const [statusCheck, setStatusCheck] = useState(false)
  const [pedidoInfo, setPedidoInfo] = useState<IPedidosRequest>()


  const history = useHistory()
  const repository = usePedidos()
  const repositoryConfirmOrder = useConfirmOrder()
  const { addToast } = useToasts()

  useEffect(() => {
    const getPedido = async () => {
      const response = await repository.getPedido(orderId)
      if(!response) return
      setPedidoInfo(response)
    }
    getPedido()
  }, [orderId])

  const itemsBreadcrumb = [
    {
      label: 'Kits de geradores de energia solar',
      isLink: true,
      isActive: false,
      url: '/'
    },
    {
      label: 'Consulta de Pedidos',
      isLink: true,
      isActive: false,
      url: '/pedidos'
    },
    {
      label: `Pedido ${orderId}`,
      isLink: false,
      isActive: true,
      url: '/'
    },
  ]

  const confirmOrder = () => {
    history.push(`/pedidos/confirmacao/${orderId}`)
  }

  const handleChangeCheckBox = () => {
    setStatusCheck(!statusCheck)
  }

  const sendEmail = async () => {
    const responseEmail = await repositoryConfirmOrder.postEmail(orderId)
    if (!responseEmail || responseEmail.commonResponse.error){
      addToast(toastErrorEmail, {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }
    addToast(toastSuccessEmail, {
      appearance: 'success',
      autoDismiss: true
    })
  }

  return (
    <>
      <Header />
      <BreadCrumb crumbList={itemsBreadcrumb} />
      <Loader isLoading={repository.isLoading} />
      {(!repository.isLoading && pedidoInfo) && (
        <MainContainer>
          <OrderNumber>Pedido {orderId}</OrderNumber>
          <BoxContainer>
            <OrderMainContainer>
              <FlexContainer spaced>
                <span>Consulte o andamento e os dados referente ao seu pedido</span>
                <GoBackButton
                  showConfirmation={false}
                  route='/pedidos'
                />
              </FlexContainer>
              <FlexContainer>
                <OrderTitleBox>
                  <OrangeTitle>Detalhes do Pedido {orderId}</OrangeTitle>
                  <h6>Realizado em {formatDateTimeToView(pedidoInfo.pedidoOutput.createdAt)} </h6>
                </OrderTitleBox>
                {!pedidoInfo.pedidoOutput.cliente && (
                  <>
                    <PedidoCheckbox
                      type='checkbox'
                      label='Estou de acordo com as informações abaixo'
                      onChange={handleChangeCheckBox}
                    />
                    <PedidoPrimaryButton
                      disabled={!statusCheck}
                      isLoading={false}
                      variant={Variant.PRIMARY}
                      onClick = {confirmOrder}
                    >
                      Confirmar Pedido
                    </PedidoPrimaryButton>
                  </>
                )}
                {Boolean(pedidoInfo.pedidoOutput.cliente) && (
                  <PedidoPrimaryButton
                    isLoading={false}
                    variant={Variant.PRIMARY}
                    onClick = {sendEmail}
                  >
                    Gerar Link de Pagamento
                  </PedidoPrimaryButton>
                )}
              </FlexContainer>

              <OrderDetails>
                <OrderDetailInfo isBordered>
                  <h6>Valor Total</h6>
                  <FlexBox>
                    <span>Valor do Produto</span>
                    <span>{formatCurrency(pedidoInfo.pedidoOutput.proposta.valorKit)}</span>
                  </FlexBox>
                  <FlexBox>
                    <span>Valor dos Serviços</span>
                    <span>{formatCurrency(pedidoInfo.pedidoOutput.proposta.valorServico)}</span>
                  </FlexBox>
                  <FlexBox>
                    <span>Impostos</span>
                    <span>{formatCurrency(pedidoInfo.pedidoOutput.proposta.valorServico * INSURANCE_AND_TAX)}</span>
                  </FlexBox>
                  <FlexBox>
                    <span>Valor do Frete</span>
                    <span>{formatCurrency(pedidoInfo.pedidoOutput.proposta.valorFrete)}</span>
                  </FlexBox>
                  <HorizontalLine />
                  <FlexBox>
                    <span>Sub-Total</span>
                    <span>{formatCurrency(pedidoInfo.pedidoOutput.proposta.subTotal)}</span>
                  </FlexBox>
                </OrderDetailInfo>

                <OrderDetailInfo isBordered>
                  <h6>Forma de Pagamento</h6>
                  <FlexBox>{upperCaseFirstLetter(pedidoInfo.pedidoOutput.proposta.condicaoPagto)}</FlexBox>
                  <FlexBox>
                    <span>Em </span>
                    <span>{upperCaseFirstLetter(pedidoInfo.pedidoOutput.proposta.detalhesCondicaoPagto)}</span>
                  </FlexBox>
                  <HorizontalLine />
                  <FlexBox>
                    <span>Total</span>
                    <span>{formatCurrency(pedidoInfo.pedidoOutput.proposta.valorTotal)}</span>
                  </FlexBox>
                </OrderDetailInfo>

                <OrderDetailInfo>
                  <h6>Local de Entrega</h6>
                  <span>{pedidoInfo.pedidoOutput.proposta.cep}</span>
                  <span>{pedidoInfo.pedidoOutput.proposta.tabela_fretes.cidade} - {pedidoInfo.pedidoOutput.proposta.tabela_fretes.uf}</span>
                </OrderDetailInfo>
              </OrderDetails>

              <OrangeTitle>Status do Pedido</OrangeTitle>
              <OrderStatusContainer>
                {itemsStep.map((item, index) => {
                  const isActive = item.index <= parseInt(pedidoInfo?.pedidoOutput.pedidoStatus.id)
                  return (
                    <>
                      <StatusBox
                        key={index}
                        active={isActive}
                      >
                        {item.title}
                      </StatusBox>
                    </>
                  )
                })}
              </OrderStatusContainer>
              <OrangeTitle>Itens do Pedido</OrangeTitle>

              <OrderItemsContainer>
                <ProductImg src={imgProductDefault} />
                <ProductInfoContainer>
                  <span>{pedidoInfo.pedidoOutput.proposta.nomeKit}</span>
                  <span>{pedidoInfo.pedidoOutput.proposta.descricaoKit}</span>
                  <span>Marca: {pedidoInfo.pedidoOutput.proposta.fabricanteModulo}</span>
                  <span>Inversor: {pedidoInfo.pedidoOutput.proposta.fabricanteInversor}</span>
                </ProductInfoContainer>
              </OrderItemsContainer>


              {pedidoInfo.pedidoOutput.cliente && (
                <>
                  <OrangeTitle>Dados Gerais</OrangeTitle>
                  <GeneralInfoContainer>
                    <GeneralInfoContainerData isBordered>
                      <h6>Dados do Cliente</h6>
                      <FlexBox>
                        <span>Nome para Contato:</span>
                        <span>{pedidoInfo.pedidoOutput.cliente.name}</span>
                      </FlexBox>
                      <FlexBox isBreakLine>
                        <span>E-mail:</span>
                        <span>{pedidoInfo.pedidoOutput.cliente.email}</span>
                      </FlexBox>
                      <FlexBox>
                        <span>Telefone Fixo:</span>
                        <span>{pedidoInfo.pedidoOutput.cliente.phone}</span>
                      </FlexBox>
                      <FlexBox>
                        <span>Telefone Celular:</span>
                        <span>{pedidoInfo.pedidoOutput.cliente.cellPhone}</span>
                      </FlexBox>
                      <hr />
                    </GeneralInfoContainerData>
                    {pedidoInfo.pedidoOutput.providerBankData && (
                      <GeneralInfoContainerData>
                        <h6>Dados Bancários do Fornecedor</h6>
                        <FlexBox isBreakLine>
                          <span>Banco:</span>
                          <span>{pedidoInfo.pedidoOutput.providerBankData.bank.name}</span>
                        </FlexBox>
                        <FlexBox>
                          <span>Agência Bancária:</span>
                          <span>{pedidoInfo.pedidoOutput.providerBankData.agency}</span>
                        </FlexBox>
                        <FlexBox>
                          <span>Número da Conta Corrente:</span>
                          <span>{pedidoInfo.pedidoOutput.providerBankData.account}</span>
                        </FlexBox>
                      </GeneralInfoContainerData>
                    )}
                  </GeneralInfoContainer>
                </>
              )}
            </OrderMainContainer>
          </BoxContainer>
        </MainContainer>
      )}
      <Footer />

    </>
  )
}

export default DetalhePedido

