import { useState } from 'react'
import useRest from 'hooks/useRest'

interface IPropostasRequest {
  page?: number
  pageSize?: number
  createDateStart?: string
  createDateEnd?: string
  proposalName?: string
  price?: string
  id?: string
}

const usePropostas = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getPropostas = async (params:IPropostasRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/proposta/consultProposals'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }

  const getProposta = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/proposta/listAllInfoById/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }
  return {
    isLoading,
    getProposta,
    getPropostas
  }
}
export default usePropostas