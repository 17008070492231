import React from 'react'
import { Form } from 'react-bootstrap'
import { FormLabel } from 'components'

type TProps = {
  required?: boolean
  type?: string
  name: string
  label?: string
  placeholder?: string
  emptyLabel?: string
  description?: string
  isInvalid?: boolean
  errorMessage?: string
  disabled?: boolean
  max?: number
  min?: number
  autoFocus?: boolean
  maxLength?: number
}

const TextField = (props: TProps) => {
  const {
    required,
    label,
    type = 'text',
    name,
    errorMessage,
    description,
    disabled = false,
    maxLength = 255,
    ...rest
  } = props

  return (
    <Form.Group>
      {label && (
        <FormLabel required={required}>
          {label}
        </FormLabel>
      )}

      <Form.Control
        id={name}
        type={type}
        disabled={disabled}
        autoComplete='new-off'
        maxLength={maxLength}
        {...rest}
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default TextField
