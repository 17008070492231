import React from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import SalesKitList from './salesKitList/SalesKitList'
import './SalesKitStyles.scss'

const SalesKit: React.FC = () => {
  return (
    <div className='SalesKitList'>
      <Header />
      <SalesKitList />
      <Footer />
    </div>
  )
}

export default SalesKit
