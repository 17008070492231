import axios from 'services/axios'
import { AxiosError } from 'axios'

import useErrorHandler from 'hooks/useErrorHandler'

const useRest = () => {
  const { handleApiErrorDesambiguation } = useErrorHandler()

  const get = async (
    url: string,
    params: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const _params = {
        params
      }
      const response = await axios.get(url, _params)
      return response.data
    } catch(error: any) {
      if (customHandleError) {
        customHandleError(error)
        return
      }

      const { status, code } = error.response.data
      if (status) {
        handleApiErrorDesambiguation(status)
        return
      }
      if (code) handleApiErrorDesambiguation(code)
    }
  }

  const post = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.post(url, body)
      return response.data
    } catch(error: any) {
      if (customHandleError) {
        customHandleError(error)
        return
      }

      const errorCode = Number(error.response.data.commonResponse.code)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const put = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.put(url, body)
      return response.data
    } catch(error: any) {
      if (customHandleError) {
        customHandleError(error)
        return
      }

      const errorCode = Number(error.response.data.commonResponse.code)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  return { get, post, put }
}

export default useRest