import React from 'react'
import { Spinner } from 'react-bootstrap'
import { LoadingContainer } from './styles'

type TProps = {
  isLoading?: boolean
}

const Loader = (props: TProps ) => {
  const { isLoading = true } = props

  if (!isLoading) return null

  return (
    <LoadingContainer>
      <Spinner animation='border' variant='secondary' />
    </LoadingContainer>
  )
}

export default Loader