export const API_URL = process.env.REACT_APP_API_URL
export const ENCRYPTION_KEY = 'eeXfrXaNrm8J6SokRWlMVzdo4X5N2syW'

export const initialPaginationParams = {
  paginate: {
    pageNumber: 0,
    numberOfRecordsByPage: 10
  }
}

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum PessoaTipo {
  FISICA = 'F',
  JURIDICA = 'J'
}

export enum ActivationStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum Sexo {
  MASCULINO = 'MASCULINO',
  FEMININO = 'FEMININO'
}

export enum OrgaoEmissor {
  SSP = 'SSP',
  SSPDS = 'SSPDS',
  SESP = 'SESP',
  SESED = 'SESED',
  SESDEC = 'SESDEC',
  CGPI_DIREX_DPF = 'CGPI/DIREX/DPF',
  DPF = 'DPF',
}

export enum TipoFinanciamento {
  SERVICO = 'Serviço',
  PRODUTO = 'Produto',
  SERVICO_PRODUTO = 'Serviço e Produto',
}

export enum FinalidadeProjeto {
  AUTO_CONSUMO_REMOTO = 'Auto Consumo Remoto',
  GERACAO_PROPRIA = 'Geração na própria Carga',
  CONDOMINIO = 'Condomínio',
  GERACAO_COMPARTILHADA = 'Geração Compartilhada',
}

export enum StatusDocumento {
  NAO_ENVIADO = 1,
  AGUARDANDO_APROVACAO_BANCO = 2,
  APROVADO_PELO_BANCO = 3,
  NEGADO_PELO_BANCO = 4
}

export const tiposDocumentosApiSafraOptions = [
  {
    label: 'RG',
    value: '1'
  },
  {
    label: 'PASSAPORTE',
    value: '2'
  },
  {
    label: 'RNE',
    value: '3'
  }
]

export const finalidadeProjetoOptions = [
  {
    label: FinalidadeProjeto.AUTO_CONSUMO_REMOTO,
    value: '1'
  },
  {
    label: FinalidadeProjeto.GERACAO_PROPRIA,
    value: '2'
  },
  {
    label: FinalidadeProjeto.CONDOMINIO,
    value: '3'
  },
  {
    label: FinalidadeProjeto.GERACAO_COMPARTILHADA,
    value: '4'
  }
]

export const tipoFinanciamentoOptions = [
  {
    label: TipoFinanciamento.SERVICO,
    value: '1'
  },
  {
    label: TipoFinanciamento.PRODUTO,
    value: '2'
  },
  {
    label: TipoFinanciamento.SERVICO_PRODUTO,
    value: '3'
  }
]

export const sexoOptions = [
  {
    label: 'Masculino',
    value: Sexo.MASCULINO
  },
  {
    label: 'Feminino',
    value: Sexo.FEMININO
  }
]

export const orgaoEmissorOptions = [
  {
    label: OrgaoEmissor.SSP,
    value: '1'
  },
  {
    label: OrgaoEmissor.SSPDS,
    value: '2'
  },
  {
    label: OrgaoEmissor.SESP,
    value: '3'
  },
  {
    label: OrgaoEmissor.SESED,
    value: '4'
  },
  {
    label: OrgaoEmissor.SESDEC,
    value: '5'
  },
  {
    label: OrgaoEmissor.CGPI_DIREX_DPF,
    value: '6'
  },
  {
    label: OrgaoEmissor.DPF,
    value: '7'
  },
]

export const statusOptions = [
  {
    label: 'Ativo',
    value: ActivationStatus.ACTIVATED
  },
  {
    label: 'Inativo',
    value: ActivationStatus.DEACTIVATED
  }
]

export const yesNoOptions = [
  {
    label: 'Sim',
    value: 'true'
  },
  {
    label: 'Não',
    value: 'false'
  },
]

export const tipoPessoaOptions = [
  {
    label: 'Física',
    value: PessoaTipo.FISICA
  },
  {
    label: 'Jurídica',
    value: PessoaTipo.JURIDICA
  }
]

export const ufOptions = [
  {
    label: 'AC',
    value: 'AC'
  },
  {
    label: 'AL',
    value: 'AL'
  },
  {
    label: 'AP',
    value: 'AP'
  },
  {
    label: 'AM',
    value: 'AM'
  },
  {
    label: 'BA',
    value: 'BA'
  },
  {
    label: 'CE',
    value: 'CE'
  },
  {
    label: 'DF',
    value: 'DF'
  },
  {
    label: 'ES',
    value: 'ES'
  },
  {
    label: 'GO',
    value: 'GO'
  },
  {
    label: 'MA',
    value: 'MA'
  },
  {
    label: 'MS',
    value: 'MS'
  },
  {
    label: 'MT',
    value: 'MT'
  },
  {
    label: 'MG',
    value: 'MG'
  },
  {
    label: 'PA',
    value: 'PA'
  },
  {
    label: 'PB',
    value: 'PB'
  },
  {
    label: 'PR',
    value: 'PR'
  },
  {
    label: 'PE',
    value: 'PE'
  },
  {
    label: 'PI',
    value: 'PI'
  },
  {
    label: 'RJ',
    value: 'RJ'
  },
  {
    label: 'RN',
    value: 'RN'
  },
  {
    label: 'RS',
    value: 'RS'
  },
  {
    label: 'RO',
    value: 'RO'
  },
  {
    label: 'RR',
    value: 'RR'
  },
  {
    label: 'SC',
    value: 'SC'
  },
  {
    label: 'SP',
    value: 'SP'
  },
  {
    label: 'SE',
    value: 'SE'
  },
  {
    label: 'TO',
    value: 'TO'
  }
]

export const estadosOptions = [
  {
    label: 'Acre',
    value: 'Acre'
  },
  {
    label: 'Alagoas',
    value: 'Alagoas'
  },
  {
    label: 'Amapá',
    value: 'Amapá'
  },
  {
    label: 'Amazonas',
    value: 'Amazonas'
  },
  {
    label: 'Bahia',
    value: 'Bahia'
  },
  {
    label: 'Ceará',
    value: 'Ceará'
  },
  {
    label: 'Distrito Federal',
    value: 'Distrito Federal'
  },
  {
    label: 'Espírito Santo',
    value: 'Espírito Santo'
  },
  {
    label: 'Goiás',
    value: 'Goiás'
  },
  {
    label: 'Maranhão',
    value: 'Maranhão'
  },
  {
    label: 'Mato Grosso',
    value: 'Mato Grosso'
  },
  {
    label: 'Mato Grosso do Sul',
    value: 'Mato Grosso do Sul'
  },
  {
    label: 'Minas Gerais',
    value: 'Minas Gerais'
  },
  {
    label: 'Pará',
    value: 'Pará'
  },
  {
    label: 'Paraíba',
    value: 'Paraíba'
  },
  {
    label: 'Paraná',
    value: 'Paraná'
  },
  {
    label: 'Pernambuco',
    value: 'Pernambuco'
  },
  {
    label: 'Piauí',
    value: 'Piauí'
  },
  {
    label: 'Rio de Janeiro',
    value: 'Rio de Janeiro'
  },
  {
    label: 'Rio Grande do Norte',
    value: 'Rio Grande do Norte'
  },
  {
    label: 'Rio Grande do Sul',
    value: 'Rio Grande do Sul'
  },
  {
    label: 'Rondônia',
    value: 'Rondônia'
  },
  {
    label: 'Roraima',
    value: 'Roraima'
  },
  {
    label: 'Santa Catarina',
    value: 'Santa Catarina'
  },
  {
    label: 'São Paulo',
    value: 'São Paulo'
  },
  {
    label: 'Sergipe',
    value: 'Sergipe'
  },
  {
    label: 'Tocantins',
    value: 'Tocantins'
  }
]

export const estadosApiSafraOptions = [
  {
    label: 'Acre',
    value: '1'
  },
  {
    label: 'Alagoas',
    value: '2'
  },
  {
    label: 'Amazonas',
    value: '3'
  },
  {
    label: 'Amapá',
    value: '4'
  },
  {
    label: 'Bahia',
    value: '5'
  },
  {
    label: 'Ceará',
    value: '6'
  },
  {
    label: 'Distrito Federal',
    value: '7'
  },
  {
    label: 'Espírito Santo',
    value: '8'
  },
  {
    label: 'Goiás',
    value: '9'
  },
  {
    label: 'Maranhão',
    value: '10'
  },
  {
    label: 'Minas Gerais',
    value: '11'
  },
  {
    label: 'Mato Grosso do Sul',
    value: '12'
  },
  {
    label: 'Mato Grosso',
    value: '13'
  },
  {
    label: 'Pará',
    value: '14'
  },
  {
    label: 'Paraíba',
    value: '15'
  },
  {
    label: 'Pernambuco',
    value: '16'
  },
  {
    label: 'Piauí',
    value: '17'
  },
  {
    label: 'Paraná',
    value: '18'
  },
  {
    label: 'Rio de Janeiro',
    value: '19'
  },
  {
    label: 'Rio Grande do Norte',
    value: '20'
  },
  {
    label: 'Rio Grande do Sul',
    value: '22'
  },
  {
    label: 'Rondônia',
    value: '21'
  },
  {
    label: 'Roraima',
    value: '27'
  },
  {
    label: 'Santa Catarina',
    value: '23'
  },
  {
    label: 'São Paulo',
    value: '25'
  },
  {
    label: 'Sergipe',
    value: '24'
  },
  {
    label: 'Tocantins',
    value: '26'
  }
]

export const toastDeleteMessage = 'Item removido com sucesso!'
export const toastAddMessage = 'Item adicionado com sucesso!'
export const confirmationMessage = 'Todos as alterações não salvas serão perdidas. Tem certeza que deseja continuar?'

export const regexTelefone = /\(?\d{2,}\)?[ -]?\d{4,}[-\s]?\d{4}/

export const regexCelular = /\(?\d{2,}\)?[ -]?\d{5,}[-\s]?\d{4}/

export const regexCep = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/

export const socialMedia = {
  facebook: 'https://www.facebook.com/Copernico-103735801775378',
  instagram: 'https://www.instagram.com/copernicoenergia/',
  linkedin: 'https://www.linkedin.com/company/copérnico-energia-renovável/',
  youtube: '',
  siteInstitucional: 'https://www.copernico.com.br'
}

export const copernicoSite = 'https://www.copernico.com.br/'

export const callCenter = {
  phoneNumber: '(11) 2673-7642',
  email: 'atendimento@copernico.com.br'
}

export const INSURANCE_AND_TAX = 0.10865

export const itemsTensao = [
  {
    label: 'Monofásico 220 V',
    value: '1'
  },
  {
    label: 'Trifásico 220 V',
    value: '2'
  },
  {
    label: 'Trifásico 380 V',
    value: '3'
  },
  {
    label: 'Trifásico 800 V',
    value: '4'
  }
]

export const tiposEstruturas = [
  {
    label: 'Cerâmico em Retrato',
    value: '1'
  },
  {
    label: 'Fibrocimento em Retrato (fx. madeira)',
    value: '2'
  },
  {
    label: 'Metálica em Retrato',
    value: '4'
  },
  {
    label: 'Laje em Retrato',
    value: '5'
  }
]

export const estruturaFixacao = [
  {
    label: 'Telhado',
    value: '1'
  },
  {
    label: 'Laje',
    value: '2'
  },
  {
    label: 'Solo fixo',
    value: '3'
  },
]

export const orientacaoEstrutura = [
  {
    label: 'Paisagem',
    value: '1'
  },
  {
    label: 'Retrato',
    value: '2'
  },
]
