import { ICollapseItem } from 'components/ProductDetails/interfaces'
import { getDataSheet } from 'dataSource/DataRepository'
import React, { useState } from 'react'
import { Accordion, AccordionToggle, AccordionCollapse } from 'react-bootstrap'
import { FaArrowDown } from 'react-icons/fa'
import './DataSheetListStyles.scss'

interface IDataSheetList {
  childList: Array<ICollapseItem>
}
const DataSheetList: React.FC<IDataSheetList> = ({ childList }) => {
  const [isOpen, setIsOpen] = useState<Array<number>>([])
  const handleChange = (e: any) => {
    if (!isOpen.includes(e)) {
      setIsOpen([...isOpen, e])
    } else {
      const index = isOpen.indexOf(e)
      const copyOfArray = [...isOpen]
      index > -1 && copyOfArray.splice(index, 1)
      setIsOpen(copyOfArray)
    }

  }

  const downloadDataSheet = (filename: string) => {
    getDataSheet(`${filename}.pdf`).then(resp => {
      if (resp) window.open(resp, '_blank')
    })
  }
  return (
    <>
      {
        childList?.map((child: ICollapseItem, index) => (
          <Accordion
            id='accordionListItems'
            key={child.label}
            onClick={() => handleChange(index)}
          >
            <AccordionToggle className='accordionToggle' eventKey={index.toString()}>
              <span>
                {child.label}
              </span>
              <span className={`arrowSpan ${isOpen.includes(index) ? 'opened' : ''}`}>
                <FaArrowDown />
              </span>
            </AccordionToggle>
            <AccordionCollapse eventKey={index.toString()}>
              <span className='download-link' onClick={() => downloadDataSheet(child.content)}>{`Download: ${child.label}`}</span>
            </AccordionCollapse>
            <hr />
          </Accordion>
        ))
      }
    </>

  )
}

export default DataSheetList
