import styled, { css } from 'styled-components'
import { Row } from 'react-bootstrap'

export const PageTitle = styled.h1`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  padding: 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 20px;
    margin: 20px;
  }
`

interface IProps {
  isBordered?: boolean
}

export const Container = styled.div<IProps>`
  padding: 30px 40px;
  
  ${p => p.isBordered && (
    css`
      box-shadow: 1px -3px 6px 0 rgb(0 0 0 / 8%);
      border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
      border-radius: 25px;
      border-bottom-style: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
  )}

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 20px;
    margin: 20px;
  }
`

export const TextInfo = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK_GRAY};
`

export const FormRow = styled(Row)`
  margin-top: 10px;
`