import { ICollapseItem, IInstItem } from './interfaces'


export const installationOptionsMock: Array<IInstItem> = [
  {
    kitId: 1,
    tipoInstalacaoId: 1,
    descricaoTipoFixacao: 'Estrutura para telhado',
    descTipoId: 1,
    descricaoTipoInstalacao: 'Cerâmica 4 módulos em retrato',
    precoVenda: 400,
    quantidadeTipoInstalacao: 1
  },

]
export const childListMock: Array<ICollapseItem> = [
  {
    label: 'Data Sheet Inversor',
    content: 'Link Aqui'
  },
  {
    label: 'Data Sheet Módulo',
    content: 'Link Aqui'
  },
]

