import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import Header from './Components/Header'
import Steps from './Components/Steps'
import ConfirmationOrder from './Components/ConfirmationOrder'
import useRequests from 'repositories/useRequests'
import { formatCurrency, upperCaseFirstLetter } from 'utils/helpers'

enum Step {
  CONFIRMATION = 1,
  PAYMENT = 2,
  FINISH = 3
}

const toastMessageTokenInvalidity = 'O token fornecido não está valido, por favor solicitar um novo token para o pagamento.'
const toastMessageInfoOrderAlreadyProcessed = 'Pedido já processado.'

const PedidosResumo = () => {
  const [orderInfo, setOrderInfo] = useState<IOrderInfo>({} as IOrderInfo)
  const [currentStep, setCurrentStep] = useState(1)

  const { id, token } = useParams<{ id: string, token: string }>()
  const repository = useRequests()
  const { addToast } = useToasts()
  const history = useHistory()

  const onClickNextStep = () => {
    if (currentStep >= Step.FINISH) return

    setCurrentStep(currentStep + 1)
  }

  useEffect(() => {
    const checkTokenOrderClient = async () => {
      const response = await repository.getCheckTokenOrderClient(id, token)
      if (response) return

      addToast(toastMessageTokenInvalidity, {
        appearance: 'error',
        autoDismiss: true
      })
      history.push('/login')
    }

    checkTokenOrderClient()
  }, [token])

  useEffect(() => {
    const getOrder = async () => {
      const response = await repository.getInfoOrderById(id)
      if (!response) {
        addToast(toastMessageInfoOrderAlreadyProcessed, {
          appearance: 'error',
          autoDismiss: true
        })
        history.push('/login')
        return
      }

      const { kitOutPut, consultOrderOutput, clientAllInfosOutPut, proposta } = response

      const data: IOrderInfo = {
        orderId: consultOrderOutput.orderId,
        kitId: kitOutPut.id,
        descricao: kitOutPut.nome,
        marca: kitOutPut.marca,
        fabricanteInversor: kitOutPut.fabricanteInversor,
        name: clientAllInfosOutPut.name,
        city: upperCaseFirstLetter(clientAllInfosOutPut.city),
        state: upperCaseFirstLetter(clientAllInfosOutPut.state),
        cep: clientAllInfosOutPut.cep,
        street: upperCaseFirstLetter(clientAllInfosOutPut.street),
        complement: upperCaseFirstLetter(clientAllInfosOutPut.complement),
        neighborhood: upperCaseFirstLetter(
          clientAllInfosOutPut.complement
            ? `${clientAllInfosOutPut.neighborhood}, ${clientAllInfosOutPut.complement}`
            : clientAllInfosOutPut.neighborhood
        ),
        number: clientAllInfosOutPut.number,
        valorFrete: formatCurrency(proposta.valorFrete),
        valorKit: formatCurrency(proposta.valorKit),
        valorTipoInstalacao: formatCurrency(proposta.valorTipoInstalacao),
        valorServico: formatCurrency(proposta.valorServico),
        valorImposto: formatCurrency(proposta.valorImposto),
        valorTotal: formatCurrency(proposta.valorTotal),
      }

      setOrderInfo(data)
    }

    getOrder()
  }, [id])

  return (
    <>
      <Header
        orderId={id}
        currentStep={currentStep}
      />
      <Steps
        currentStep={currentStep}
      />
      {currentStep === Step.CONFIRMATION && (
        <ConfirmationOrder
          orderInfo={orderInfo}
          onClickNextStep={onClickNextStep}
          isLoading={repository.isLoading}
        />
      )}
    </>
  )
}

export default PedidosResumo