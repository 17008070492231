import React from 'react'
import { Form } from 'react-bootstrap'
import { FormLabel } from 'components'

type TProps = {
  required?: boolean
  name: string
  label?: string
  value: string
  placeholder?: string
  firstItem?: string
  description?: string
  options: TSelectOption[]
  isInvalid?: boolean
  errorMessage?: string
  disabled?: boolean
  onChange(e: TInputEvent): void
}

const SelectField = (props: TProps) => {
  const {
    required,
    label,
    name,
    value,
    firstItem = 'Selecione uma opção',
    options,
    errorMessage,
    description,
    disabled = false,
    ...rest
  } = props

  return (
    <Form.Group>
      <FormLabel required={required}>
        {label}
      </FormLabel>

      <Form.Control
        id={name}
        as='select'
        value={value}
        disabled={disabled}
        {...rest}
      >
        <option value=''>{firstItem}</option>
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Form.Control>

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default SelectField
