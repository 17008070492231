import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  font-weight: 700;

  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GRAY};
  }
`