import { useState } from 'react'
import useRest from 'hooks/useRest'
import { TPropostaInfo } from '../pages/Proposta'

export interface IClienteInfo {
  clientId: number
  name: string
  phone: string
  cellPhone: string
  email: string
  cep: string
  neighborhood: string
  number: string
  state: string
  uf: string
  city: string
  street: string
  complement: string
}

export interface IProviderBank {
  id: number
  bank: {
    id: number
    name: string
    code: string
  },
  agency: string
  account: string
}

export interface IPedidosRequest {
  pedidoOutput: {
    pedidoId: number
    proposta: TPropostaInfo
    pedidoStatus: {
      descricao: string
      id: string
    }
    providerBankData: IProviderBank | null
    cliente: IClienteInfo | null
    createdAt: string
  }
}

const usePedidos = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getPedido = async (id:string, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/pedido/list/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if(!response) return false
    return response
  }
  return {
    isLoading,
    getPedido
  }
}
export default usePedidos