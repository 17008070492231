import React, { useCallback, useEffect, useState } from 'react'
import './ListProducts.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, DefaultContainer } from '../StyledComponents'
import ProductCard from '../ProductCard/ProductCard'
import { getProducts } from '../../dataSource/DataRepository'
import { IProduct } from '../Interfaces'
import Spinner from '../Spinner/Spinner'

export interface IQueryParams {
  power?: string;
  inverter?: string;
}

interface IFindParams {
  potency?: number;
  inversor?: string;
  pageNumber: number;
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

const ListProducts: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const [queryURL, setQueryURL] = useState<IQueryParams>({})
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [isLastPage, setIsLastPage] = useState<boolean>(true)
  const [products, setProducts] = useState<IProduct[]>([])

  const loadPageItems = useCallback((pageNumber, queryObj = false) => {
    setLoading(true)

    let powerValue
    let inverterValue: string | undefined
    if (queryObj) {
      // Busca com novos parametros
      powerValue = queryObj?.power
      inverterValue = queryObj?.inverter
    } else {
      // Busca com mesmos parametros (carregar mais)
      powerValue = queryURL?.power
      inverterValue = queryURL?.inverter
    }

    const params: IFindParams = { pageNumber }
    //valor default, usado no back-end para trazer todos os kits sem filtro de potência.
    params.potency = 1050
    if (powerValue) {
      params.potency = Number(powerValue)
    }
    if (inverterValue) {
      params.inversor = inverterValue
    }

    getProducts(params)
      .then(response => {
        setLoading(false)
        if (!response.error) {
          const responseProducts: IProduct[] = response.kitList.content || []

          if (pageNumber === 0) {
            setProducts(responseProducts)
          } else {
            setProducts([...products, ...responseProducts])
          }

          setIsLastPage(response.kitList.last)
          setCurrentPage(response.kitList.pageable.pageNumber)

        } else {
          setProducts([])
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }, [products, history, location, queryURL])

  useEffect(() => {
    const queryObj = {} as IQueryParams
    try {
      const powerQuery = query.get('power')
      const inverterQuery = query.get('inverter')
      queryObj.power = powerQuery !== null ? JSON.parse(powerQuery) : ''
      queryObj.inverter = inverterQuery !== null ? inverterQuery : ''
    } catch (err) {
      queryObj.power = ''
      queryObj.inverter = ''
    } finally {
      setQueryURL(queryObj)
    }

    loadPageItems(0, queryObj)
  }, [location])

  const loadMore = useCallback(() => {
    loadPageItems(currentPage + 1)
  }, [currentPage, loadPageItems])

  const productListEmptyComponent =
    <div className='products-items--empty'>
      <span className='products-items--empty--text'>
        Não existem kits com base no filtro realizado.
      </span>
    </div>

  const productListComponent =
    <div className='products-items'>
      {products.map(product =>
        <ProductCard key={product.id} product={product} />
      )}
    </div>

  return (
    <div className='ListProducts'>
      <div className='list-title-container'>
        <DefaultContainer>
          <div className='list-title'>Kits de Geradores de Energia Solar</div>
        </DefaultContainer>
      </div>
      <DefaultContainer>
        <div className='products-container'>
          <div className='products-title'>
            Reduza a Sua Conta de Luz e/ou Fique Independente da Rede Elétrica!  Encontre aqui geradores de energia solar para casas,
            comércios ou indústrias. As melhores e mais conceituadas marcas de geradores de energia solar estão aqui. Confira
          </div>
          <Spinner loading={loading}>
            <>
              {
                products.length === 0
                  ? productListEmptyComponent
                  : productListComponent
              }
            </>
          </Spinner>
          {!isLastPage &&
            <div className='load-more-shadow'>
              <div className='load-more'>
                <Button type='transparent' className='load-more-button' onClick={loadMore}>Carregar mais kits</Button>
              </div>
            </div>
          }
        </div>
      </DefaultContainer>
    </div>
  )
}

export default ListProducts
