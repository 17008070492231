import styled from 'styled-components'
import { Button as BootstrapButton } from 'react-bootstrap'

export const DefaultContainer = styled.div`
  width: 100%;
  padding: 0 70px;
  margin: 0 auto;
  max-width: 1366px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 0 20px;
    width: 95%;
  }
`

export const DefaultContainerWithShadow = styled(DefaultContainer)`
  &:before {
    content: '';
    position: absolute;
    top:0;
    bottom: 50%;
    left:0;
    right:50%;
    width: 100%;
    box-shadow:0 3px 6px 0 rgba(0,0,0,0.16);
    border-radius:10px / 100px;
    z-index: -1;
}
`

const buttonTypes = {
  primary: {
    backgroundColor: '#fe961d',
    backgroundColorHover: '#e77d01',
    color: '#FFFFFF',
  },
  transparent: {
    backgroundColor: 'transparent',
    backgroundColorHover: 'rgba(0, 0, 0, 0.1)',
    color: '#565556',
  },
}

type TButtonProps = {
  type: 'primary' | 'transparent'
}

export const Button = styled(BootstrapButton)<TButtonProps>`
  background-color: ${p => buttonTypes[p.type].backgroundColor};
  color: ${p => buttonTypes[p.type].color};
  border-color: ${p => buttonTypes[p.type].color};
  width: 100%;
  padding: 10px 0;
  border-radius: 6px;
  &:hover, &:focus, &:active{
    color: ${p => buttonTypes[p.type].color} !important;
    border-color: ${p => buttonTypes[p.type].color} !important;
    background-color: ${p => buttonTypes[p.type].backgroundColorHover} !important;
    box-shadow: none !important;
  }
`

const textInputTypes: any = {
  'M': {
    width: '250.2px',
    height: '51px'
  },
  'L': {
    width: '610.2px',
    height: '51px'
  }
}
type TTextInputs = {
  type: 'M' | 'L';
}

export const TextInput = styled.input<TTextInputs>`
  width: ${p => textInputTypes[p.type].width};
  height: ${p => textInputTypes[p.type].height};
  padding: 12.9px 152.2px 19.2px 33px;
  border: solid 1px rgb(203, 203, 203);
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
`
