import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

  }
  body {
    font-family: 'Verdana', 'Geneva', sans-serif;
    -webkit-font-smoothing: antialiased !important;
    background-color: #FFF;
  }

  .modal-backdrop {
      width: 100%;
  }

  label {
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.DARKER_GRAY} !important;
    font-weight: normal !important;
  }

  select.form-control {
    appearance: auto;
    padding: 0.495rem 0.95rem;
  }

  .ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
`
