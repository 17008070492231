import React from 'react'
import { Container } from '../../styles'
import {
  StepsContainer,
  ItemStepsContainer
} from './styles'

interface IItemsStep {
  index: number
  title: string
}

const itemsStep: IItemsStep[] = [
  {
    index: 1,
    title: 'Confirmação do Pedido'
  },
  {
    index: 2,
    title: 'Realizar o Pagamento'
  },
  {
    index: 3,
    title: 'Concluído'
  }
]

interface IStepsProps {
  currentStep: number
}

const Steps = (props: IStepsProps) => {
  const {
    currentStep
  } = props

  return (
    <Container>
      <StepsContainer>
        {itemsStep.map((item, index) => {

          const isActive = item.index <= currentStep
          return (
            <>
              <ItemStepsContainer
                key={index}
                active={isActive}
              >
                {item.title}
              </ItemStepsContainer>
            </>
          )
        })}
      </StepsContainer>
    </Container>
  )
}

export default Steps