const Routes = {
  kitList: '/',
  proposalList: '/propostas',
  loginPage: '/login',
  forgotPassword: '/recuperar-senha',
  recoverPassword: '/redefine-password',
  kitDetail: '/detalhes',
  loginPortal: '/login-portal'
}

export default Routes
