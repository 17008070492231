import React from 'react'
import { Form } from 'react-bootstrap'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { FormLabel } from 'components'

type TProps = {
  required?: boolean
  name: string
  label?: string
  placeholder?: string
  emptyLabel?: string
  description?: string
  isInvalid?: boolean
  errorMessage?: string
  value?: string
  onChange(e: TInputEvent): void
  disabled?: boolean
  readOnly?: boolean
  prefix?: string
  className?: string
  maxValue?: number
}

const MaskedCurrencyField = (props: TProps) => {
  const {
    required,
    label,
    name,
    isInvalid,
    errorMessage,
    description,
    disabled = false,
    prefix = '',
    className,
    maxValue = 999999999999.99,
    ...rest
  } = props

  const validLimitValue = ({ value }: NumberFormatProps) => Number(value) <= maxValue

  const _prefix = prefix ? `${prefix} ` : ''

  return (
    <Form.Group>
      <FormLabel required={required}>
        {label}
      </FormLabel>

      <NumberFormat
        prefix={_prefix}
        id={name}
        className={`form-control ${isInvalid && 'is-invalid'} ${className}`}
        autoComplete='off'
        disabled={disabled}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        fixedDecimalScale={true}
        isAllowed={validLimitValue}
        {...rest}
      />

      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>

      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default MaskedCurrencyField
