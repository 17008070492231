import { IInstItem } from 'components/ProductDetails/interfaces'
import { getItems } from 'dataSource/DataRepository'
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionCollapse, AccordionToggle } from 'react-bootstrap'
import { FaArrowDown } from 'react-icons/fa'
import './ListItens.scss'
interface IListItens {
  kitId: string;
  installationType?: IInstItem
}
interface IItem {
  nome: string;
  quantidade: string;
}
const ListItens: React.FC<IListItens> = ({ kitId, installationType }) => {
  const [itens, setItens] = useState([] as Array<IItem>)

  const loadItems = () => {
    getItems(kitId).then(resp => {
      if (!resp.itemList) return

      if (!installationType) {
        setItens(resp.itemList)
        return
      }

      const itemInstalacao = {
        nome: `${installationType?.descricaoTipoFixacao} - ${installationType?.descricaoTipoInstalacao}`,
        quantidade: (
          installationType?.quantidadeTipoInstalacao ? installationType.quantidadeTipoInstalacao : '1'
        )
      }
      const itemList = [...resp.itemList, itemInstalacao]
      setItens(itemList)

    }).catch(error => {
      console.error(error)
    })
  }
  useEffect(() => {
    if (kitId) {
      loadItems()
    }
  }, [kitId, installationType])
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Accordion id='accordionListItems' onClick={handleClick}>
      <AccordionToggle eventKey={'0'} className='accordionToggle'>
        <span>Itens do KIT</span>
        <span className={`arrowSpan ${isOpen ? 'opened' : ''}`}>
          <FaArrowDown />
        </span>
      </AccordionToggle>
      <AccordionCollapse eventKey='0'>
        <>
          <div className='itemRow'>
            <span>Itens</span>
            <span className='itemAmountHeader' />
          </div>
          {
            itens.map((item, index) => (
              <div
                key={item.nome}
                className={`itemRow ${index % 2 === 0 && 'darkRow'}`}
              >
                <span>
                  {item.nome}
                </span>
                <span className='itemAmount'>
                  {item.quantidade}
                </span>
              </div>
            ))
          }
        </>
      </AccordionCollapse>
      <hr />
    </Accordion>
  )
}

export default ListItens
