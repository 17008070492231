import React from 'react'
import { Switch } from 'react-router-dom'
import RoutesLinks from './routes'

import Route from './Route'
import SalesKit from 'pages/salesKit/SalesKit'
import LoginPage from 'pages/LoginPage/LoginPage'
import ForgotPassword from 'pages/LoginPage/ForgotPassword'
import RecoverPassword from 'pages/LoginPage/RecoverPassword'
import SalesKitDetails from 'pages/salesKit/salesKitDetails/SalesKitDetails'
import SalesKitProposal from 'pages/Propostas/Detalhes'
import Proposta from 'pages/Proposta'
import AuthRoute from './AuthRoute'
import LoginPortal from 'pages/LoginPortal'
import Confirmacao from 'pages/Pedidos/Confirmacao'
import ListarPropostas from 'pages/Propostas/Lista'
import ListarPedidos from 'pages/Pedidos/Lista'
import PersonalizarKits from 'pages/Kits/Personalizar'
import DetalhePedido from 'pages/Pedidos/Detalhes'
import PedidosResumo from 'pages/Pedidos/Resumo'

const Routes = () => {
  return (
    <Switch>
      <AuthRoute path={RoutesLinks.kitList} exact component={SalesKit} />
      <AuthRoute path={`${RoutesLinks.kitDetail}/:kitId`} component={SalesKitDetails} />
      <AuthRoute path='/propostas' exact component={ListarPropostas} />
      <AuthRoute path='/pedidos' exact component={ListarPedidos} />
      <AuthRoute path='/propostas/detalhes/:proposalId' component={SalesKitProposal} />
      <AuthRoute path='/proposta' component={Proposta} />
      <AuthRoute path='/pedidos/detalhes/:orderId' component={DetalhePedido} />
      <AuthRoute path='/personalizar-kits' component={PersonalizarKits} />
      <AuthRoute path='/pedidos/confirmacao/:requestId' component={Confirmacao} />
      <Route path={RoutesLinks.forgotPassword} exact component={ForgotPassword} />
      <Route path={`${RoutesLinks.recoverPassword}/:token`} component={RecoverPassword} />
      <Route path={RoutesLinks.loginPage} exact component={LoginPage} />
      <Route path={`${RoutesLinks.loginPortal}/:token`} component={LoginPortal} />

      <Route path='/pedidos/pagamento/:id/:token' component={PedidosResumo} />
    </Switch>
  )
}

export default Routes
