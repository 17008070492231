import styled, { css } from 'styled-components'
import iconArrowRight from 'assets/images/icons/Arrow.svg'
import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components'


interface IProps {
  isBordered?: boolean,
  leftRadius?: boolean,
  rightRadius?: boolean,
  active?: boolean
}

export const PedidoPrimaryButton = styled(PrimaryButton)`
   @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
     width: 100%;
   }
`
export const PedidoCheckbox = styled(Form.Check)`
   @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
     width: 100%;
     justify-content: center;
     padding: 20px 0;
     align-items: center;
   }
`

export const MainContainer = styled.div`
  width: 100%;
  padding: 0 70px;
  margin: 0 auto;
  max-width: 1366px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    padding: 0 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`

export const BoxContainer = styled.div`
    padding: 30px 40px;
    box-shadow: 1px -3px 6px 0 rgb(0 0 0 / 8%);
    border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
    border-radius: 25px;
    border-bottom-style: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    padding: 20px;
  }
`

export const OrderMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

export const OrderNumber = styled.h2`
    margin: 20px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
        text-align: center;
    }
`

export const OrderTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
      width: 100%;
      text-align: center;
    }

`

export const OrangeTitle = styled.span`
    color: orange;
    font-size: 22px;
    font-weight: bold;
`

export const HorizontalLine = styled.hr`
    width: 100%;
`

export const OrderDetails = styled.div`
    margin:22px 0;
    padding: 32px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 300px;
    border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
    border-radius: 25px;

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const OrderDetailInfo = styled.div<IProps>`
    display: flex ;
    flex-direction: column;
    padding: 0 50px;
    border-right: ${p => p.isBordered ? 'solid 1px #D3D3D3' : 0};

        h6{
            font-weight: bold;
        }
    
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
        border: none;
        margin: 10px 0;
  }   
`

interface IFlexBox {
  isBreakLine?: boolean
}

export const FlexBox = styled.div<IFlexBox>`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    ${p =>
    p.isBreakLine && (
      css`
      flex-direction: column;
      span:last-child {
        font-size: 12px;
      }
    `
    )}
  } 
`

export const OrderStatusContainer = styled.div`
    margin: 22px 0;
    height: 51px;
    width: 100%;
    display: flex;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.LIGHTER_GRAY};
`

export const StatusBox = styled.div<IProps>`
    display: flex;
    align-items: center;
    width: 100%; 
    padding: 20px 0;
    color: ${({ theme }) => theme.colors.DARK_GRAY};
    font-weight: bold;
    text-align: center;
    position: relative;
    justify-content: space-evenly;
    font-size: 12.5px;
    background-color: ${p => p.active
    ? ({ theme }) => theme.colors.LIGHT_GRAY
    : 'transparent'};

    
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
      font-size: 11px;
      padding: 12px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
      font-size: 9px;
    }

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :not(:last-child):after {
    position: absolute;
    background: url(${iconArrowRight}) no-repeat;
    background-size: 9px;
    content: '';
    width: 14px;
    height: 14px;
    left: 99%;
    z-index: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
   display: none;
    }
  }
`

export const OrderItemsContainer = styled.div`
    border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
    margin:22px 0;
    padding: 32px 0;
    border-radius: 25px;
    height: 239px;
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
        flex-direction: column;
        height: 100%;
  }
`

export const ProductImg = styled.img`
    margin-left: 48px;
    width: 221px;
    height: 177px;
    border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
        margin: auto;
  }

`

export const ProductInfoContainer = styled.div`
    margin-left: 52px;
    display: flex;
    flex-direction: column;

    span {
        margin-bottom: 7px;
    }

    span:nth-child(1){
        font-weight: bold;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
        span {
            margin: 5px 0;
        }
  }
`

export const GeneralInfoContainer = styled.div`
    margin:22px 0;
    padding: 32px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 223px;
    border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
    border-radius: 25px;  

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const GeneralInfoContainerData = styled.div<IProps>`
    display: flex ;
    flex-direction: column;
    padding: 0 50px;
    border-right: ${p => p.isBordered ? 'solid 1px #D3D3D3' : 0};

    h6 {
      font-weight: bold;
    } 

    hr {
      display: none;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakPoints.MEDIUM_SCREEN}) {
    border: none;
    padding: 0 20px;

    hr {
        display: block;
    }
  }
`