import styled from 'styled-components'

type TFlexContainerProps = {
  spaced?: boolean
}

export const FlexContainer = styled.div<TFlexContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${p => p.spaced ? '20px' : '0'};

  div {
    display: flex;
    gap: 10px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    div {
      margin-top: 10px;
      flex-direction: column;
      width: 100%;
    }
  }
`