import styled from 'styled-components'
import { Button, Spinner } from 'react-bootstrap'

type TPrimaryButtonProps = {
  iconPosition?: 'right' | 'left'
  inLine?: boolean
}

export const CustomButton = styled(Button)<TPrimaryButtonProps>`
  background-color: ${({ theme }) => theme.colors.ORANGE} !important;
  color: ${({ theme }) => theme.colors.WHITE};
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${p => p.inLine ? '32px' : 'unset'};
  padding: 10px 40px;
  
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.DARK_YELLOW};
    color: ${({ theme }) => theme.colors.WHITE};
  }

  svg {
    margin-left: ${p => p.iconPosition === 'right' ? '10px' : '0'};
    margin-right: ${p => p.iconPosition === 'left' ? '10px' : '0'};
    width: 22px;
    height: auto;
  }
`

export const CustomSpinner = styled(Spinner)`
  width: 17px;
  height: 17px;
`