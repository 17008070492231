import React from 'react'
import { Spinner as SpinnerBootstrap } from 'react-bootstrap'
import './Spinner.scss'

interface IProps {
  loading: boolean,
  children: JSX.Element,
}

const Spinner: React.FC<IProps> = ({ loading, children }: IProps) => {

  return (
    <div className='Spinner'>
      {children}
      {loading &&
        <div className='spinner-overlay'>
          <SpinnerBootstrap animation='border' role='status' className='spinner-icon'>
            <span className='visually-hidden'>Loading...</span>
          </SpinnerBootstrap>
        </div>
      }
    </div>
  )
}

export default Spinner
