import React, { useMemo } from 'react'
import { IoMdLock } from 'react-icons/io'

import {
  Container,
  Logo,
  SubHeader
} from './styles'

const firstStep = 1

interface IProps {
  orderId: string
  currentStep: number
}

const Header = (props: IProps) => {
  const {
    orderId,
    currentStep
  } = props

  const titleHeader = useMemo(() => (
    currentStep === firstStep
      ? `Resumo do Pedido ${orderId}`
      : 'Dados para o Pagamento'
  ), [currentStep])

  return (
    <>
      <Container>
        <main>
          <Logo />
          <span>
            Espaço 100% Seguro <IoMdLock />
          </span>
        </main>
      </Container>
      <SubHeader>
        <Container>
          {titleHeader}
        </Container>
      </SubHeader>
    </>
  )
}

export default Header