import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

type TContainerProps = {
  disabled: boolean
}

export const Container = styled.div<TContainerProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${p => p.disabled ? '#e9ecef' : p.theme.colors.WHITE};
`

export const Label = styled.label`
  background-color: ${({ theme }) => theme.colors.DARK_YELLOW};
  color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  font-weight: 700;
  padding: 3px 12px;
  border-radius: .25rem;
  cursor: pointer;
  margin: -3px 10px 0 -8px;
`

export const InvisibleFileField = styled.input`
  display: none;
`

export const Loader = styled(Spinner)`
  width: 20px;
  height: 20px;
`

export const FileDownloadContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  color: ${({ theme }) => theme.colors.DARK_GRAY};
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding: 5px 12px;
  border-radius: .25rem;

  span {
    width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.colors.DARK_GRAY};
    cursor: pointer;

    &:last-child {
      margin-left: 5px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }
`