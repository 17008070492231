import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { saveLoginUserData } from '../../utils/users'
import Routes from '../../routes/routes'
import { useToasts } from 'react-toast-notifications'

const parseJwt = (token: string) => {
  const [, base64Url] = token.split('.')

  try{
    return JSON.parse(atob(base64Url))
  } catch {
    return null
  }

}


const LoginPortal = () => {
  const { token } = useParams<{ token: string}>()
  const history = useHistory()
  const { addToast } = useToasts()

  useEffect(() => {
    const user = parseJwt(token)
    if(!user){
      addToast('Houve um problema com sua autenticação! Faça login novamente.', {
        appearance: 'error',
        autoDismiss: false,
      })
      history.push(Routes.loginPage)
      return
    }
    saveLoginUserData(token, user)
    history.push(Routes.kitList)
  }, [token])

  return (<></>)
}

export default LoginPortal