import styled from 'styled-components'
import productImage from 'assets/images/img-product-default.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 70px;
  margin: 20px auto;
  max-width: 1366px;
  gap: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 0 20px;
  }
`

export const TitleText = styled.span`
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.ORANGE};
  margin: 15px 0;
`

export const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  border-radius: 10px;
  padding: 30px 10px;
`

export const ProductImage = styled.h1`
  background: url(${productImage}) no-repeat;
  background-size: contain;
  width: 198px;
  height: 186px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
`

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SMALL_SCREEN}) {
    div:first-child {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    }

    div:last-child {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    grid-template-columns: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    div:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    }
  }

  div {
    display: flex;
    align-items: center;
    padding: 0 30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    div {
      padding: 0 10px;
    }
  }

  div ul {
    list-style: none;

    li {
      line-height: 2;
    }
  }
`

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    grid-template-columns: 100%;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    > div:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    > div {
      padding: 0 10px;
    }
  }

  div ul {
    list-style: none;
    padding-left: 0;

    li {
      line-height: 2;
    }
  }
`