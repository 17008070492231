import React, { useState, useMemo, useRef, useEffect } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { Dropdown, Form } from 'react-bootstrap'
import { FaFilter } from 'react-icons/fa'
import {
  CustomDropdown,
  Button,
  ButtonsContainer,
  CustomSpinner,
  DropdownContainer,
  CustomFormGroup
} from './styles'
import { PrimaryButton, SecondaryButton } from 'components'
import { formatCurrencyToServer, getCurrentDate } from 'utils/helpers'

type TProps = {
  field: string
  specialType?: 'number' | 'currency'
  label?: string | string[]
  options?: TSelectOption[]
  filters: TFiltersHook
  maxValue?: number
}

const Filter = (props: TProps) => {
  const {
    field,
    specialType,
    label = '',
    options = [],
    filters,
    maxValue = 999999999999.99
  } = props

  const [term1, setTerm1] = useState<string>('')
  const [term2, setTerm2] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)

  const dropdownToggleRef = useRef<HTMLButtonElement>(null)
  const inputTextRef = useRef<HTMLInputElement>(null)
  const inputSelectRef = useRef<HTMLSelectElement>(null)
  const inputNumberRef = useRef<NumberFormatProps>(null)

  useEffect(() => {
    const isShowing = dropdownToggleRef.current?.getAttribute('aria-expanded') === 'true'
    if (!filters.isLoading && isShowing) toggleDropdown()
  }, [filters.isLoading])

  const onChangeSetTerm = (e: TInputEvent) => {
    const { value, id } = e.target
    if (id === 'term1') {
      setTerm1(value)
      return
    }

    setTerm2(value)
  }

  const onSubmitAdd = (e: TFormEvent) => {
    e.preventDefault()
    if (field2 && !term2) return

    const filtersToAdd = [
      {
        field: field1,
        term: specialType
          ? formatCurrencyToServer(term1)
          : term1
      },
      ...(field2 ? [{ field: field2, term: term2 }] : [])
    ]

    filters.add(filtersToAdd)
  }

  const onClickRemove = () => {
    const fields = [field1, ...(field2 ? [field2] : [])]
    filters.remove(fields)
    setTerm1('')
    setTerm2('')
    toggleDropdown()
  }

  const toggleDropdown = () => dropdownToggleRef.current?.click()

  const [field1, field2] = field.split('|')
  const [label1, label2] = typeof label === 'string'
    ? [label, '']
    : label

  const inputType = field2 ? 'date' : 'text'

  const isActive = Boolean(filters.getTerm(field1)) && !filters.isLoading
  const filterButtonLabel = isActive ? 'Atualizar' : 'Filtrar'

  const isValidLimitValue = ({ value }: NumberFormatProps) => Number(value) <= maxValue

  const numberProps = {
    isAllowed: isValidLimitValue,
    getInputRef: inputNumberRef
  }

  const currencyProps = {
    ...numberProps,
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2,
    fixedDecimalScale: true
  }

  const specialProps = useMemo(() => {
    if (!specialType) return {}

    return specialType === 'number'
      ? numberProps
      : currencyProps
  }, [specialType])

  const onClickOpenFilterAction = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (specialType) {
      inputNumberRef.current?.focus()
      return
    }

    if (options.length) {
      inputSelectRef.current?.focus()
      return
    }

    if (field2 && !term2) setTerm2(getCurrentDate())
    inputTextRef.current?.focus()
  }, [isOpen])

  return (
    <CustomDropdown alignRight>
      <div onClick={onClickOpenFilterAction}>
        <Dropdown.Toggle
          as={Button}
          active={isActive}
          ref={dropdownToggleRef}
        >
          <FaFilter />
        </Dropdown.Toggle>
      </div>
      <DropdownContainer>
        <Form onSubmit={onSubmitAdd}>
          {options.length ? (
            <CustomFormGroup>
              {label1 && <Form.Label>{label1}</Form.Label>}
              <Form.Control
                id='term1'
                as='select'
                ref={inputSelectRef}
                onChange={onChangeSetTerm}
                size='sm'
                value={term1}
              >
                <option>Selecione...</option>
                {options.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </CustomFormGroup>
          ) : (
            <>
              <CustomFormGroup>
                {label1 && <Form.Label>{label1}</Form.Label>}
                <Form.Control
                  as={specialType ? NumberFormat : 'input'}
                  ref={inputTextRef}
                  {...specialProps}
                  onChange={onChangeSetTerm}
                  id='term1'
                  type={inputType}
                  size='sm'
                  value={term1}
                  placeholder='Digite para filtrar...'
                />
              </CustomFormGroup>
              {field2 && (
                <CustomFormGroup>
                  {label2 && <Form.Label>{label2}</Form.Label>}
                  <Form.Control
                    onChange={onChangeSetTerm}
                    id='term2'
                    type={inputType}
                    size='sm'
                    value={term2}
                    placeholder='Digite para filtrar...'
                  />
                </CustomFormGroup>
              )}
            </>
          )}
          <ButtonsContainer>
            <PrimaryButton type='submit' size='sm'>
              {
                !filters.isLoading
                  ? <span>{filterButtonLabel}</span>
                  : <CustomSpinner animation='border' />
              }
            </PrimaryButton>
            {isActive && (
              <SecondaryButton onClick={onClickRemove} size='sm'>
                Limpar
              </SecondaryButton>
            )}
          </ButtonsContainer>
        </Form>

      </DropdownContainer>
    </CustomDropdown>
  )
}

export default Filter