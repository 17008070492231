import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { checkUserIsAuth, removeUserFromStorage } from '../utils/users'
import Routes from './routes'

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!checkUserIsAuth()) {
          // Clean storage token
          removeUserFromStorage()
          return <Redirect to={{ pathname: Routes.loginPage }} />
        }
        return (<Component {...props} />)
      }}
    />
  )
}

export default AuthRoute
