import { useState } from 'react'
import useRest from 'hooks/useRest'

interface ICustomKitRequest {
  potenciaKwp: string,
  tensaoId: number,
  overloadMin: number,
  overloadMax: number,
  tipoEstruturaId: string
  tipoFixacaoEstruturaId: number
  orientacaoEstruturaId: number
}

export interface ICustomKitConfirmRequest {
  potencia: number
  customizado: boolean
  itens: ICustomKitItens[]
}

interface IParamsItemTipoFornecedor {
  fornecedorId: number
  tipo: string
}

interface IItemTipoFornecedorResponse {
  id: number
  nome: string
  custoUnitario: number
  tipo: string
}

const useKits = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getTensoes = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/tensao/listAll'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TSelectOption[]
    return response as TSelectOption[]
  }

  const getTiposFixacao = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/tipo-fixacao/listAll'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TSelectOption[]
    return response as TSelectOption[]
  }

  const getOrientacaoEstrutura = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/orientacao-estrutura/listAll'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as TSelectOption[]
    return response as TSelectOption[]
  }

  const getTiposEstruturas = async (triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/tipo-fixacao/listAll'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as string[]
    return response as string[]
  }

  const getTiposItens = async (fornecedorId: number, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const params = { fornecedorId }
    const url = '/Item/tipo'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as string[]
    return response.tipoList as string[]
  }

  const getItensByTipoAndFornecedor = async (
    params: IParamsItemTipoFornecedor,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/Item/tipo/fornecedor'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return [] as IItemTipoFornecedorResponse[]
    return response.itemTipoOutputList as IItemTipoFornecedorResponse[]
  }

  const postCustomKit = async (data: ICustomKitRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/custom-kit/generate'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as ICustomKitItensResponse
    return response as ICustomKitItensResponse
  }

  const postGenerateCustomKit = async (data: ICustomKitConfirmRequest, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const url = '/custom-kit/save'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    if (!response) return

    return response.customKitId as number
  }

  return {
    isLoading,
    getTensoes,
    getTiposFixacao,
    getOrientacaoEstrutura,
    getTiposEstruturas,
    getTiposItens,
    getItensByTipoAndFornecedor,
    postCustomKit,
    postGenerateCustomKit
  }
}

export default useKits