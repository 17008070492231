import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { CustomLink } from './styles'
import { IoIosArrowBack } from 'react-icons/io'
import { PrimaryButton, SecondaryButton } from 'components'
import { ButtonsContainer } from 'components/Form/ButtonsContainer'

type TProps = {
  showConfirmation?: boolean
  route: string
  message?: string
}

const GoBackButton = (props: TProps) => {
  const { showConfirmation = false, route, message } = props

  const [showModal, setShowModal] = useState(false)

  const history = useHistory()

  const showModalFn = () => setShowModal(true)
  const hideModalFn = () => setShowModal(false)
  const goBack = () => history.push(route)

  const onClickCheck = () => (
    showConfirmation ? showModalFn() : goBack()
  )

  return (
    <>
      <CustomLink onClick={onClickCheck}>
        <IoIosArrowBack />
        <span>Voltar</span>
      </CustomLink>

      <Modal
        size='sm'
        show={showModal}
        onHide={hideModalFn}
      >
        <Modal.Body>
          {message}
        </Modal.Body>

        <Modal.Footer>
          <ButtonsContainer compact>
            <SecondaryButton onClick={hideModalFn}>
              Não
            </SecondaryButton>

            <PrimaryButton onClick={goBack}>
              Sim
            </PrimaryButton>
          </ButtonsContainer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GoBackButton