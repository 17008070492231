import styled, { css } from 'styled-components'

interface IProps {
  isBordered?: boolean
}

export const BoxContainer = styled.div<IProps>`
  padding: 30px 40px;
  
  ${p => p.isBordered && (
    css`
      box-shadow: 1px -3px 6px 0 rgb(0 0 0 / 8%);
      border: solid 1px ${({ theme }) => theme.colors.LIGHT_GRAY};
      border-radius: 25px;
      border-bottom-style: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
  )}

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.BS_LG}) {
    padding: 20px;
    margin: 20px;
  }
`