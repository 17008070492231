import React from 'react'
import './SalesKitListStyles.scss'
import ListFilters from '../../../components/listFilters/ListFilters'
import ListProducts from '../../../components/ListProducts/ListProducts'

const SalesKitList: React.FC = () => {
  return (
    <div className='SalesKitList'>
      <ListFilters />
      <ListProducts />
    </div>
  )
}

export default SalesKitList
